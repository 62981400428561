import * as businessTypes from "../ActionTypes";

const initialState = {
  bank_details: {},
  static_texts: {},
};

const bankDetailsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case businessTypes.SAVE_BANK_ACCOUNT_DETAILS:
      return {
        ...state,
        bank_details: action.data.bank_details,
        static_texts: action.data.static_text,
      };
    default:
      return state;
  }
};

export default bankDetailsReducer;
