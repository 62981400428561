import * as actionTypes from "../ActionTypes";

export function setMarketingTag(data: any) {
  return {
    type: actionTypes.SET_MARKETING_TAG,
    data,
  };
}

export function getMarketingTag(data: any) {
  return {
    type: actionTypes.GET_MARKETING_TAG,
    data,
  };
}

export function saveMarketingTags(data: any) {
  return {
    type: actionTypes.SAVE_MARKETING_TAGS,
    data,
  };
}

export function getCustomerAddressList() {
  return {
    type: actionTypes.GET_CUSTOMER_ADDRESS_LIST,
  };
}

export function saveAddressList(data: any) {
  return {
    type: actionTypes.SAVE_ADDRESS_LIST,
    data,
  };
}

export function setCustomerAddressList(data: any) {
  return {
    type: actionTypes.SET_CUSTOMER_ADDRESS_LIST,
    data,
  };
}
