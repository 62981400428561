import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

declare const clevertap: any;

export function useCheckLoginStatus() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const { storeData } = useSelector((state: RootStateOrAny) => ({
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    if (storeData?.store_id && !userLoggedIn) {
      setUserLoggedIn(true);
    }

    if (userLoggedIn) {
      clevertap?.profile?.push({
        Site: {
          Name: storeData?.store_info?.name,
          Identity: storeData?.phone,
          Phone: storeData?.phone,
        },
      });
    }
  }, [storeData?.store_id, userLoggedIn]);

  return { userLoggedIn };
}
