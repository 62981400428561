import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes/gstBilling";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { GST_BILLING } from "../../Services/apiUrls";
import {
  GST_BILLING_CONFIG_TYPE_SIGNATURE,
  GST_BILLING_CONFIG_TYPE_INCLUSIVE,
  GST_BILLING_CONFIG_TYPE_SAME_GST,
  GST_BILLING_CONFIG_TYPE_GST_VALUE,
  GST_BILLING_CONFIG_TYPE_HSN_VALUE,
} from "../../Constants/gstConstants";
import { setPageLoaderVisibility, saveGSTBillingDetails, toggleHasCustomSEO } from "../Actions";
import { ToastNotifySuccess, ToastNotifyError } from "../../Components/Toast";
import { parseToJson } from "../../Utils/common";
import store from "../Store";

/**
 * This function will make a post api call to update GST Details.
 * @param actions
 */
function* updateGSTBillingDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      data: actions.data,
      url: config.base_url + GST_BILLING.updateGSTBillingDetails,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Changes saved");
      const imageUrl = parseToJson(response.data.data);
      if (actions.data.config_type_list.includes(GST_BILLING_CONFIG_TYPE_SIGNATURE)) {
        actions.callback(imageUrl);
      } else {
        actions.callback && actions.callback();
      }
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an get api call to get all the GST details
 * @param actions
 */
function* getGSTBillingDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      data: actions.data,
      url: config.base_url + GST_BILLING.getGSTBillingDetails,
    });
    if (response && response?.data?.status) {
      const parsedResponse = parseToJson(response.data.data);
      yield put(saveGSTBillingDetails(parsedResponse));
      yield put(setPageLoaderVisibility(false));
      actions?.callback && actions?.callback();
    } else {
      yield put(saveGSTBillingDetails(null));
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an post api call to verify gst number.
 * @param actions
 */
function* verifyGST(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + GST_BILLING.verifyGST,
    });
    if (response && response?.data?.status) {
      actions.callback(true);
    } else {
      ToastNotifyError(response?.data?.message);
      actions.callback(false);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
    actions.callback(false);
  }
}

/**
 * This function will enable or disable GST Billing for the merchant.
 * @param actions
 */
function* enableOrDisableGST(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      data: actions.data,
      url: config.base_url + GST_BILLING.enableOrDisableGst,
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback();
      ToastNotifySuccess(`GST Billing Successfully ${actions.data.flag ? "Enabled" : "Disabled"}`);
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will download the GST Reports of the merchant.
 * @param actions
 */
function* downloadGSTReport(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: `${config.base_url}${GST_BILLING.requestGSTReport}`,
    });
    if (response && response?.data?.status) {
      actions.callback();
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.UPDATE_GST_BILLING_DETAILS, updateGSTBillingDetails);
  yield takeEvery(actionTypes.GET_GST_BILLING_DETAILS, getGSTBillingDetails);
  yield takeEvery(actionTypes.VERIFY_GST, verifyGST);
  yield takeEvery(actionTypes.ENABLE_OR_DISABLE_GST, enableOrDisableGST);
  yield takeEvery(actionTypes.DOWNLOAD_GST_REPORT, downloadGSTReport);
}
