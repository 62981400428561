import { put, takeEvery } from "redux-saga/effects";
import * as analyticsTypes from "../ActionTypes/analytics";
import apiCall from "../../Services/api";
import { setVisitorsSummaryByFilters, setPageLoaderVisibility } from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";
import { STORE_ANALYTICS_API } from "../../Services/apiUrls";
import { parseToJson } from "../../Utils/common";

function* getVisitorsSummaryByFilters(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: STORE_ANALYTICS_API,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(setVisitorsSummaryByFilters(parsedData));
      actions.callback && actions.callback(true);
    } else {
      actions.callback && actions.callback(false);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch data, try later!");
  }
}

export default function* root() {
  yield takeEvery(analyticsTypes.GET_VISITORS_SUMMARY_BY_FILTERS, getVisitorsSummaryByFilters);
}
