import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  workspacebuyTimer: {
    position: "relative",
    top: 48,
  },

  banner: {
    position: "fixed",
    height: 48,
    zIndex: 1300,
  },
}));
