import { put, takeEvery } from "redux-saga/effects";
import config from "./../../Config";
import { UTILITIES_PAGE } from "@/Services/apiUrls";
import apiCall from "./../../Services/api";
import { GET_ALL_UTILITIES_DATA } from "../ActionTypes/utilities";
import { ToastNotifyError } from "@/Components/Toast";
import { saveAllUtilitiesData } from "../Actions/utilities";

function* getUtilityPageDetails(actions) {
  try {
    const utilitiesPageRes = yield apiCall({
      method: "GET",
      url: `${config.base_url}${UTILITIES_PAGE.utilityPageDetails}`,
      parseToJson: true,
    });
    if (utilitiesPageRes && utilitiesPageRes.data?.status) {
      yield put(saveAllUtilitiesData(utilitiesPageRes.data?.data || {}));
      actions.callback?.(true);
    } else {
      ToastNotifyError(utilitiesPageRes.data.message || "Something went bad!");
      actions.callback?.(false);
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message || "Something went bad!!");
    actions.callback?.(false);
  }
}

export default function* root() {
  yield takeEvery(GET_ALL_UTILITIES_DATA, getUtilityPageDetails);
}
