import { useEffect } from "react";
import renderFbAdsLWI from "../../Utils/renderFbAdsLwi";
import { RootStateOrAny, useSelector } from "react-redux";

export function useRenderFBAds() {
  const { storeData } = useSelector((state: RootStateOrAny) => ({
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    const fbAdsDummyElement = document.getElementById("fb-ads-config");
    if (fbAdsDummyElement && storeData?.store_id) {
      renderFbAdsLWI();
    }
  }, [storeData?.store_id]);

  return null;
}
