import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { setPageLoaderVisibility, saveBankAccountDetails } from "../Actions";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import * as actionTypes from "./../ActionTypes";
import store from "../Store";

function* fetchBankAccountDetails() {
  try {
    yield put(setPageLoaderVisibility(true));
    const { store_id } = store.getState().storeReducer.store;
    const payload = {
      store_id: store_id,
      language_id: 1,
    };
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/settings/getBankDetailsPageInfo`,
      headers: {
        app_version: 1,
      },
      data: payload,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      yield put(setPageLoaderVisibility(false));
      yield put(saveBankAccountDetails(parsedData));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* submitBankAccountDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const { store_id } = store.getState().storeReducer.store;
    const payload = {
      store_id: store_id,
      ...actions.data,
    };
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/setBank`,
      data: payload,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      actions && actions.callback();
      ToastNotifySuccess("Bank account details updated!");
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess(response.data.message);
    }
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.FETCH_BANK_ACCOUNT_DETAILS, fetchBankAccountDetails);
  yield takeEvery(actionTypes.SUBMIT_BANK_ACCOUNT_DETAILS, submitBankAccountDetails);
}
