export const GST_BILLING_CONFIG_TYPE_SIGNATURE = 0;
export const GST_BILLING_CONFIG_TYPE_INCLUSIVE = 1;
export const GST_BILLING_CONFIG_TYPE_SAME_GST = 2;
export const GST_BILLING_CONFIG_TYPE_GST_VALUE = 3;
export const GST_BILLING_CONFIG_TYPE_HSN_VALUE = 4;
export const NORMAL_ORDER_WITHOUT_GST = 0;
export const INCLUSIVE_GST_TAX = 1;
export const EXCLUSIVE_GST_TAX = 2;
export const validGstRates = ["0", "0.1", "0.25", "1.5", "3", "5", "6", "12", "13.8", "18", "28"];
export const GST_STATUSES = {
  DEFAULT: 0,
  EDITABLE: 1,
  VERIFIED: 2,
  FAILED: 3,
  INVALID_STATUS_FROM_VENDOR: 4,
  API_DOWN: 5,
};
