import { combineReducers } from "redux";
import userReducer from "./user";
import commonReducer from "./common";
import catalogReducer from "./catalog";
import storeReducer from "./store";
import ordersReducer from "./orders";
import businessReducer from "./business";
import themeReducer from "./theme";
import paymentMethodReducer from "./payment";
import domainReducer from "./domain";
import bankDetailsReducer from "./bankDetails";
import cartReducer from "./cart";
import adsReducer from "./ads";
import couponsReducer from "./coupons";
import marketingReducer from "./marketing";
import subscriptionReducer from "./subscription";
import bulkUploadReducer from "./bulkUpload";
import additionalPagesReducer from "./additionalPage";
import staffManagementReducer from "./staffManagement";
import workspaceReducer from "./workspace";
import crmReducer from "./crm";
import creditEngine from "./creditEngine";
import leadReducer from "./leadgen";
import analyticsReducer from "./analytics";
import googleShoppingReducer from "./googleShopping";
import facebookBusinessReducer from "./facebookBusiness";
import smsMarketingReducer from "./smsMarketing";
import customDeliveryReducer from "./customDelivery";
import seoToolsReducer from "./seoTools";
import gstBillingReducer from "./gstBilling";
import customerReviewRatingReducer from "./customerReviewRatings";
import whatsappMarketingReducer from "./whatsappMarketing";
import productInquiryReducer from "./productInquiry";
import bulkEditReducer from "./bulkEdit";
import dnsManagementReducer from "./dnsManagement";
import helpReducer from "./helpReducer";
import brandWebsiteReducer from "./brandWebsite";
import exitGatingReducer from "./exitGating";
import { useSelector as reduxUseSelector } from "react-redux";
import utilitiesReducer from "./utilities";

const rootReducer = combineReducers({
  commonReducer,
  userReducer,
  catalogReducer,
  storeReducer,
  ordersReducer,
  businessReducer,
  themeReducer,
  paymentMethodReducer,
  domainReducer,
  bankDetailsReducer,
  cartReducer,
  adsReducer,
  couponsReducer,
  marketingReducer,
  subscriptionReducer,
  bulkUploadReducer,
  additionalPagesReducer,
  staffManagementReducer,
  workspaceReducer,
  crmReducer,
  creditEngine,
  leadReducer,
  analyticsReducer,
  googleShoppingReducer,
  facebookBusinessReducer,
  smsMarketingReducer,
  customDeliveryReducer,
  seoToolsReducer,
  gstBillingReducer,
  customerReviewRatingReducer,
  whatsappMarketingReducer,
  productInquiryReducer,
  bulkEditReducer,
  dnsManagementReducer,
  helpReducer,
  brandWebsiteReducer,
  exitGatingReducer,
  utilitiesReducer,
});
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

export function useSelector<TState = RootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected {
  return reduxUseSelector<TState, TSelected>(selector, equalityFn);
}
