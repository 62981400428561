import * as userActionType from "../ActionTypes/userActionType";

export function saveUserSubscriptionDetails(data: any) {
  return { type: userActionType.SAVE_SUBSCRIPTION_DETAILS, data };
}

export const saveStoreUserInfo = (data: any) => {
  return {
    type: userActionType.SAVE_USER_GMAIL_PAGE_INFO,
    data,
  };
};

export function getDeliverySetupInfo() {
  return { type: userActionType.GET_DELIVERY_SETUP_INFO };
}

export function saveDeliverySetupInfo(data: any) {
  return { type: userActionType.SAVE_DELIVERY_SETUP_INFO, data };
}

export function setOrRemoveDefaultDeliveryPartner(data: any, callback: any) {
  return {
    type: userActionType.SET_OR_REMOVE_DEFAULT_DELIVERY_PARTNER,
    data,
    callback,
  };
}

export function getDeliveryPartnerInputConfig(data: any, callback: any) {
  return {
    type: userActionType.GET_DELIVERY_PARTNER_INPUT_CONFIG,
    data,
    callback,
  };
}

export function addDeliveryPartner(data: any, callback: any) {
  return {
    type: userActionType.ADD_DELIVERY_PARTNER,
    data,
    callback,
  };
}

export function fetchCityStateFromPincode(data: any, callback: any) {
  return {
    type: userActionType.FETCH_CITY_AND_STATE_FROM_PINCODE,
    data,
    callback,
  };
}
