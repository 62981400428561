import { DeliveryAreaKeys, DeliveryAreaValues } from "../../Types/customDelivery";

export interface DeliveryBoxProps {
  data: Record<string, any>;
  isCollapsed: boolean;
  customClasses?: string;
  chargesFor: DeliveryAreaValues;
  setDeliveryData: (a: string, b: Record<string, any>) => void;
  shouldRenderZonalChargeSelection?: boolean;
  toggleCollapse: (a: DeliveryAreaKeys) => void;
}

export interface DeliveryDataProps {
  delivery_type: number;
  delivery_charge: number;
  free_delivery_above: number;
  is_active: number;
}

export const DELIVERY_TYPE = {
  NOT_SERVICABLE: 0,
  FREE: 1,
  FIXED: 2,
};

export type ZONES_LIST_TYPE = "NORTHERN" | "CENTRAL" | "EASTERN" | "WESTERN" | "SOUTHERN" | "ROI";
export const ZONES_LIST: ZONES_LIST_TYPE[] = ["NORTHERN", "CENTRAL", "EASTERN", "WESTERN", "SOUTHERN", "ROI"];

export const deliveryModes = [
  { label: "Free", deliveryType: DELIVERY_TYPE.FREE },
  { label: "Fixed", subLabel: "(Free delivery above)", deliveryType: DELIVERY_TYPE.FIXED },
  { label: "Not Servicable", subLabel: "(Cannot deliver to this area)", deliveryType: DELIVERY_TYPE.NOT_SERVICABLE },
];

export const MAX_DEFAULT_FILTER_TYPE = 4;
