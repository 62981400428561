import * as actionTypes from "../ActionTypes/gstBilling";

const initialState = {
  gstBilling: {},
  sameHsnUpdated: false,
  showUploadCatalogSheetModal: false,
  termsAndConditionsChecked: false,
};

const gstBillingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_GST_BILLING_DETAILS:
      return { ...state, gstBilling: action.data };
    case actionTypes.UPDATE_SAME_HSN:
      return { ...state, sameHsnUpdated: true };
    case actionTypes.TOGGLE_UPLOAD_CATALOG_SHEET_MODAL:
      return { ...state, showUploadCatalogSheetModal: !state.showUploadCatalogSheetModal };
    case actionTypes.TOGGLE_TERMS_AND_CONDITIONS_CHECKED_STATE:
      return { ...state, termsAndConditionsChecked: !state.termsAndConditionsChecked };
    default:
      return state;
  }
};

export default gstBillingReducer;
