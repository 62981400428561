import * as actionTypes from "../ActionTypes";

const initialState = {
  dnsData: {
    records: [],
  },
};

const DNSManagementReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_ALL_DNS_MANAGEMENT_RECORDS:
      return {
        ...state,
        dnsData: {
          ...action.data,
          records: action.data?.records || [],
        },
      };
    case actionTypes.UPDATE_ADD_CUSTOM_RECORDS:
      return {
        ...state,
        dnsData: {
          ...state.dnsData,
          records: [...state.dnsData.records, action.data],
        },
      };
    default:
      return state;
  }
};

export default DNSManagementReducer;
