import { FB_LOGIN_CONFIG } from "../Constants/facebookBusinessExtension";
import { FACEBOOK_ADS, MARKETING_ROUTE } from "../Constants/routesConstant";
import store from "../Redux/Store";

export default () => {
  const { store_id: storeID, store_name: storeName } = store.getState().storeReducer?.store;
  const element = document.getElementById("fb-ads-config");
  if (element?.outerHTML) {
    element.outerHTML = `
    <div
      class="fb-lwi-ads-creation"
      data-fbe-extras="{'business_config':{'business':{'name':${storeName}}},
      'setup':{'external_business_id':${storeID},
      'timezone':${FB_LOGIN_CONFIG.TIMEZONE},
      'currency':${FB_LOGIN_CONFIG.CURRENCY},
      'business_vertical':${FB_LOGIN_CONFIG.BUSINESS_VERTICAL}},
      'repeat':false}"
      data-fbe-scopes=${Object.values(FB_LOGIN_CONFIG.SCOPE).toString()}
      data-fbe-redirect-uri="https://web.dotpe.in${MARKETING_ROUTE}${FACEBOOK_ADS}"
    ></div>
    <div
      class="fb-lwi-ads-insights"
      data-fbe-extras="{'business_config':{'business':{'name':${storeName}}},
      'setup':{'external_business_id':${storeID},
      'timezone':${FB_LOGIN_CONFIG.TIMEZONE},
      'currency':${FB_LOGIN_CONFIG.CURRENCY},
      'business_vertical':${FB_LOGIN_CONFIG.BUSINESS_VERTICAL}},
      'repeat':false}"
      data-fbe-scopes=${Object.values(FB_LOGIN_CONFIG.SCOPE).toString()}
      data-fbe-redirect-uri="https://web.dotpe.in${MARKETING_ROUTE}${FACEBOOK_ADS}"
    ></div>`;
  }
};
