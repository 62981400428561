import * as actionsType from "../ActionTypes/staffManagement";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const getAllStaffMember = (): Action => {
  return {
    type: actionsType.GET_ALL_STAFF_MEMBERS_ACTION,
  };
};

export const saveAllStaffMember = (data: any, callback?: any): Action => {
  return {
    type: actionsType.SAVE_ALL_STAFF_MEMBERS_ACTION,
    data,
    callback,
  };
};

export const addStaffMember = (data: any, callback?: any): Action => {
  return {
    type: actionsType.ADD_STAFF_MEMBER_ACTION,
    data,
    callback,
  };
};

export const removeStaffMember = (data: any, callback?: any): Action => {
  return {
    type: actionsType.REMOVE_STAFF_MEMBER_ACTION,
    data,
    callback,
  };
};

export const updateStaffMemberPermission = (data: any, callback?: any): Action => {
  return {
    type: actionsType.UPDATE_STAFF_MEMBER_PERMISSION_ACTION,
    data,
    callback,
  };
};

export const getAllPermissionList = (callback?: any): Action => {
  return {
    type: actionsType.GET_ALL_PERMISSION_LIST,
    callback,
  };
};

export const getStaffMemberDetails = (data: any, callback?: any): Action => {
  return {
    type: actionsType.GET_STAFF_MEMBER_DETAILS,
    data,
    callback,
  };
};

export const saveStaffMemberDetails = (data: any, callback?: any): Action => {
  return {
    type: actionsType.SAVE_STAFF_MEMBER_DETAILS,
    data,
    callback,
  };
};

export const checkStaffExistence = (data: any, callback?: any): Action => {
  return {
    type: actionsType.CHECK_STAFF_MEMBER_EXISTENCE_ACTION,
    data,
    callback,
  };
};

export const sendStaffinvitation = (data: any, callback?: any): Action => {
  return {
    type: actionsType.SEND_STAFF_INVITATION_ACTION,
    data,
    callback,
  };
};

export const updateUserInvitation = (data: any, callback?: any): Action => {
  return {
    type: actionsType.UPDATE_USER_INVITATION_ACTION,
    data,
    callback,
  };
};

export const saveStaffInvitationData = (data: any): Action => {
  return {
    type: actionsType.SAVE_STAFF_INVITATION_ACTION,
    data,
  };
};

export const checkStaffInvite = (callback?: any): Action => {
  return {
    type: actionsType.CHECK_STAFF_INVITE_EXISTENCE,
    callback,
  };
};

export const getRequestStaffPermission = (data: any): Action => {
  return {
    type: actionsType.GET_REQUEST_PERMISSION_ACTION,
    data,
  };
};
