import { SAVE_BRAND_NAMES } from "./../ActionTypes/index";
import * as catalogTypes from "./../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
  additionalData?: any;
}

export const toggleItemAvailability = (
  data: {
    store_item_id: number;
    store_id: number;
    available: number;
    variant_id?: number;
  },
  callback?: any,
  additionalData?: any,
): Action => {
  return {
    type: catalogTypes.TOGGLE_ITEM_AVAILABLITY,
    data,
    callback,
    additionalData,
  };
};

export const setActiveCategoryId = (data: string | number | null): Action => {
  return {
    type: catalogTypes.SET_ACTIVE_CATEGORY_ID,
    data,
  };
};

export const setActiveTagId = (data: number | null): Action => {
  return {
    type: catalogTypes.SET_ACTIVE_TAG_ID,
    data,
  };
};

export const setItemModState = (data: any): Action => {
  return {
    type: catalogTypes.SET_ITEM_MOD_STATE,
    data,
  };
};

export const saveItem = (data: any, callback?: any): Action => {
  return {
    type: catalogTypes.SAVE_ITEM,
    data,
    callback,
  };
};

export const saveCollection = (data: any, callback?: any): Action => {
  return {
    type: catalogTypes.SAVE_COLLECTION,
    data,
    callback,
  };
};

export const updateCollectionStatus = (data: any, callback: any): Action => {
  return {
    type: catalogTypes.UPDATE_COLLECTION_STATUS,
    data,
    callback,
  };
};

export const setCatalogItemSearchText = (data: string): Action => {
  return {
    type: catalogTypes.SET_CATALOG_ITEM_SEARCH_TEXT,
    data,
  };
};

export const fetchCategories = (data: any): Action => {
  return {
    type: catalogTypes.FETCH_CATALOG_CATEGORIES,
    data,
  };
};

export const fetchCollections = (data: any, additionalData?: any): Action => {
  return {
    type: catalogTypes.FETCH_CATALOG_COLLECTIONS,
    data,
    additionalData,
  };
};

export const saveFetchedCollections = (data: any): Action => {
  return {
    type: catalogTypes.SAVE_CATALOG_COLLECTIONS,
    data,
  };
};

export const saveSuggestiveCategories = (data: any): Action => {
  return {
    type: catalogTypes.SAVE_CATALOG_CATEGORIES,
    data,
  };
};

export const deleteCatalogItem = (data: any, callback?: any) => {
  return {
    type: catalogTypes.DELETE_CATALOG_ITEM,
    data,
    callback,
  };
};

export const deleteCategory = (data: any) => {
  return {
    type: catalogTypes.DELETE_CATEGORY,
    data,
  };
};

export const updateCategory = (data: any, callback?: any): Action => {
  return {
    type: catalogTypes.UPDATE_CATEGORY,
    data,
    callback,
  };
};

export const uploadCatalogImage = (data: any, callback: any) => {
  return {
    type: catalogTypes.UPLOAD_IMAGE,
    data,
    callback,
  };
};

export const searchImage = (searchTxt: string, storeid: number) => {
  return {
    type: catalogTypes.SEARCH_IMAGE,
    searchTxt,
    storeid,
  };
};

export const saveSearchImage = (data: any) => {
  return {
    type: catalogTypes.SAVE_SEARCHED_IMAGE,
    data,
  };
};

export const saveReorderedCategory = (data: any, callback: any) => {
  return {
    type: catalogTypes.SAVE_REORDERED_CATEGORY,
    data,
    callback,
  };
};

export const saveReorderedTag = (data: any, callback: any) => {
  return {
    type: catalogTypes.SAVE_REORDERED_TAG,
    data,
    callback,
  };
};

export const fetchIndividualItemDetails = (data: any, callback?: any): Action => {
  return {
    type: catalogTypes.GET_INDIVIDUAL_ITEM_DETAILS,
    data,
    callback,
  };
};

export const saveReorderedItems = (data: any, callback?: any) => {
  return {
    type: catalogTypes.SAVE_REORDERED_ITEMS,
    data,
    callback,
  };
};

export const saveReorderedTagItems = (data: any, callback?: any) => {
  return {
    type: catalogTypes.SAVE_REORDERED_TAG_ITEMS,
    data,
    callback,
  };
};

export const updateCatalogList = (data: any, callback?: any) => {
  return {
    type: catalogTypes.UPDATE_CATALOG_DATA,
    data,
    callback,
  };
};

export const fetchCatalogItemsByBasicDetails = (data: any, callback?: any, clickedCategoryID?: any) => {
  return {
    type: catalogTypes.FETCH_CATALOG_ITEMS_BASIC_DETAILS,
    data,
    callback,
    clickedCategoryID,
  };
};

export const fetchItemsInsideClickedCategoryOfCollection = (data: any, callback?: any) => {
  return {
    type: catalogTypes.FETCH_ITEMS_INSIDE_CLICKED_CATEGORY_OF_COLLECTIONS,
    data,
    callback,
  };
};

export const saveClickedCategoryItemsOfCollection = (data: any) => {
  return {
    type: catalogTypes.SAVE_CLICKED_CATEGORY_ITEM_OF_COLLECTON,
    data,
  };
};

export const fetchRecentlyCreatedVariants = () => {
  return {
    type: catalogTypes.FETCH_RECENTLY_CREATED_VARIANTS,
  };
};

export const saveRecentlyCreatedVariants = (data?: any) => {
  return {
    type: catalogTypes.SAVE_RECENTLY_CREATED_VARIANTS,
    data,
  };
};

export const saveCatalogItemsByBasicDetails = (data: any) => {
  return {
    type: catalogTypes.SAVE_CATALOG_ITEMS_BASIC_DETAILS,
    data,
  };
};

export const fetchSuggestiveVariants = () => {
  return {
    type: catalogTypes.FETCH_SUGGESTIVE_VARIANTS,
  };
};

export const saveSuggestiveVariants = (data?: any) => {
  return {
    type: catalogTypes.SAVE_SUGGESTIVE_VARIANTS,
    data,
  };
};

export const saveCatalogItemsByBasicDetailsForRearrange = (data: any) => {
  return {
    type: catalogTypes.SAVE_CATALOG_ITEMS_BASIC_DETAILS_FOR_REARRANGE,
    data,
  };
};

export const saveTagItemsByBasicDetailsForRearrange = (data: any) => {
  return {
    type: catalogTypes.SAVE_TAG_ITEMS_BASIC_DETAILS_FOR_REARRANGE,
    data,
  };
};

export const fetchSearchData = (data: any) => {
  return {
    type: catalogTypes.FETCH_SEARCH_DATA,
    data,
  };
};

export const updateCatalogSearchList = (data: any) => {
  return {
    type: catalogTypes.UPDATE_CATALOG_SEARCH_LIST,
    data,
  };
};
export const revertSearchedData = () => {
  return {
    type: catalogTypes.REVERT_SEARCHED_DATA,
  };
};

export const saveSearchedData = (data: any) => {
  return {
    type: catalogTypes.SAVE_SEARCHED_DATA,
    data,
  };
};

export const saveCatalogForCreateBillRender = (data: any) => {
  return {
    type: catalogTypes.SAVE_CATALOG_FOR_CREATE_BILL,
    data,
  };
};

export const fetchCatalogItemById = (data: any, isVariantData: boolean, callback?: any) => {
  return {
    type: catalogTypes.FETCH_CATALOG_ITEM_BY_ID,
    data,
    isVariantData,
    callback,
  };
};

export const addCatalogItem = (data: any, callback: any): Action => {
  return {
    type: catalogTypes.ADD_CATALOG_ITEM,
    data,
    callback,
  };
};

export const getItemsByTagId = (data: any): Action => {
  return {
    type: catalogTypes.GET_ITEMS_BY_TAG_ID,
    data,
  };
};

export const saveSearcheditems = (data: any): Action => {
  return {
    type: catalogTypes.SAVE_SEARCHED_ITEMS,
    data,
  };
};

export const fetchSearchedItems = (data: any, callback?: any): Action => {
  return {
    type: catalogTypes.FETCH_SEARCHED_ITEMS,
    data,
    callback,
  };
};

export const resetCatalogForCreateBill = (): Action => {
  return {
    type: catalogTypes.RESET_CATALOG_FOR_CREATE_BILL,
  };
};

export const updateTag = (data: any, callback: any): Action => {
  return {
    type: catalogTypes.UPDATE_TAG,
    data,
    callback,
  };
};

export const removeItemsFromTag = (data: any, callback: any): Action => {
  return {
    type: catalogTypes.REMOVE_ITEM_FROM_TAG,
    data,
    callback,
  };
};

export const quickUpdateItem = (
  data: {
    store_item_id: number;
    store_id: number;
    available_quantity: number;
    managed_inventory: number;
    variant_id: number;
  },
  callback?: any,
): Action => {
  return {
    type: catalogTypes.QUICK_UPDATE_ITEM,
    data,
    callback,
  };
};

export const uploadCollectionOrder = (data: any, callback: any): Action => {
  return {
    type: catalogTypes.UPLOAD_COLLECTION_ORDER,
    data,
    callback,
  };
};

export const getBrandNames = (): Action => {
  return {
    type: catalogTypes.GET_BRAND_NAMES,
  };
};

export const saveBrandNames = (data: any): Action => {
  return {
    type: catalogTypes.SAVE_BRAND_NAMES,
    data,
  };
};

export const saveCatalogDataToV3 = (data: any, callback: any, additionalData: any): Action => {
  return {
    type: catalogTypes.SAVE_ITEM_V3,
    data,
    callback,
    additionalData,
  };
};

export const saveOtherCollection = (data: any): Action => {
  return {
    type: catalogTypes.SAVE_OTHERCOLLECTION,
    data,
  };
};

export const getSortFilters = () => {
  return {
    type: catalogTypes.GET_SORT_FILTERS,
  };
};

export const saveSortFilters = (data: any): Action => {
  return {
    type: catalogTypes.SAVE_SORT_FILTERS,
    data,
  };
};

export const setSortFilters = (data: any) => {
  return {
    type: catalogTypes.SET_SORT_FILTERS,
    data,
  };
};

export const fetchItemsDetailsByItemIds = (data: any, callback: any): Action => {
  return {
    type: catalogTypes.FETCH_ITEMS_BY_ITEM_IDS,
    data,
    callback,
  };
};

export const setCollectionCardAnimation = (data: any, callback: any): Action => {
  return {
    type: catalogTypes.SET_COLLECTION_CARD_ANIMATION,
    data,
    callback,
  };
};

export const setStoreServicesFlag = (data: any, callback?: any): Action => {
  return {
    type: catalogTypes.SET_STORE_SERVICES_FLAG,
    data,
    callback,
  };
};

export const browseCatalog = (data: {
  type: number,
  page: number,
}, callback?: any): Action => {
  return {
    type: catalogTypes.BROWSE_CATALOG,
    data,
    callback,
  };
};
