/**
 * min-max values for orders
 */
export const ConstantNoOfOrders: {
  from: number;
  till: number;
} = {
  from: 0,
  till: 500,
};

/**
 * min-max values for sales
 */
export const ConstantSalesOfCustomer: {
  from: number;
  till: number;
} = {
  from: 0,
  till: 200000,
};

/**
 * min-max values for days
 */
export const ConstantDaysFromLastOrder: {
  from: number;
  till: number;
} = {
  from: 0,
  till: 360,
};

/**
 * default time to check for new customers added
 */
export const DEFAULT_DATE_TIME_CRM = "2020-01-01 00:00:00";

/**
 * delete and add/update for filters
 */
export const FILTER_STATUS: {
  delete: 0;
  update: 1;
} = {
  delete: 0,
  update: 1,
};

/**
 * status to fetch specific kind of filter
 * currently only fetch active ones
 */
export const FILTER_CUSTOMER_STATUS: {
  active: 1;
} = {
  active: 1,
};

export const crmApiMessages = (CrmRecord: Record<string, any>) => {
  switch (CrmRecord?.error_type) {
    case "invalid_input_error":
      return CrmRecord?.message;
    case "data_already_exists_error":
      return "Excel sheet with this name already exists";
    default:
      return "Cannot upload excel file currently. Please try later";
  }
};

// List Kind types
export const LIST_KIND: {
  whitelistedCustomers: number;
  subscribedCustomers: number;
  activeCustomers: number;
} = {
  whitelistedCustomers: 4,
  subscribedCustomers: 3,
  activeCustomers: 5,
};

export const LAST_ORDER_DATE_DEFAULT = "0000-00-00 00:00:00";
