import { ADV_SEO_ACTION_TYPES } from "../Constants";
import store from "../Redux/Store";
import { isFalsyExceptZero } from "./common";

export function getBannerTextPerType(type) {
  switch (type) {
    case ADV_SEO_ACTION_TYPES.COLLECTION:
      return "collections";
    case ADV_SEO_ACTION_TYPES.ADDITIONAL_PAGES:
      return "pages";
    case ADV_SEO_ACTION_TYPES.CATEGORY:
      return "categories, products";
    default:
      return "";
  }
}

export const getSEOData = () => {
  const { seoDetailPerType } = store.getState().seoToolsReducer;
  if (seoDetailPerType) {
    return seoDetailPerType;
  }
  return {};
};

/**
 *
 * @param type
 * @param id
 * @returns Boolean
 * @description The function checks if Advance SEO is enabled, and invalid SEO data exist for the type ( category, collection, additional_pages)
 */
export const validateSEOPerType = (type, id) => {
  const seoData = getSEOData();
  const seoRecommendation = seoData?.seoRecommendation || {};
  const seoRecommendationFlag = store.getState().storeReducer.store.services.seo_recommendations_flag;

  // Hide Warning Icon for categories if seoRecommendationFlag is false
  if (type === ADV_SEO_ACTION_TYPES.CATEGORY && !seoRecommendationFlag) {
    return false;
  }

  if (Object.prototype.hasOwnProperty.call(seoRecommendation, id)) {
    return seoRecommendation[id].count > 0;
  }
  return false;
};

export const validateCategoryItemSeo = (itemId) => {
  const { activeCategoryId = null, activeTagId = null } = store.getState().catalogReducer || {};
  const seoRecommendationFlag = store.getState().storeReducer?.store?.services?.seo_recommendations_flag;
  const seoData = getSEOData();
  const seoRecommendation = seoData?.seoRecommendation || {};
  if (!isFalsyExceptZero(activeCategoryId) || !isFalsyExceptZero(activeTagId)) {
    const categorySeo = (activeCategoryId || activeCategoryId === 0)
        ? seoRecommendation[activeCategoryId] || {}
        : seoRecommendation[activeTagId] || {};
    return !!(
      seoRecommendationFlag &&
      categorySeo?.child_ids &&
      categorySeo?.child_ids.length > 0 &&
      categorySeo?.child_ids.includes(itemId)
    );
  }
  return false;
};

export const isCategoryOrProductInvalidSEO = (catId) => {
  const seoRecommendationFlag = store.getState().storeReducer.store?.services?.seo_recommendations_flag;
  const seoData = getSEOData();
  const seoRecommendation = seoData?.seoRecommendation || {};
  if (Object.keys(seoRecommendation).length) {
    const categorySeo = seoRecommendation[catId] || {};
    return !!(
      seoRecommendationFlag &&
      (categorySeo?.count > 0 || (categorySeo?.child_ids && categorySeo?.child_ids.length > 0))
    );
  }
  return false;
};

export function formatData(data) {
  if (!data) {
    return {};
  }

  const { seo_recommendations: seoRecommendations } = data;
  const response = {};
  if (seoRecommendations && seoRecommendations?.length) {
    seoRecommendations.forEach((seo) => (response[seo.type_id] = seo));
  }
  return response;
}

export function getSeoCountDisplayText(count) {
  return count > 200 ? "200+" : count;
}
