import { getStoreType } from "./getStoreType";

export function getSettingsTextPrefixByStoreType(): string {
  const storeType = getStoreType();
  switch (storeType) {
    case 1:
      return "Booking";
    case 2:
      return "Booking";
    case 3:
      return "Request";
    default:
      return "Order";
  }
}
