import * as actions from "./../ActionTypes/googleShoppingActions";
import { Dispatch, SetStateAction } from "react";
interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const getGoogleShoppingAccountDetails = (data: any, callback?: any) => {
  return { type: actions.GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS, data, callback };
};

export const saveGoogleShoppingAccountDetails = (data: any) => {
  return { type: actions.SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS, data };
};

// This function will trigger the action to fetch the product analysis for google shopping
export const getProductAnalysis = (data: any, callback?: any) => {
  return { type: actions.FETCH_SHOPPING_PRODUCT_ANALYSIS, data, callback };
};

export const saveProductAnalysis = (data: any) => {
  return { type: actions.SAVE_GOOGLE_SHOPPING_PRODUCT_ANALYSIS, data };
};

export const checkStoreEligibility = (data: any, callback?: any) => {
  return { type: actions.CHECK_STORE_ELIGIBILITY_FOR_SHOPPING, data, callback };
};

export const checkEligibleProductNumber = (data: any, callback?: any) => {
  return { type: actions.CHECK_ELIGIBLE_PRODUCT_NUMBER, data, callback };
};

export const requestAManualDemo = (data: any, callback?: any) => {
  return { type: actions.REQUEST_A_MANUAL_DEMO_ACTION, data, callback };
};

export const getStoreServiceDetails = (data: any, callback?: any) => {
  return { type: actions.GET_STORE_SERVICE_DETAILS, data, callback };
};

export const saveStoreServiceDetails = (data: any, callback?: any) => {
  return { type: actions.SAVE_STORE_SERVICE_DETAILS, data, callback };
};

export const createGoogleShoppingAccount = (data: any, callback?: any) => {
  return { type: actions.CREATE_GOOGLE_SHOPPINGACCOUNT_ACTION, data, callback };
};

export const setUserShippingDetails = (data: any, callback?: any) => {
  return { type: actions.SET_USER_SHIPPING_DETAILS_ACTION, data, callback };
};

export const setApiCallOngoingFlag = (data: any) => {
  return { type: actions.SET_API_CALL_ONGOING_FLAG, data };
};

export const requestPhoneNumberVerification = (data: any, callback?: any) => {
  return { type: actions.REQUEST_PHONE_NUMBER_VERIFICATION, data, callback };
};

export const verifyGoogleShoppingOtp = (data: any, callback?: any) => {
  return { type: actions.VERIFY_GOOGLE_SHOPPING_OTP, data, callback };
};

export const syncAllProducts = (data: any) => {
  return { type: actions.SYNC_ALL_PRODUCTS, data };
};

export const getShoppingAccountDetailsV2 = () => {
  return { type: actions.GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2 };
};

export const saveShoppingAccountDetailsV2 = (data: any) => {
  return { type: actions.SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2, data };
};

export const createGoogleShoppingAccountV2 = (callback: any, resolvePromise?: any) => {
  return { type: actions.CREATE_GOOGLE_SHOPPING_ACCOUNT_V2, callback, resolvePromise };
};

export const generateGoogleShoppingOtpV2 = () => {
  return { type: actions.GENERATE_OTP_GOOGLE_SHOPPING_V2 };
};

export const verifyGoogleShoppingOtpV2 = (history: any, data: any, callback: Dispatch<SetStateAction<boolean>>) => {
  return { type: actions.VERIFY_OTP_GOOGLE_SHOPPING_V2, history, callback, data };
};

export const saveVerificationIdGoogleShoppingV2 = (data: any) => {
  return { type: actions.SAVE_VERIFICATION_ID_GOOGLE_SHOPPING_V2, data };
};

export const getStoreCatalogGoogleShoppingV2 = (callback?: any) => {
  return { type: actions.GET_STORE_CATALOG_GOOGLE_SHOPPING_V2, callback };
};

export const saveStoreCatalogGoogleShoppingV2 = (data: any) => {
  return { type: actions.SAVE_STORE_CATALOG_GOOGLE_SHOPPING_V2, data };
};

export const getStoreAnalyticsGoogleShoppingV2 = () => {
  return { type: actions.GET_STORE_ANALYTICS_GOOGLE_SHOPPING_V2 };
};

export const saveStoreAnalyticsGoogleShoppingV2 = (data: any) => {
  return { type: actions.SAVE_STORE_ANALYTICS_GOOGLE_SHOPPING_V2, data };
};

export const getAutoDescGoogleShoppingV2 = (callback: Dispatch<SetStateAction<string>>, itemId: number) => {
  return { type: actions.AUTO_GENERATE_DESC_GOOGLE_SHOPPING_V2, callback, itemId };
};

export const addItemImageGoogleShoppingV2 = (data: any, callback: any) => {
  return { type: actions.ADD_ITEM_IMAGE_GOOGLE_SHOPPING_V2, data, callback };
};

export const quickItemUpdateGoogleShoppingV2 = (data: any) => {
  return { type: actions.QUICK_ITEM_UPDATE_GOOGLE_SHOPPING_V2, data };
};

export const sendShippingDetailsGoogleShoppingV2 = (data: any) => {
  return { type: actions.SEND_SHIPPING_DETAILS_GOOGLE_SHOPPING_V2, data };
};

export const syncAllProductsGoogleShoppingV2 = () => {
  return { type: actions.SYNC_ALL_PRODUCTS_GOOGLE_SHOPPING_V2 };
};
