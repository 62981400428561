import { put, takeEvery } from "redux-saga/effects";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import * as actionTypes from "./../ActionTypes/creditEngineActions";
import { setPageLoaderVisibility, saveStoreCredits, savePrePaymentData, saveCreditsTransactions } from "../Actions";
import { CREDIT_ENGINE_API } from "../../Services/apiUrls";
import { parseToJson } from "../../Utils/common";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import { setPremiumPurchaseType, setPrePaymentDataToSessionStorage } from "../../Utils/_helper";

function* fetchStoreCredits() {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CREDIT_ENGINE_API.getStoreCredits}`,
    });
    if (response && response.data.status) {
      yield put(saveStoreCredits(response.data.data));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* addStoreCredits(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CREDIT_ENGINE_API.addStoreCredits}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = parseToJson(response.data.data);
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.CREDIT_ENGINE);
      setPrePaymentDataToSessionStorage(parsedData);
      yield put(savePrePaymentData(parsedData));
      actions.callback && actions.callback();
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* fetchStoreCreditsTransactions(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CREDIT_ENGINE_API.getCreditsTransaction}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveCreditsTransactions(parsedData));
      actions.callback && actions.callback(parsedData?.transactions || []);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* updateStoreCredits(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${CREDIT_ENGINE_API.updateStoreCreditsApi}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(savePrePaymentData(parsedData));
      actions.callback && actions.callback(parsedData);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_STORE_CREDITS, fetchStoreCredits);
  yield takeEvery(actionTypes.ADD_CREDITS, addStoreCredits);
  yield takeEvery(actionTypes.GET_CREDITS_TRANSACTIONS, fetchStoreCreditsTransactions);
  yield takeEvery(actionTypes.UPDATE_STORE_CREDITS, updateStoreCredits);
}
