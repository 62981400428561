import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./../Reducers";
import sagas from "./../Sagas";
import { loadState, saveState } from "./../../Utils/persistState";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const persistedState = loadState();

const middlewaresArr: any = [sagaMiddleware];

if (process.env.REACT_APP_CUSTOM_ENV === "DEV") {
  middlewaresArr.push(logger);
}
const store = createStore(rootReducer, persistedState, applyMiddleware(...middlewaresArr));

store.subscribe(() => {
  saveState({
    storeReducer: store.getState().storeReducer,
    catalogReducer: store.getState().catalogReducer,
    ordersReducer: store.getState().ordersReducer,
    businessReducer: store.getState().businessReducer,
    paymentMethodReducer: store.getState().paymentMethodReducer,
    themeReducer: store.getState().themeReducer,
    commonReducer: store.getState().commonReducer,
    userReducer: store.getState().userReducer,
    additonalPagesReducer: store.getState().additionalPagesReducer,
    staffManagementReducer: store.getState().staffManagementReducer,
    creditEngine: store.getState().creditEngine,
    seoToolsReducer: store.getState().seoToolsReducer,
    customerReviewRatingReducer: store.getState().customerReviewRatingReducer,
  });
});

export default store;
sagaMiddleware.run(sagas);
