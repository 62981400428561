import { put, takeEvery } from "redux-saga/effects";
import apiCall from "./../../Services/api";
import * as cartTypes from "./../ActionTypes";
import { setPageLoaderVisibility, cartDetailsById, isCartInfoLoading } from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";
import { CART_API } from "../../Services/apiUrls";
import { parseToJson } from "../../Utils/common";
import { CART_CONSTANTS } from "../../Constants";

// fetch filter cart data
function* getCartsByFilters(actions: any) {
  try {
    yield put(isCartInfoLoading(true));
    const response = yield apiCall({
      method: "POST",
      url: CART_API.getCartsByFiltersApi,
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      const customers = response?.data?.data || [];
      actions?.callback(customers, !!customers?.length);
      yield put(isCartInfoLoading(false));
    }
  } catch (err) {
    yield put(isCartInfoLoading(false));
    ToastNotifyError("Unable to fetch data");
  }
}

function* getOrderCartById(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${CART_API.getOrderCartByIdApi}/${actions.data.id}`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions?.callback && actions?.callback(response?.data?.data);
      yield put(cartDetailsById(response?.data.data));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch pending orders");
  }
}
function* sendAbandonedCartReminder(actions: any, callback?: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: CART_API.sendAbandonedCartReminderApi,
      data: actions.data,
    });
    if (response?.data?.status) {
      actions?.callback && actions?.callback(parseToJson(response?.data?.data));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to send reminder");
  }
}

function* updateCartNotification(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: CART_API.updateCartNotificationApi,
      data: actions.data,
    });
    if (response?.data?.status) {
      actions.callback && actions.callback(true);
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to send notification");
  }
}

function* getFilteredLeadSubscribers(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    yield put(isCartInfoLoading(true));
    const response = yield apiCall({
      method: "POST",
      url: CART_API.getFilteredLeadSubscribers,
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback(response?.data?.data?.customers, !!response?.data?.data?.next);
      yield put(setPageLoaderVisibility(false));
      yield put(isCartInfoLoading(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    yield put(isCartInfoLoading(false));
    ToastNotifyError("Unable to send notification");
  }
}

function* exportCart(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: CART_API.requestCartReport,
      parseToJson: true,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions.callback();
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(cartTypes.GET_CARTS_BY_FILTER, getCartsByFilters);
  yield takeEvery(cartTypes.GET_ORDER_CART_BY_ID, getOrderCartById);
  yield takeEvery(cartTypes.SEND_ABANDONED_CART_REMINDER, sendAbandonedCartReminder);
  yield takeEvery(cartTypes.UPDATE_CART_NOTIFICATION, updateCartNotification);
  yield takeEvery(cartTypes.GET_FILTERED_EMAIL_SUBSCRIBERS, getFilteredLeadSubscribers);
  yield takeEvery(cartTypes.REQUEST_CART_REPORT, exportCart);
}
