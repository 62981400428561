import {
  SET_CUSTOM_DELIVERY_DATA,
  GET_CUSTOM_DELIVERY_DATA,
  GET_CITY_DATA_LIST,
  SAVE_CUSTOM_DELIVERY_DATA_FOR_STORE,
  SAVE_CITY_DATA_LIST,
  GET_ITEM_LIST_WITH_CHARGES_SETUP,
  SET_ITEM_LIST_WITH_CHARGES_SETUP,
  SAVE_CUSTOM_DELIVERY_DATA_FOR_ITEM,
  GET_ALL_REGIONS,
  SAVE_ALL_REGIONS,
  GET_ALL_STATE_BY_REGION,
  SAVE_ALL_STATE_BY_REGION,
  GET_ALL_CITIES_BY_STATE,
  SAVE_ALL_CITIES_BY_STATE,
  GET_ALL_PINCODES_BY_CITY,
  SAVE_ALL_PINCODES_BY_CITY,
  UPDATE_CUSTOM_ZONE_SETUP,
  SET_CUSTOM_ZONES_DELIVERY_DATA,
  GET_CUSTOM_ZONES_DELIVERY_DATA,
  GET_REGIONAL_DETAILS,
  SAVE_REGIONAL_DETAILS,
  GET_ALL_CUSTOM_ZONES_DATA,
  SAVE_ALL_CUSTOM_ZONES_DATA,
  SAVE_ALL_CUSTOM_ZONES_DATA_FOR_ITEM,
  GET_CUSTOM_DELIVERY_SETUP_STATUS,
  SAVE_CUSTOM_DELIVERY_CHOSEN_PRODUCT,
  SET_ESTIMATED_DELIVERY_FLAG,
} from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const setCustomDeliveryData = (data: any, callback?: any): Action => {
  return {
    type: SET_CUSTOM_DELIVERY_DATA,
    data,
    callback,
  };
};

export const getCustomDeliveryData = (data: number, callback?: any): Action => {
  return {
    type: GET_CUSTOM_DELIVERY_DATA,
    data,
    callback,
  };
};

export const setCustomZonesDeliveryData = (data: any, callback?: any): Action => {
  return {
    type: SET_CUSTOM_ZONES_DELIVERY_DATA,
    data,
    callback,
  };
};

export const getCustomZonesDeliveryData = (data: number, callback?: any): Action => {
  return {
    type: GET_CUSTOM_ZONES_DELIVERY_DATA,
    data,
    callback,
  };
};

export const saveCustomDeliveryDataForStore = (data?: any): Action => {
  return {
    type: SAVE_CUSTOM_DELIVERY_DATA_FOR_STORE,
    data,
  };
};

export const saveCustomDeliveryDataForItem = (data?: any): Action => {
  return {
    type: SAVE_CUSTOM_DELIVERY_DATA_FOR_ITEM,
    data,
  };
};

export const getItemListWithChargesSetup = (data: number): Action => {
  return {
    type: GET_ITEM_LIST_WITH_CHARGES_SETUP,
    data,
  };
};

export const saveItemListWithChargesSetup = (data?: any): Action => {
  return {
    type: SET_ITEM_LIST_WITH_CHARGES_SETUP,
    data,
  };
};

export const getAllRegions = (flag: number): Action => {
  return {
    type: GET_ALL_REGIONS,
    data: flag,
  };
};

export const saveAllRegions = (data?: any): Action => {
  return {
    type: SAVE_ALL_REGIONS,
    data,
  };
};

export const getAllStatesByRegion = (data: string, callback?: any): Action => {
  return {
    type: GET_ALL_STATE_BY_REGION,
    data,
    callback,
  };
};

export const saveAllStatesByRegion = (data?: any): Action => {
  return {
    type: SAVE_ALL_STATE_BY_REGION,
    data,
  };
};

export const getAllCitiesByState = (data: string, callback?: any, isMetroRegion?: boolean): Action & any => {
  return {
    type: GET_ALL_CITIES_BY_STATE,
    data,
    callback,
    isMetroRegion,
  };
};

export const saveAllCitiesByState = (data?: any): Action => {
  return {
    type: SAVE_ALL_CITIES_BY_STATE,
    data,
  };
};

export const getAllPincodesByCity = (data: string, isMetroRegion: boolean, callback?: any): Action | any => {
  return {
    type: GET_ALL_PINCODES_BY_CITY,
    data,
    callback,
    isMetroRegion,
  };
};

export const saveAllPincodesByCity = (data?: any): Action => {
  return {
    type: SAVE_ALL_PINCODES_BY_CITY,
    data,
  };
};

export const updateCustomZoneSetup = (data?: any, callback?: any): Action => {
  return {
    type: UPDATE_CUSTOM_ZONE_SETUP,
    data,
    callback,
  };
};

export const getRegionalDetails = (data: string, callback?: any): Action => {
  return {
    type: GET_REGIONAL_DETAILS,
    data,
    callback,
  };
};

export const saveRegionalDetails = (data?: any): Action => {
  return {
    type: SAVE_REGIONAL_DETAILS,
    data,
  };
};

export const getAllCustomZonesData = (
  data: string,
  itemID: number,
  callback?: any,
  zoneName?: string,
): Action & any => {
  return {
    type: GET_ALL_CUSTOM_ZONES_DATA,
    data,
    itemID,
    callback,
    zoneName,
  };
};

export const saveAllCustomZonesData = (data?: any): Action => {
  return {
    type: SAVE_ALL_CUSTOM_ZONES_DATA,
    data,
  };
};

export const saveAllCustomZonesDataForItem = (data?: any): Action => {
  return {
    type: SAVE_ALL_CUSTOM_ZONES_DATA_FOR_ITEM,
    data,
  };
};

export const getCustomDeliverySetupStatus = (callback: any): Action => {
  return {
    type: GET_CUSTOM_DELIVERY_SETUP_STATUS,
    callback,
  };
};

export const saveCustomDeliveryChosenProduct = (data: any): Action => {
  return {
    type: SAVE_CUSTOM_DELIVERY_CHOSEN_PRODUCT,
    data,
  };
};

export const updateEstimateDeliveryFlag = (data: any): Action => {
  return {
    type: SET_ESTIMATED_DELIVERY_FLAG,
    data,
  };
};
