import * as leadTypes from "../ActionTypes";

const initialState = {
  data: {
    items: [],
    order_type: 3,
  },
  leadtemplatesData: [],
};

const leadReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case leadTypes.SET_STORE_LEADGEN_TEMPLATES:
      return { ...state, leadtemplatesData: action.data };
    default:
      return state;
  }
};

export default leadReducer;
