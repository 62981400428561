import * as themeTypes from "./../ActionTypes";

const initialState: any = {
  displayTags: {
    business_types: [],
    colors: [],
  },
  displayThemes: [],
  isDisplayThemeHasMore: false,
  subscriptionDetails: {
    data: {},
    status: false,
    additional_page: null,
    youtube_link: null,
    workspace_details: null,
    theme: null,
    multibanner: null,
    abandoned_cart: null,
    customer_management: null,
    domain: null,
    gstSubscription: null,
  },
  searchTags: [],
  searchedColors: [],
  cfeCtaConfigs: [],
};

const themeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case themeTypes.SAVE_DISPLAY_TAGS:
      return { ...state, displayTags: action.data };
    case themeTypes.SAVE_DISPLAY_THEMES:
      return {
        ...state,
        displayThemes: state.displayThemes.concat(action.data.themes || []),
        isDisplayThemeHasMore: action.data.isNext,
      };
    case themeTypes.EMPTY_DISPLAY_THEMES:
      return {
        ...state,
        displayThemes: [],
        isDisplayThemeHasMore: false,
      };
    case themeTypes.SAVE_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionDetails: {
          status: action.data.status,
          data: action.data.subscription_data || {},
          additional_page: action.data.additional_page || null,
          youtube_link: action.data.youtube_link || null,
          workspace_details: action.data.workspace_details || null,
          theme: action.data.theme || null,
          multibanner: action.data.multibanner || null,
          abandoned_cart: action.data.abandoned_cart || null,
          domain: action.data.domain || null,
          gstSubscription: action.data.gst,
          customerReviewRatingSubscription: action.data.customerReviewRatingSubscription,
          waba: action.data?.waba || null,
          brandWebsiteSubscription: action.data.brandWebsiteSubscription || null,
          ecomSubscription: action.data.ecomSubscription || null,
          upcomingEcomSubscription: action.data.upcomingEcomSubscription || null,
          externalDomain: action.data.externalDomain || null,
          ...action.data,
        },
      };
    case themeTypes.SAVE_SEARCH_TAGS:
      return {
        ...state,
        searchTags: action.data,
      };
    case themeTypes.SAVE_ALL_PRESET_COLORS:
      return {
        ...state,
        searchedColors: action.data,
      };
    case themeTypes.SAVE_CFE_CTA_CONFIGS:
      return {
        ...state,
        cfeCtaConfigs: action.data,
      };
    default:
      return state;
  }
};

export default themeReducer;
