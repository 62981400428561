import * as adsType from "../ActionTypes";

const initialState = {
  adsList: [],
  searchedAdLocations: [],
  keywords: [],
  adDetails: {},
  isAdLocationModalVisible: false,
  isPauseResumeModalVisible: false,
};

const AdsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case adsType.SAVE_ADS_LIST:
      return {
        ...state,
        adsList: action.data || [],
      };
    case adsType.SAVE_AD_KEYWORDS:
      return {
        ...state,
        keywords: action.data || [],
      };
    case adsType.SAVE_SEARCHED_AD_LOCATIONS:
      return {
        ...state,
        searchedAdLocations: action.data || [],
      };
    case adsType.SAVE_AD_DETAILS:
      return {
        ...state,
        adDetails: action.data || {},
      };
    case adsType.TOGGLE_AD_LOCATION_MODAL:
      return {
        ...state,
        isAdLocationModalVisible: action.data,
      };
    case adsType.TOGGLE_PAUSE_RESUME_MODAL:
      return {
        ...state,
        isPauseResumeModalVisible: action.data,
      };
    default:
      return state;
  }
};

export default AdsReducer;
