import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes";
import { ToastNotifyError, ToastNotifySuccess } from "./../../Components/Toast";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import LocalStorageHelper from "./../../Utils/LocalStorageHelper";
import {
  saveStoreInfo,
  setPageLoaderVisibility,
  setloginPageInfo,
  setOnboardingDomainInfo,
  getStaffMemberDetails,
  saveStaffInvitationData,
  checkStaffInvite,
  saveStoreCredits,
  fetchFeatureLocks,
} from "./../Actions";
import { parseToJson } from "../../Utils/common";
import { LOGIN_API, DELIVERY_AUTHENTICATION_API } from "../../Services/apiUrls";
import { logoutUser } from "../../Utils/_helper";
import { BUY_SUBSCRIPTION } from "../../Constants/routesConstant";
import { goToRoute } from "../../Utils/goToRoute";
import {
  LOGO_AND_NAME_STORAGE_KEY,
  ToggleStateModel,
  getVisibilityForFooter,
  getVisibilityForHeader,
} from "../../Utils/nameAndLogoVisibilityHelpers";
import { TEMP_TOKEN } from "../../Utils/constants";

function* generateOTP(actions: any) {
  try {
    const otpResponse = yield apiCall({
      method: "POST",
      url: `${LOGIN_API.generateOTP}/${actions.data.data}`,
      data: {
        g_recaptcha_token: actions.data.recaptchaToken,
        source: actions.data.source,
      },
    });
    if (otpResponse && otpResponse.data.status) {
      actions && actions.data.callback();
      ToastNotifySuccess("OTP sent Successfully");
    } else {
      ToastNotifyError("Error !");
    }
  } catch (err) {
    ToastNotifyError(err?.data?.message);
    console.log(err);
  }
}

function* verifyOTP(actions: any) {
  try {
    const verifyResponse = yield apiCall({
      method: "POST",
      url: LOGIN_API[actions.data.useOldOTPService ? "verifyOTP" : "verifyOTPLess"](),
      data: actions.data.payload,
    });
    if (verifyResponse && verifyResponse.data.status) {
      const parsedData = parseToJson(verifyResponse.data.data);
      LocalStorageHelper.add("auth_token", parsedData.auth_token);
      const staffData = {
        is_invitation_available: parsedData.is_invitation_available,
        staff_invitation: parsedData.staff_invitation,
      };
      yield put(saveStaffInvitationData(staffData));
      yield put(saveStoreInfo(parsedData));
      if (parsedData?.staff_invitation?.invited_store_id) {
        yield put(
          getStaffMemberDetails({ phone: actions.data.phone, storeId: parsedData?.staff_invitation?.invited_store_id }),
        );
      }
      ToastNotifySuccess("OTP Verified Successfully");
      actions.data && actions.data.callback && actions.data.callback(true, parsedData);
    } else {
      actions.data && actions.data.callback && actions.data.callback(false, false);
      ToastNotifyError(verifyResponse.data.message);
    }
  } catch (err) {
    actions.data && actions.data.callback && actions.data.callback(false, false);
    ToastNotifyError(err?.data?.message);
  }
}

function* verifyOpenOTP(actions: any) {
  try {
    const verifyResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vo1/user/loginAndCreateStore`,
      data: {
        otp: actions.data.otp,
        phone: actions.data.phone,
        source: window?.location?.href || "",
      },
    });
    if (verifyResponse && verifyResponse.data.status) {
      const parsedData = parseToJson(verifyResponse.data.data);
      LocalStorageHelper.add(TEMP_TOKEN, parsedData.auth_token);
      if (actions.data && !actions.data.from) {
        LocalStorageHelper.add("auth_token", parsedData.auth_token);
        yield put(saveStoreInfo(parsedData));
      }
      ToastNotifySuccess("Successfully Verified");
      actions.data && actions.data.callback && actions.data.callback(true, parsedData);
    } else {
      actions.data && actions.data.callback && actions.data.callback(false, verifyResponse.data.message);
      ToastNotifyError(verifyResponse.data.message);
    }
  } catch (err) {
    actions.data && actions.data.callback && actions.data.callback(false, err?.data?.message);
    ToastNotifyError(err?.data?.message);
  }
}

function* createStore(actions: any) {
  try {
    const createStoreResponse = yield apiCall({
      method: "POST",
      url: `${LOGIN_API.createStore}`,
      data: {
        phone: actions.data.phone,
        user_id: actions.data.user_id,
        store_name: actions.data.store_name,
        store_type: "dukaan",
        reference_phone: "",
        verify_phone: 1,
        language_id: 1,
        secret_key: "wFqjaY2jR85nMmgI7wqvPHd6mYaEN9a1",
        source: actions.data.source,
      },
    });

    if (createStoreResponse && createStoreResponse.data.status) {
      yield put(saveStoreInfo(parseToJson(createStoreResponse.data.data)));
      actions.callback && actions.callback(createStoreResponse.data.data);
      actions.data && actions.data.callback && actions.data.callback(createStoreResponse.data.status);
      // ToastNotifySuccess("Successfully created store");
      // goToRoute("/orders");
    } else {
      actions.data && actions.data.callback && actions.data.callback(false);
      ToastNotifyError(createStoreResponse.data.message);
    }
  } catch (err) {
    actions.data && actions.data.callback && actions.data.callback(false);
    ToastNotifyError(err?.data?.message);
  }
}

function* authenticateUser(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const payload = {
      auth_token: actions.data,
    };
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/authenticate`,
      data: payload,
    });
    if (response && response.data.status) {
      if (response.data.store) {
        const storeData = { ...response.data.store, user_id: response.data.user_id };
        yield put(saveStoreInfo(storeData));
        yield put(saveStoreCredits(storeData?.store_info?.total_credits || 0));
        // Fetch feature locks map
        yield put(fetchFeatureLocks());

        // setting the values to localstorage due to an anomaly where the redux store gets overwritten by the cfe website running on iframe
        // this is not a proper solution but will have to keep it until the above mentioned issue is fixed
        const valuesToSet: ToggleStateModel = {
          headerLogoActive: getVisibilityForHeader(response.data.store?.theme?.store_logo_status),
          headerNameActive: getVisibilityForHeader(response.data.store?.theme?.store_name_status),
          footerLogoActive: getVisibilityForFooter(response.data.store?.theme?.store_logo_status),
          footerNameActive: getVisibilityForFooter(response.data.store?.theme?.store_name_status),
        };
        LocalStorageHelper.add(LOGO_AND_NAME_STORAGE_KEY, valuesToSet);
      } else {
        yield put(saveStoreInfo(response.data));
      }
      yield put(setPageLoaderVisibility(false));
      actions.callback && actions.callback(response?.data?.store);
      if (window.location.pathname !== BUY_SUBSCRIPTION) {
        if (response.data.store?.store_id) {
          yield put(checkStaffInvite());
          yield put(getStaffMemberDetails({ phone: response.data.phone, storeId: response.data.store?.store_id }));
        } else {
          logoutUser();
        }
      }
    } else {
      if (window.location.pathname !== BUY_SUBSCRIPTION) {
        yield put(setPageLoaderVisibility(false));
        LocalStorageHelper.delete("auth_token");
        LocalStorageHelper.delete("state");
        goToRoute("/");
      }
    }
  } catch (err: any) {
    if (window.location.pathname !== BUY_SUBSCRIPTION) {
      LocalStorageHelper.delete("auth_token");
      LocalStorageHelper.delete("state");
      goToRoute("/");
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Please login again!");
    }
  }
}

function* getLoginHelpScreen(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const loginHelpScreenResponse = yield apiCall({
      method: "GET",
      url: `${LOGIN_API.getLoginPageInfo}`,
    });
    if (loginHelpScreenResponse && loginHelpScreenResponse.data.status) {
      yield put(setPageLoaderVisibility(false));
      yield put(setloginPageInfo(parseToJson(loginHelpScreenResponse.data.data)));
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Error !");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    console.log(err);
  }
}

function* getOnboardingPageInfo(actions: any) {
  try {
    const onboardingPageInfoResponse = yield apiCall({
      method: "GET",
      url: `${LOGIN_API.getOnboardingPageInfo}`,
    });
    if (onboardingPageInfoResponse && onboardingPageInfoResponse.data.status) {
      yield put(setOnboardingDomainInfo(parseToJson(onboardingPageInfoResponse.data.data)));
      actions.data && actions.data.callback && actions.data.callback(true);
    } else {
      actions.data && actions.data.callback && actions.data.callback(false);
    }
  } catch (err) {
    actions?.data?.callback(false);
  }
}
function* generateDeliveryOTP(actions: any) {
  try {
    const otpResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}${DELIVERY_AUTHENTICATION_API.deliveryOtpApi}${actions.data.phone}`,
    });
    if (otpResponse && otpResponse.data.status) {
      actions.callback && actions.callback(otpResponse.data.status);
      ToastNotifySuccess("OTP sent Successfully");
    } else {
      actions.callback && actions.callback(otpResponse.data.status);
    }
  } catch (err: any) {
    actions.callback && actions.callback(err.data.status);
  }
}

function* deliveryMerchantLogin(actions: any) {
  try {
    const loginResponse = yield apiCall({
      method: "POST",
      data: actions.data.data,
      url: `${config.base_url}${DELIVERY_AUTHENTICATION_API.deliveryMerchantLoginApi}`,
    });
    if (loginResponse && loginResponse.status) {
      actions.callback && actions.callback(loginResponse.status);
    }
  } catch (err) {
    actions.callback && actions.callback(false);
    console.log(err);
  }
}

function* logoutOfAllDevices(actions) {
  try {
    const invalidateAuthResponse = yield apiCall({
      method: "DELETE",
      url: `${config.base_url}${LOGIN_API.invalidateAuthByPhone(actions.data?.phone)}`,
    });
    if (invalidateAuthResponse?.data?.status) {
      actions?.callback();
    } else {
      ToastNotifyError("something went bad!!");
    }
  } catch (err) {
    ToastNotifyError(err?.data?.message || "something went bad!");
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GENERATE_OTP, generateOTP);
  yield takeEvery(actionTypes.VERIFY_OTP, verifyOTP);
  yield takeEvery(actionTypes.VERIFY_OPEN_OTP, verifyOpenOTP);
  yield takeEvery(actionTypes.AUTHENTICATE_USER, authenticateUser);
  yield takeEvery(actionTypes.CREATE_STORE, createStore);
  yield takeEvery(actionTypes.GET_LOGIN_PAGE_INFO, getLoginHelpScreen);
  yield takeEvery(actionTypes.GET_ONBOARDING_PAGE_INFO, getOnboardingPageInfo);
  yield takeEvery(actionTypes.GENERATE_DELIVERY_OTP, generateDeliveryOTP);
  yield takeEvery(actionTypes.DELIVERY_MERCHANT_LOGIN, deliveryMerchantLogin);
  yield takeEvery(actionTypes.LOGOUT_FROM_ALL_DEVICES, logoutOfAllDevices);
}
