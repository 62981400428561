import { CLEAR_FBE_ACCOUNT_DETAILS, SAVE_FBE_ACCOUNT_DETAILS } from "../ActionTypes";

const initialState = {
  data: {},
};

const FbeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SAVE_FBE_ACCOUNT_DETAILS:
      return {
        ...state,
        data: action.data,
      };
    case CLEAR_FBE_ACCOUNT_DETAILS:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};

export default FbeReducer;
