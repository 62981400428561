import * as actionTypes from "../ActionTypes";

export function searchDomain(data: any) {
  return {
    type: actionTypes.SEARCH_DOMAIN,
    data,
  };
}

export function searchOpenDomain(data) {
  return {
    type: actionTypes.SEARCH_OPEN_DOMAIN,
    data,
  };
}

export function defaultSearchDomain(data: any) {
  return {
    type: actionTypes.DEFAULT_SEARCH_DOMAIN,
    data,
  };
}

export function saveDomainList(data: any) {
  return {
    type: actionTypes.SAVE_DOMAIN_LIST,
    data,
  };
}

export function saveSearchDomainList(data: any) {
  return {
    type: actionTypes.SAVE_SEARCH_DOMAIN_LIST,
    data,
  };
}

// export function saveTransformedSearchDomainList(data: any) {
//   return {
//     type: actionTypes.SAVE_TRANSFORMED_DOMAIN_LIST,
//     data,
//   };
// }

export const initDomainPuchase = (data: any, callback: any) => {
  return {
    type: actionTypes.INITIATE_DOMAIN_PURCHASE,
    data,
    callback,
  };
};

export const getDomainDetails = (data: any) => {
  return {
    type: actionTypes.GET_DOMAIN_DETAILS,
    data,
  };
};

export const saveDomainDetails = (data: any) => {
  return {
    type: actionTypes.SAVE_DOMAIN_DETAILS,
    data,
  };
};

export const initDomainSetupPurchase = (data: any, callback: any) => {
  return {
    type: actionTypes.INITIATE_DOMAIN_SETUP_PURCHASE,
    data,
    callback,
  };
};

export function storeDomains() {
  return {
    type: actionTypes.STORE_DOMAINS,
  };
}

export function domainRenewalDetails(data: any, callback?: any) {
  return {
    type: actionTypes.DOMAIN_RENEWAL_DETAILS,
    data,
    callback,
  };
}

export function initiateDomainRenewal(data: any, callback: any) {
  return {
    type: actionTypes.INITIATE_DOMAIN_RENEWAL,
    data,
    callback,
  };
}

export function saveAllDomainDetails(data: any) {
  return {
    type: actionTypes.SAVE_ALL_DOMAIN_DETAILS,
    data,
  };
}

export function saveDomainRenewalDetails(data: any) {
  return {
    type: actionTypes.SAVE_DOMAIN_RENEWAL_DETAILS,
    data,
  };
}
export function savedomainRenewalExpiry(data: any) {
  return {
    type: actionTypes.SAVE_DOMAIN_RENEWAL_EXPIRY,
    data,
  };
}

export function saveDomainRenewalYear(data: any) {
  return {
    type: actionTypes.SAVE_DOMAIN_YEAR,
    data,
  };
}

export function fetchDomainCart(data?: any, callback?: any) {
  return {
    type: actionTypes.FETCH_DOMAIN_CART,
    data,
    callback,
  };
}

export function saveDomainCart(data: any) {
  return {
    type: actionTypes.SAVE_DOMAIN_CART,
    data,
  };
}

export function updateDomainCart(data: any, callback?: any) {
  return {
    type: actionTypes.UPDATE_DOMAIN_CART,
    data,
    callback,
  };
}

export function initiateDomainCartPurchase(data: any, callback?: any) {
  return {
    type: actionTypes.INITIATE_DOMAIN_CART_PURCHASE,
    data,
    callback,
  };
}

export function getLatestDomainPurchaseDetails(data: any, callback?: any) {
  return {
    type: actionTypes.GET_LATEST_DOMAIN_PURCHASE_DETAILS,
    data,
    callback,
  };
}

export function saveLatestDomainPurchaseDetails(data: any) {
  return {
    type: actionTypes.SAVE_LATEST_DOMAIN_PURCHASE_DETAILS,
    data,
  };
}

export function checkDomainAvailable(data: any, callback?: any) {
  return {
    type: actionTypes.CHECK_DOMAIN_AVAILABLE,
    data,
    callback,
  };
}

export function captureLoginMerchantLeads(data) {
  return {
    type: actionTypes.CAPTURE_DLP_MERCHANT_LEADS,
    data,
  };
}
