import { GET_ALL_UTILITIES_DATA, SAVE_UTILITIES_DATA } from "../ActionTypes/utilities";

export function getAllUtilitiesData(callback: any) {
  return  {
    type: GET_ALL_UTILITIES_DATA,
    callback,
  };
}

export function saveAllUtilitiesData(data: any) {
  return  {
    type: SAVE_UTILITIES_DATA,
    data,
  };
}
