import { FC, PropsWithChildren } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/Reducers";
import { checkToShowBanner } from "../../../Utils/checkToShowBanner";
import useIsDashy from "../../../Utils/useIsDashy";

interface IPremiumBody {
  customClassName?: string;
  customStyle?: any;
  noAlertbar?: boolean;
  maxWidth?: string | undefined;
}

const PremiumBody: FC<PropsWithChildren<IPremiumBody>> = (props) => {
  const classes = useStyles();
  const { storeData } = useSelector((state: RootState) => ({
    storeData: state.storeReducer.store,
  }));

  return (
    <Box
      component="section"
      className={clsx(
        classes.BodyComponent,
        !props.noAlertbar && storeData?.theme?.is_demo === 1 && classes.classWhenThemeAlertBarPresent,
        props.customClassName || "",
        "relative",
      )}
      style={props.customStyle || {}}
      maxWidth={props.maxWidth}
    >
      {props.children}
    </Box>
  );
};

export default PremiumBody;
