import * as adsTypes from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const getAdsList = (callback?: any): Action => {
  return {
    type: adsTypes.FETCH_ADS_LIST,
    callback,
  };
};

export const saveAdsList = (data: any): Action => {
  return {
    type: adsTypes.SAVE_ADS_LIST,
    data,
  };
};

export const searchAdLocations = (data: any): Action => {
  return {
    type: adsTypes.SEARCH_AD_LOCATIONS,
    data,
  };
};

export const saveSearchedAdLocations = (data: any): Action => {
  return {
    type: adsTypes.SAVE_SEARCHED_AD_LOCATIONS,
    data,
  };
};

export const getAdKeywords = (data: any): Action => {
  return {
    type: adsTypes.FETCH_AD_KEYWORDS,
    data,
  };
};

export const saveAdKeywords = (data: any): Action => {
  return {
    type: adsTypes.SAVE_AD_KEYWORDS,
    data,
  };
};

export const saveAd = (data: any, callback: any): Action => {
  return {
    type: adsTypes.SAVE_AD,
    data,
    callback,
  };
};

export const createAd = (data: any): Action => {
  return {
    type: adsTypes.CREATE_AD,
    data,
  };
};

export const initiateAdPurchase = (data: any, callback: any): Action => {
  return {
    type: adsTypes.INITIATE_AD_PURCHASE,
    data,
    callback,
  };
};

export const getAdDetails = (data: any, callback?: any): Action => {
  return {
    type: adsTypes.FETCH_AD_DETAILS,
    data,
    callback,
  };
};

export const saveAdDetails = (data: any): Action => {
  return {
    type: adsTypes.SAVE_AD_DETAILS,
    data,
  };
};

export const updateAdState = (data: any, callback?: any): Action => {
  return {
    type: adsTypes.UPDATE_AD_STATE,
    data,
    callback,
  };
};

export const toggleAdLocationModal = (data: any): Action => {
  return {
    type: adsTypes.TOGGLE_AD_LOCATION_MODAL,
    data,
  };
};

export const togglePauseResumeModal = (data: any): Action => {
  return {
    type: adsTypes.TOGGLE_PAUSE_RESUME_MODAL,
    data,
  };
};
