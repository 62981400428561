import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { MARKETING_API } from "../../Services/apiUrls";
import { setPageLoaderVisibility, saveMarketingTags, saveAddressList } from "../Actions";
import { ToastNotifySuccess, ToastNotifyError } from "../../Components/Toast";

/**
 * This function will make an post api call to add a fb pixel or google Analytics Id
 * @param actions
 */
function* setMarketingTag(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data.data,
      url: config.base_url + MARKETING_API.setMarketingTagsApi,
    });
    const type = actions.data.data.type === "facebook_pixel" ? "Facebook pixel" : "Google Analytics";
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      actions.data.callBack && actions.data.callBack();
      if (actions && actions.data && actions.data.data.delete) {
        ToastNotifySuccess(`Id deleted Successfully!`);
      } else {
        ToastNotifySuccess(`Id added Successfully!`);
      }
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an get api call to get  fb pixel or google Analytics Id
 * @param actions
 */
function* getMarketingTag(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: config.base_url + MARKETING_API.getMarketingTagsApi,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      const payload = { ...response.data.marketing_tags };
      yield put(saveMarketingTags(payload));
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}
function* getCustomerAddressList(actions: any) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: config.base_url + MARKETING_API.getCustomerAddressListApi,
      parseToJson: true,
    });
    if (response && response.status) {
      const payload = response?.data?.data?.address_fields_list;
      yield put(saveAddressList(payload));
    }
  } catch (err) {
    console.log(err);
  }
}

function* setCustomerAddressList(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + MARKETING_API.setCustomerAddressListApi,
    });
    if (response && response.status) {
      ToastNotifySuccess(`Address Fields Edited Successfully!`);
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.SET_MARKETING_TAG, setMarketingTag);
  yield takeEvery(actionTypes.GET_MARKETING_TAG, getMarketingTag);
  yield takeEvery(actionTypes.GET_CUSTOMER_ADDRESS_LIST, getCustomerAddressList);
  yield takeEvery(actionTypes.SET_CUSTOMER_ADDRESS_LIST, setCustomerAddressList);
}
