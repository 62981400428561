import { saveGshopCatalogUpdatedList } from "../../Utils/common";
import * as actionType from "../ActionTypes/googleShoppingActions";

const initialState = {
  userShoppingAccountDetails: {},
  productAnalysis: {},
  isApiCallOngoing: false,
  verificationId: 0,
  googleShopStoreAnalytics: {},
  googleShoppingCatalog: {} as any,
};

const GoogleShoppingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionType.SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS:
      return { ...state, userShoppingAccountDetails: action.data || {} };
    case actionType.SAVE_STORE_SERVICE_DETAILS:
      return { ...state, storeServiceType: action.data };
    case actionType.SET_API_CALL_ONGOING_FLAG:
      return { ...state, isApiCallOngoing: action.data };
    case actionType.SAVE_GOOGLE_SHOPPING_PRODUCT_ANALYSIS:
      return { ...state, productAnalysis: action.data };
    case actionType.SAVE_VERIFICATION_ID_GOOGLE_SHOPPING_V2:
      return { ...state, verificationId: action.data };
    case actionType.SAVE_STORE_CATALOG_GOOGLE_SHOPPING_V2:
      return { ...state, googleShoppingCatalog: saveGshopCatalogUpdatedList(action.data) };
    case actionType.SAVE_STORE_ANALYTICS_GOOGLE_SHOPPING_V2:
      return { ...state, googleShopStoreAnalytics: action.data };
    case actionType.SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2:
      return { ...state, userShoppingAccountDetails: action.data || {} };
    default:
      return state;
  }
};

export default GoogleShoppingReducer;
