import { CART_CONSTANTS } from "../../Constants";
import { isFalsyExceptZero } from "../../Utils/common";
import * as cartTypes from "../ActionTypes";

const initialState = {
  data: {
    items: [],
    order_type: 3,
  },
  cartDetailsById: {},
  isLoading: false,
};

const CartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case cartTypes.ADD_TO_CART:
      return { ...state, data: action.data };
    case cartTypes.CART_DETAILS_BY_ID:
      return { ...state, cartDetailsById: action.data };
    case cartTypes.IS_LOADING:
      return { ...state, isLoading: action.data };
    default:
      return state;
  }
};

export default CartReducer;
