import * as bulkUploadType from "./../ActionTypes/bulkUploadType";

export function bulkUploadBatch(data: any, callback?: any) {
  return {
    type: bulkUploadType.BULK_UPLOAD_BATCH,
    data,
    callback,
  };
}

export function uploadBatchId(data: any) {
  return {
    type: bulkUploadType.UPLOAD_BATCH_ID,
    data,
  };
}

export function getBulkUploadDetails(data: any) {
  return {
    type: bulkUploadType.GET_BULK_UPLOAD_DETAILS,
    data,
  };
}

export function setBulkUploadDetails(data: any) {
  return {
    type: bulkUploadType.SET_BULK_UPLOAD_DETAILS,
    data,
  };
}

export function downloadItemsExcelByStoreId(data: any, callback?: any) {
  return {
    type: bulkUploadType.GET_ITEMS_EXCEL_BY_STORE_ID,
    data,
    callback,
  };
}

export function downloadBulkItemsExcelByStoreId(data: any) {
  return {
    type: bulkUploadType.DOWNLOAD_BULK_ITEMS_EXCEL_BY_STORE_ID,
    data,
  };
}

export function getStoreItemsInExcel(data?: any) {
  return {
    type: bulkUploadType.GET_STORE_ITEMS_IN_EXCEL,
    data,
  };
}

export function gstUploadBatchId(data: any) {
  return {
    type: bulkUploadType.UPLOAD_GST_BATCH_ID,
    data,
  };
}

export function setGstBulkUploadDetails(data: any) {
  return {
    type: bulkUploadType.SET_GST_BULK_UPLOAD_DETAILS,
    data,
  };
}
