import * as ordersTypes from "./../ActionTypes";

interface Action {
  type: string;
  data?: any;
  storeid?: number;
  isNext?: boolean;
  callback?: any;
  history?: any;
  page?: number;
  orderDetails?: any;
  isSharebill?: boolean;
}

export const fetchPendingOrders = (data: { store_id: number; page: number }): Action => {
  return {
    type: ordersTypes.FETCH_PENDING_ORDERS,
    data,
  };
};

export const savePendingOrders = (data?: any, isNext?: boolean, page?: number): Action => {
  return {
    type: ordersTypes.SAVE_PENDING_ORDERS,
    data,
    isNext,
    page,
  };
};

export const emptyPendingOrders = (data: any): Action => {
  return {
    type: ordersTypes.EMPTY_PENDING_ORDERS,
    data,
  };
};

export const fetchCompletedOrders = (data: { store_id: number; page: number }): Action => {
  return {
    type: ordersTypes.FETCH_COMPLETED_ORDERS,
    data,
  };
};

export const saveCompletedOrders = (data: any, isNext: boolean, page: number): Action => {
  return {
    type: ordersTypes.SAVE_COMPLETED_ORDERS,
    data,
    isNext,
    page,
  };
};

export const saveWeeklyReports = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_WEEKLY_REPORTS,
    data,
  };
};

export const saveDailyReports = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_DAILY_REPORTS,
    data,
  };
};

export const toggleOrderDetailsModal = (data: { orderHash: string; show: boolean }): Action => {
  return {
    type: ordersTypes.TOGGLE_ORDER_DETAILS_MODAL,
    data,
  };
};

export const getOrderDetailsByOrderHash = (data: string, callback?: any): Action => {
  return {
    type: ordersTypes.GET_ORDER_DETAILS_BY_ORDER_HASH,
    data,
    callback,
  };
};

export const saveOrderDetailsByOrderId = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_ORDER_DETAILS_BY_ORDER_ID,
    data,
  };
};

export const saveOrderReviewDetails = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_ORDER_REVIEW_DETAILS,
    data,
  };
};

export const markOrderComplete = (data: any, storeid: number, callback?: any): Action => {
  return {
    type: ordersTypes.MARK_ORDER_COMPLETE,
    data,
    storeid,
    callback,
  };
};

export const emptyCompletedOrders = (data: any) => {
  return {
    type: ordersTypes.EMPTY_COMPLETED_ORDERS_LIST,
    data,
  };
};

export const updateOrderStatus = (data: any, history?: any, storeid?: number, callback?: any): Action => {
  return {
    type: ordersTypes.UPDATE_ORDER_STATUS,
    data,
    history,
    storeid,
    callback,
  };
};

export function sendShareBill(data: any, orderDetails: any, isSharebill: boolean, callback?: any): Action {
  return {
    type: ordersTypes.SEND_BILL,
    data,
    orderDetails,
    callback,
    isSharebill,
  };
}

export const setOrderItemsPayload = (data: any): Action => {
  return {
    type: ordersTypes.SET_ORDER_ITEMS_PAYLOAD,
    data,
  };
};

export const markPrepaidOrdersComplete = (data: any, callback?: any): Action => {
  return {
    type: ordersTypes.MARK_PREPAID_ORDER_COMPLETE,
    data,
    callback,
  };
};

export const markPrepaidDeliveryDateComponent = (data: boolean): Action => {
  return {
    type: ordersTypes.MARK_PREPAID_DELIVERY_DATE,
    data,
  };
};

export const setOrderDetailsDataTemporary = (data: any): Action => {
  return {
    type: ordersTypes.SET_ORDER_DETAILS_DATA,
    data,
  };
};

export const getTransactionDetails = (data: any): Action => {
  return {
    type: ordersTypes.GET_TRANSACTION_DATA_FOR_ORDER,
    data,
  };
};

export const saveTransactionDetails = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_TRANSACTION_DATA_FOR_ORDER,
    data,
  };
};

export const rejectOrderAction = (data: any, callback: any): Action => {
  return {
    type: ordersTypes.REJECT_ORDER_ACTION,
    data,
    callback,
  };
};

export const shareBillAction = (): Action => {
  return {
    type: ordersTypes.SHARE_BILL_ACTION,
  };
};

export const setOrderDetailsOptionMenu = (data: any): Action => {
  return {
    type: ordersTypes.SET_ORDER_DETAILS_OPTIONS_MENU,
    data,
  };
};

export const createOrder = (data: any): Action => {
  return {
    type: ordersTypes.CREATE_ORDER,
    data,
  };
};

export const saveOrderInfo = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_ORDER_INFO,
    data,
  };
};

export const saveTransactionList = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_TRANSACTION_LIST,
    data,
  };
};

export const getTransactionList = (data: any): Action => {
  return {
    type: ordersTypes.GET_TRANSACTION_LIST,
    data,
  };
};

export const getOrderBannerDetails = (data: any): Action => {
  return {
    type: ordersTypes.GET_ORDER_BANNER_DETAILS,
    data,
  };
};

export const saveOrderBannerDetails = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_ORDER_BANNER_DETAILS,
    data,
  };
};

export const saveOrderPromoCodeDetails = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_ORDER_PROMO_CODE_DETAILS,
    data,
  };
};

export const sendPaymentLink = (data: any, callback: any): Action => {
  return {
    type: ordersTypes.SEND_PAYMENT_LINK,
    data,
    callback,
  };
};

export const sharePaymentLink = (data: any): Action => {
  return {
    type: ordersTypes.SHARE_PAYMENT_LINK,
    data,
  };
};

export const saveDeliveryPartnerDetails = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_DELIVERY_PARTNER_DETAILS,
    data,
  };
};

export const updateOrderParameters = (data: any, callback: any): Action => {
  return {
    type: ordersTypes.UPDATE_ORDER_PARAMETERS,
    data,
    callback,
  };
};

export const createShipmentOrder = (data: any, callback?: any): Action => {
  return {
    type: ordersTypes.CREATE_SHIPMENT_ORDER,
    data,
    callback,
  };
};

export const updateOrderPaymentStatus = (data: any, callback: any): Action => {
  return {
    type: ordersTypes.UPDATE_ORDER_PAYMENT_STATUS,
    data,
    callback,
  };
};

export const setOrderPaymentTempData = (data: any): Action => {
  return {
    type: ordersTypes.SET_ORDER_PAYMENT_TEMP_DATA,
    data,
  };
};

export const getSearchOrdersList = (data: any, callback: any): Action => {
  return {
    type: ordersTypes.GET_SEARCH_ORDER_LIST,
    data,
    callback,
  };
};

export const getOrderReport = (data: any, callback: any): Action => {
  return {
    type: ordersTypes.GET_ORDER_REPORT,
    data,
    callback,
  };
};

export const sendReviewLink = (data: any, callback?: any): Action => {
  return {
    type: ordersTypes.SEND_REVIEW_LINK,
    data,
    callback,
  };
};

export const getOrderLandingPageCards = (callback?: any): Action => {
  return {
    type: ordersTypes.GET_LANDING_PAGE_CARDS,
    callback,
  };
};

export const savePartialPaymentTransactionDetailsForChildOrder = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER,
    data,
  };
};

export const savePrepaidLimitData = (data: any): Action => {
  return {
    type: ordersTypes.SAVE_PREPAID_LIMIT_DATA,
    data,
  };
};

export const getPartialPaymentTransactionDetailsForChildOrder = (data: any): Action => {
  return {
    type: ordersTypes.GET_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER,
    data,
  };
};

export function requestOrderReport(data: any, callback: any) {
  return {
    type: ordersTypes.REQUEST_ORDER_REPORT,
    data,
    callback,
  };
}

export function getServiceableDeliveryPartner(data: any, callback: any) {
  return {
    type: ordersTypes.GET_SERVICEABLE_DELIVERY_PARTNER,
    data,
    callback,
  };
}
