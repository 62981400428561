import { CatalogItem, bulkCatalogWithDraft } from "../types/bulkEditTypes";

function generateRandomId() {
  return "ran-" + Math.random().toString().slice(2);
}

export const IS_EDITED = "isEdited";
export function getBulkCatalogWithDraft(data: bulkCatalogWithDraft) {
  const BulkEditCatalog = data.store_items.reduce((acc: CatalogItem[], storeItem: CatalogItem) => {
    if (data?.common_items?.[storeItem?.id]) {
      acc.push({
        ...data?.common_items?.[storeItem?.id],
        [IS_EDITED]: true,
      } as any);
    } else {
      acc.push(storeItem);
    }
    return acc;
  }, []);

  let BulkEditCatalogWithDraft = [];

  if (data.draft_items) {
    BulkEditCatalogWithDraft = BulkEditCatalog.concat(data.draft_items);
    const BulkEditCatalogWithTempId = BulkEditCatalogWithDraft.map((item) => {
      return {
        ...item,
        draft_item_id: item?.draft_item_id || generateRandomId(),
      };
    });
    return BulkEditCatalogWithTempId;
  }
  return BulkEditCatalog;
}
