import {
  GET_ALL_DNS_MANAGEMENT_RECORDS,
  SAVE_ALL_DNS_MANAGEMENT_RECORDS,
  UPDATE_CUSTOM_RECORDS,
  UPDATE_ADD_CUSTOM_RECORDS,
  DELETE_CUSTOM_RECORDS,
  UPDATE_NAME_SERVERS,
} from "../ActionTypes/dnsManagement";

export const getDnsManagementData = (domainName, callback?: any) => {
  return {
    type: GET_ALL_DNS_MANAGEMENT_RECORDS,
    domainName,
    callback,
  };
};

export const saveDnsManagementData = (data) => {
  return {
    type: SAVE_ALL_DNS_MANAGEMENT_RECORDS,
    data,
  };
};

export const updateCustomRecords = (data, callback) => {
  return {
    type: UPDATE_CUSTOM_RECORDS,
    data,
    callback,
  };
};

export const deleteACustomRecord = (data, callback) => {
  return {
    type: DELETE_CUSTOM_RECORDS,
    data,
    callback,
  };
};

export const updateAddCustomRecords = (data) => {
  return {
    type: UPDATE_ADD_CUSTOM_RECORDS,
    data,
  };
};

export const updateNameServers = (data, callback) => {
  return {
    type: UPDATE_NAME_SERVERS,
    data,
    callback,
  };
};
