import {
  SAVE_ALL_CITIES_BY_STATE,
  SAVE_ALL_CUSTOM_ZONES_DATA,
  SAVE_ALL_CUSTOM_ZONES_DATA_FOR_ITEM,
  SAVE_ALL_PINCODES_BY_CITY,
  SAVE_ALL_REGIONS,
  SAVE_ALL_STATE_BY_REGION,
  SAVE_CITY_DATA_LIST,
  SAVE_CUSTOM_DELIVERY_CHOSEN_PRODUCT,
  SAVE_CUSTOM_DELIVERY_DATA_FOR_ITEM,
  SAVE_CUSTOM_DELIVERY_DATA_FOR_STORE,
  SET_ITEM_LIST_WITH_CHARGES_SETUP,
} from "../ActionTypes";

const initialState = {
  customDeliveryConfigsForStore: {},
  customDeliveryConfigsForItem: {},
  productList: [],
  zones: {
    regions: [],
    statesByRegions: {},
    citiesByState: {},
    pincodesByCity: {},
  },
  customZonesList: [],
  customZonesListForItem: [],
  currentChosenProduct: {
    item_id: 0,
    item_name: "",
    zone_name: "",
  },
};

const CRMReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case SAVE_CUSTOM_DELIVERY_DATA_FOR_STORE: {
      return {
        ...state,
        customDeliveryConfigsForStore: action.data,
      };
    }
    case SAVE_CUSTOM_DELIVERY_DATA_FOR_ITEM: {
      return {
        ...state,
        customDeliveryConfigsForItem: action.data,
      };
    }
    case SET_ITEM_LIST_WITH_CHARGES_SETUP: {
      return {
        ...state,
        productList: action.data,
      };
    }
    case SAVE_ALL_REGIONS: {
      return {
        ...state,
        zones: {
          ...state.zones,
          regions: action.data,
        },
      };
    }
    case SAVE_ALL_STATE_BY_REGION: {
      return {
        ...state,
        zones: {
          ...state.zones,
          statesByRegions: {
            ...state.zones.statesByRegions,
            [action.data.region]: action.data.data,
          },
        },
      };
    }
    case SAVE_ALL_CITIES_BY_STATE: {
      return {
        ...state,
        zones: {
          ...state.zones,
          citiesByState: {
            ...state.zones.citiesByState,
            [action.data.state]: action.data.data,
          },
        },
      };
    }
    case SAVE_ALL_PINCODES_BY_CITY: {
      return {
        ...state,
        zones: {
          ...state.zones,
          pincodesByCity: {
            ...state.zones.pincodesByCity,
            [action.data.city]: action.data.data,
          },
        },
      };
    }
    case SAVE_ALL_CUSTOM_ZONES_DATA: {
      return {
        ...state,
        customZonesList: action.data,
      };
    }
    case SAVE_ALL_CUSTOM_ZONES_DATA_FOR_ITEM: {
      return {
        ...state,
        customZonesListForItem: action.data,
      };
    }
    case SAVE_CUSTOM_DELIVERY_CHOSEN_PRODUCT:
      return {
        ...state,
        currentChosenProduct: action.data,
      };
    default:
      return state;
  }
};

export default CRMReducer;
