import * as actionTypes from "../ActionTypes/seoTools";

const initialState = {
  seoFormDetails: {
    keywords: [""],
    location: {
      name: "",
      canonical_name: "",
    },
    title: {
      is_custom: false,
      value: "",
    },
    description: "",
    seo_recommendations: null,
  },
  hasCustomSEO: false,
  seoFormDropdownOpen: true,
  step1Open: true,
  step2Open: false,
  step3Open: false,
  unsavedSEODetails: false,
  unsavedDetailsModalOpen: false,
  advCustomSeoDetails: null,
  seoDetailPerType: null,
};

const SEOToolsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_SEO_DETAILS:
      return {
        ...initialState,
        seoFormDetails: action.data || initialState.seoFormDetails,
      };
    case actionTypes.SAVE_STEP1_DETAILS:
      return {
        ...state,
        seoFormDetails: {
          ...state.seoFormDetails,
          keywords: action.data.keywords,
          location: {
            ...state.seoFormDetails.location,
            name: action.data.location.name,
            canonical_name: action.data.location.canonical_name,
          },
          title: {
            ...state.seoFormDetails.title,
          },
        },
        step1Open: false,
        step2Open: true,
      };
    case actionTypes.SAVE_STEP2_DETAILS:
      return {
        ...state,
        seoFormDetails: {
          ...state.seoFormDetails,
          keywords: [...state.seoFormDetails.keywords],
          location: {
            ...state.seoFormDetails.location,
          },
          title: {
            ...state.seoFormDetails.title,
            is_custom: action.data.is_custom,
            value: action.data.value,
          },
        },
        step2Open: false,
        step3Open: true,
      };
    case actionTypes.SAVE_STEP3_DETAILS:
      return {
        ...state,
        seoFormDetails: {
          ...state.seoFormDetails,
          keywords: [...state.seoFormDetails.keywords],
          location: {
            ...state.seoFormDetails.location,
          },
          title: {
            ...state.seoFormDetails.title,
          },
          description: action.data,
        },
        step3Open: false,
      };
    case actionTypes.TOGGLE_STEP1_DROPDOWN:
      return {
        ...state,
        step1Open: true,
        step2Open: false,
        step3Open: false,
      };
    case actionTypes.TOGGLE_STEP2_DROPDOWN:
      return {
        ...state,
        step1Open: false,
        step2Open: true,
        step3Open: false,
      };
    case actionTypes.TOGGLE_STEP3_DROPDOWN:
      return {
        ...state,
        step1Open: false,
        step2Open: false,
        step3Open: true,
      };
    case actionTypes.TOGGLE_UNSAVED_DETAILS_MODAL:
      return {
        ...state,
        unsavedDetailsModalOpen: !state.unsavedDetailsModalOpen,
      };
    case actionTypes.TOOGLE_SEO_FORM_DROPDOWN:
      return {
        ...state,
        seoFormDropdownOpen: !state.seoFormDropdownOpen,
      };
    case actionTypes.TOGGLE_UNSAVED_SEO_DETAILS_STATE:
      return {
        ...state,
        unsavedSEODetails: !state.unsavedSEODetails,
      };
    case actionTypes.TOGGLE_HAS_CUSTOM_SEO:
      return {
        ...state,
        hasCustomSEO: action.data,
      };
    case actionTypes.RESET_SEO_DETAILS_STATE:
      return initialState;
    case actionTypes.SAVE_ADV_SEO_DETAILS:
      return {
        ...state,
        advCustomSeoDetails: action.data || initialState.advCustomSeoDetails,
      };
    case actionTypes.SET_ADV_SEO_DETAILS_PER_TYPE:
      return {
        ...state,
        seoDetailPerType: action.data || initialState.seoDetailPerType,
      };
    case actionTypes.UPDATE_SEO_RECOMMENDATION:
      return {
        ...state,
        seoFormDetails: {
          ...state.seoFormDetails,
          seo_recommendations: {
            ...state?.seoFormDetails?.seo_recommendations,
            ...action.data,
          },
        },
      };
    default:
      return state;
  }
};

export default SEOToolsReducer;
