import { saveFeatureLocks, saveSubscriptionIdOnSuccess, saveSubscriptionPricingList } from "./../Actions/subscription";
import { put, takeEvery } from "redux-saga/effects";
import {
  FETCH_FEATURE_LOCKS,
  GET_SUBSCRIPTION_PRICING_LIST,
  INITIATE_DOMAIN_SUBSCRIPTION_PURCHASE,
  INITIATE_SUBSCRIPTION_PURCHASE,
  INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON,
  INITIATE_WABA_PURCHASE,
  REQUEST_CALLBACK,
  SET_SUBSCRIPTION_CART,
  TOGGLE_MEMBERS_ONLY,
  FETCH_STORE_INVOICES,
  FETCH_INVOICE_INFO,
  GENERATE_INVOICE,
} from "./../ActionTypes";
import apiCall from "./../../Services/api";
import {
  FETCH_FEATURE_LOCKS_API,
  GET_SUBSCRIPTION_PRICING_LIST_API,
  initiateDomainSubscriptionPurchaseUrl,
  INITIATE_SUBSCRIPTION_PURCHASE as INITIATE_SUBSCRIPTION_PURCHASE_API,
  INITIATE_WABA_PURCHASE_API,
  INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON_API,
  REQUEST_CALLBACK_FOR_SUBSCRIPTION,
  SET_SUBSCRIPTION_CART_URL,
  MEMBER_ONLY,
  fetchInvoicesUrl,
  fetchInvoiceInfoApi,
  GENERATE_INVOICE_API,
} from "../../Services/apiUrls";
import { savePrePaymentData, setPageLoaderVisibility, setStoreInvoices } from "../Actions";
import { parseToJson, getEventPlatform } from "../../Utils/common";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { setPremiumPurchaseType, setPrePaymentDataToSessionStorage, appendCreditUsedKey } from "../../Utils/_helper";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import store from "../Store";

function* initSubscriptionPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: INITIATE_SUBSCRIPTION_PURCHASE_API,
      data: appendCreditUsedKey(actions.data),
    });
    if (response && response?.data?.status) {
      response.data.data = parseToJson(response.data.data);
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.SUSBSCRIPTION);
      setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.data));
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback && actions.callback(response?.data?.status, response?.data?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError("Cannot enable subscription currently. Please try later.");
    yield put(setPageLoaderVisibility(false));
  }
}

function* initWabaPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: INITIATE_WABA_PURCHASE_API,
      data: appendCreditUsedKey(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.WABA_PURCHASE);
      setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.data));
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback && actions.callback(response?.data?.status, response?.data?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError("Cannot purchase Whatsapp Marketing currently. Please try later.");
    yield put(setPageLoaderVisibility(false));
  }
}

function* requestCallback(actions: any) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = (urlParams.get("token") as any) ?? null;

  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: REQUEST_CALLBACK_FOR_SUBSCRIPTION,
      data: actions.data,
      headers: {
        auth_token: token,
      },
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback();
      actions.showToast &&
        ToastNotifySuccess("We have succesfully received your request. Our executive will call you shortly.");
    } else {
      actions.showToast && ToastNotifyError("Unable to process, Try again Later!");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    actions.showToast && ToastNotifyError("Unable to process, Try again Later!");
    yield put(setPageLoaderVisibility(false));
  }
}

function* setSubscriptionCart(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: SET_SUBSCRIPTION_CART_URL,
      data: appendCreditUsedKey(actions.data),
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveSubscriptionIdOnSuccess(parsedData));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getSubscriptionRenewalPricingList(): any {
  try {
    const response = yield apiCall({
      url: GET_SUBSCRIPTION_PRICING_LIST_API,
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(saveSubscriptionPricingList(response.data.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* initiateDomainSubscriptionPurchase(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: initiateDomainSubscriptionPurchaseUrl(actions.data),
    });
    if (response && response?.data?.status) {
      const parsedData = parseToJson(response?.data?.data);
      sessionStorage.setItem("premiumPurchaseType", "domain");
      sessionStorage.setItem("prePaymentData", parsedData);
      yield put(savePrePaymentData(parsedData));
    }
    actions?.callback(response?.data?.status);
  } catch (err: any) {
    actions?.callback(err?.data?.status);
  }
}

function* fetchFeatureLocks(): any {
  try {
    const response = yield apiCall({
      url: FETCH_FEATURE_LOCKS_API,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveFeatureLocks(response.data.data));
    }
  } catch (err: any) {
    //
  }
}

function* initSubscriptionPurchaseUsingCoupon(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON_API,
      data: appendCreditUsedKey(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.SUBSCRIPTION_USING_COUPONS);
      setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.data));
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback && actions.callback(response?.data?.status, response?.data?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError("Cannot purchase subscriptions using coupons currently. Please try later.");
    yield put(setPageLoaderVisibility(false));
  }
}

function* toggleMembersOnlySaga(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: MEMBER_ONLY.TOGGLE_MEMBER_ONLY,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions.callback?.();
      if (actions?.data?.flag) {
        ToastNotifySuccess("Members Only Login is activated");
      } else {
        ToastNotifySuccess("Members Only Login is deactivated");
      }
    }
  } catch (err) {
    ToastNotifyError("Something Went Bad");
  }
}

function* fetchInvoiceInfo(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: fetchInvoiceInfoApi(actions?.data?.subscription_payment_id),
    });
    actions?.callback && actions?.callback(response);
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    actions?.callback && actions?.callback(err);
  }
}

function* fetchInvoices() {
  yield put(setPageLoaderVisibility(true));
  try {
    const { store_id } = store.getState().storeReducer.store;
    const response = yield apiCall({
      method: "GET",
      url: fetchInvoicesUrl(store_id),
    });
    if (response && response?.data?.status) {
      yield put(setStoreInvoices(response?.data?.invoices));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data?.message);
  }
}

function* generateInvoice(action: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: GENERATE_INVOICE_API,
      data: action?.data,
    });
    action?.callback && action?.callback(response);
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    action?.callback && action?.callback(err);
  }
}

export default function* root() {
  yield takeEvery(INITIATE_SUBSCRIPTION_PURCHASE, initSubscriptionPurchase);
  yield takeEvery(INITIATE_WABA_PURCHASE, initWabaPurchase);
  yield takeEvery(REQUEST_CALLBACK, requestCallback);
  yield takeEvery(SET_SUBSCRIPTION_CART, setSubscriptionCart);
  yield takeEvery(INITIATE_DOMAIN_SUBSCRIPTION_PURCHASE, initiateDomainSubscriptionPurchase);
  yield takeEvery(GET_SUBSCRIPTION_PRICING_LIST, getSubscriptionRenewalPricingList);
  yield takeEvery(FETCH_FEATURE_LOCKS, fetchFeatureLocks);
  yield takeEvery(INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON, initSubscriptionPurchaseUsingCoupon);
  yield takeEvery(TOGGLE_MEMBERS_ONLY, toggleMembersOnlySaga);
  yield takeEvery(FETCH_STORE_INVOICES, fetchInvoices);
  yield takeEvery(FETCH_INVOICE_INFO, fetchInvoiceInfo);
  yield takeEvery(GENERATE_INVOICE, generateInvoice);
}
