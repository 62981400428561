import * as commonTypes from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
}

export const fetchBusinessList = (): Action => {
  return {
    type: commonTypes.FETCH_BUSSINESS_TYPES,
  };
};

export const saveBusinessList = (data: any): Action => {
  return {
    type: commonTypes.SAVE_BUSSINESS_TYPES,
    data,
  };
};

export const submitBusiness = (data: any): Action => {
  return {
    type: commonTypes.SUBMIT_BUSINESS,
    data,
  };
};

export const saveBusinessDetails = (data: any): Action => {
  return {
    type: commonTypes.SAVE_BUSINESS_DETAILS,
    data,
  };
};
