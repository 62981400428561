import * as catalogTypes from "../ActionTypes";

const initialState: any = {
  storeCategories: [],
  storeCollections: [],
  suggestedImages: [],
  suggestedCategories: {},
  activeCategoryId: null,
  activeTagId: null,
  itemModState: {
    show: false,
    mode: "",
    item: {},
  },
  searchedItem: "",
  searchedItemData: {
    next: 0,
    page: 1,
    searchedData: [],
  },
  suggestiveVariants: [],
  recentlyCreatedVariants: [],
  catalog_items: [],
  clickedCategoryItemsOfCollection: [],
  catalog_items_for_rearrange: [],
  tag_items_for_rearrange: [],
  catalog_items_for_create_bill: {},
  searchedInfo: {
    isNext: 0,
    searchItems: [],
    searchPageNo: 1,
    searchText: "",
  },
  tags: [],
  brandNames: [],
  sortAndFilter: {},
  b2bCartItems: [],
};

const catalogReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case catalogTypes.SET_ACTIVE_CATEGORY_ID:
      return { ...state, activeCategoryId: action.data };
    case catalogTypes.SET_ACTIVE_TAG_ID:
      return { ...state, activeTagId: action.data };
    case catalogTypes.SET_ITEM_MOD_STATE:
      return { ...state, itemModState: action.data };
    case catalogTypes.SET_CATALOG_ITEM_SEARCH_TEXT:
      return { ...state, searchedItem: action.data };
    case catalogTypes.SAVE_CATALOG_CATEGORIES:
      return {
        ...state,
        storeCategories: action.data?.store_categories || [],
        suggestedCategories: action.data?.suggested_categories,
        tags: action.data?.tags || [],
      };
    case catalogTypes.SAVE_CATALOG_COLLECTIONS:
      return {
        ...state,
        storeCollections: action.data || [],
      };
    case catalogTypes.SAVE_SEARCHED_IMAGE:
      return { ...state, suggestedImages: action.data };
    case catalogTypes.SAVE_RECENTLY_CREATED_VARIANTS:
      return { ...state, recentlyCreatedVariants: action.data };
    case catalogTypes.SAVE_SUGGESTIVE_VARIANTS:
      return { ...state, suggestiveVariants: action.data };
    case catalogTypes.SAVE_CATALOG_ITEMS_BASIC_DETAILS:
      return { ...state, catalog_items: action.data || [] };
    case catalogTypes.SAVE_CLICKED_CATEGORY_ITEM_OF_COLLECTON:
      return { ...state, clickedCategoryItemsOfCollection: action.data || [] };
    case catalogTypes.SAVE_CATALOG_ITEMS_BASIC_DETAILS_FOR_REARRANGE:
      return { ...state, catalog_items_for_rearrange: action.data };
    case catalogTypes.SAVE_TAG_ITEMS_BASIC_DETAILS_FOR_REARRANGE:
      return { ...state, tag_items_for_rearrange: action.data };
    case catalogTypes.REVERT_SEARCHED_DATA:
      return {
        ...state,
        searchedItemData: {
          next: 0,
          page: 1,
          searchedData: [],
        },
      };
    case catalogTypes.SAVE_SEARCHED_DATA: {
      const oldSearchedData =
        state?.searchedItemData?.searchedData.length > 0 ? state.searchedItemData.searchedData : [];
      const combinedData = [...oldSearchedData, ...action?.data?.items || []];

      return {
        ...state,
        searchedItemData: {
          page: action.data.page,
          next: action.data.next,
          searchedData: combinedData,
        },
      };
    }
    case catalogTypes.UPDATE_CATALOG_SEARCH_LIST: {
      return {
        ...state,
        searchedItemData: {
          ...state.searchedItemData,
          searchedData: action.data,
        },
      };
    }
    case catalogTypes.SAVE_CATALOG_FOR_CREATE_BILL: {
      const tempState = JSON.parse(JSON.stringify(state.catalog_items_for_create_bill));
      tempState[action.data.categoryId] = action.data.items;
      return {
        ...state,
        catalog_items_for_create_bill: tempState,
      };
    }
    case catalogTypes.SAVE_SEARCHED_ITEMS: {
      const newSearchedItems =
        action.data.items.length > 0 ? [...state.searchedInfo.searchItems, ...action.data.items] : [];
      return {
        ...state,
        searchedInfo: {
          searchItems: newSearchedItems,
          searchPageNo: action.data.next ? state.searchedInfo.searchPageNo + 1 : 1,
          isNext: action.data.next,
          searchText: action.data.searchText,
        },
      };
    }
    case catalogTypes.RESET_CATALOG_FOR_CREATE_BILL: {
      return {
        ...state,
        catalog_items_for_create_bill: {},
      };
    }
    case catalogTypes.SAVE_BRAND_NAMES: {
      return {
        ...state,
        brandNames: action.data,
      };
    }
    case catalogTypes.SAVE_SORT_FILTERS: {
      return {
        ...state,
        sortAndFilter: action?.data,
      };
    }
    default:
      return state;
  }
};

export default catalogReducer;
