import {
  CLEAR_FBE_ACCOUNT_DETAILS,
  DELETE_FBE_ACCOUNT,
  GET_FBE_ACCOUNT_DETAILS,
  GET_FBE_ACCOUNT_DETAILS_FROM_SERVER,
  GET_FBE_ADS_VISIBILITY_STATUS,
  GET_FBE_FEED_ID,
  GET_FBE_USER_CATALOG,
  SAVE_FBE_ACCOUNT_DETAILS,
  SET_FBE_ACCOUNT_DETAILS_ON_SERVER,
  SET_FBE_ADS_VISIBILITY_STATUS,
} from "../ActionTypes";
import { AccountDetails } from "../../Types/facebookBusiness";

interface FbeApiParam {
  externalBusinessId: string;
  accessToken: string;
  userID?: string;
}

export function getFbeAccountDetails(data: FbeApiParam, callback?: <U>(status: boolean, data?: U) => any) {
  return {
    type: GET_FBE_ACCOUNT_DETAILS,
    data,
    callback,
  };
}

export function saveFbeAccountDetails(data: any) {
  return {
    type: SAVE_FBE_ACCOUNT_DETAILS,
    data,
  };
}

export function getFbeAccountDetailsFromServer(data?: number, callback?: <U>(status: boolean, data?: U) => any) {
  return {
    type: GET_FBE_ACCOUNT_DETAILS_FROM_SERVER,
    data,
    callback,
  };
}

export function setFbeAccountDetailsOnServer(data: any, callback?: (status: boolean) => void) {
  return {
    type: SET_FBE_ACCOUNT_DETAILS_ON_SERVER,
    data,
    callback,
  };
}

export function clearFbeAccountDetails() {
  return {
    type: CLEAR_FBE_ACCOUNT_DETAILS,
  };
}

export function deleteFbeAccount(data: FbeApiParam, callback: (status: boolean) => void) {
  return {
    type: DELETE_FBE_ACCOUNT,
    data,
    callback,
  };
}

export function getFbeFeedId(data: AccountDetails) {
  return {
    type: GET_FBE_FEED_ID,
    data,
  };
}

export function getFbeUserCatalog(storeID: string, callback?: (...args: any[]) => any) {
  return {
    type: GET_FBE_USER_CATALOG,
    data: storeID,
    callback,
  };
}

export function getFBEAdsVisibilityStatus() {
  return {
    type: GET_FBE_ADS_VISIBILITY_STATUS,
  };
}

export function setFBEAdsVisibilityStatus(data: any) {
  return {
    type: SET_FBE_ADS_VISIBILITY_STATUS,
    data,
  };
}
