import {
  TOGGLE_REQUEST_CONFIRM_MODAL,
  SUBMIT_WHATSAPP_MARKETING_DEMO_REQUEST,
  UPDATE_WHATSAPP_MARKETING_REQUEST_STATUS,
  GET_WHATSAPP_MARKETING_REQUEST_STATUS,
  UPDATE_WABA_CONFIG,
  GET_WABA_CONFIG,
  SET_WABA_CONFIG,
} from "./../ActionTypes";

export function toggleWABARequestConfirmModal(data?: boolean) {
  return { type: TOGGLE_REQUEST_CONFIRM_MODAL, data };
}

export function submitWhatsappMarketingRequest(data?: any, callback?: any) {
  return { type: SUBMIT_WHATSAPP_MARKETING_DEMO_REQUEST, data, callback };
}

export function updataWhatsappMarketingRequestStatus(data?: any) {
  return { type: UPDATE_WHATSAPP_MARKETING_REQUEST_STATUS, data };
}

export function getWhatsappMarketingRequestStatus() {
  return { type: GET_WHATSAPP_MARKETING_REQUEST_STATUS };
}

export function getWabaConfig(data?: string) {
  return { type: GET_WABA_CONFIG, data };
}

export function setWabaConfig(data?: any) {
  return { type: SET_WABA_CONFIG, data};
}

export function updateWabaConfig(data?: any) {
  return { type: UPDATE_WABA_CONFIG, data };
}
