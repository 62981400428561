import {
  FETCH_PRODUCT_INQUIRY,
  SAVE_PRODUCT_INQUIRY,
  TOGGLE_PRODUCT_INQUIRY_ENABLE,
  FETCH_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS,
  SAVE_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS,
  ENABLE_PRODUCT_INQUIRY_FEATURE,
  SAVE_PRODUCT_ENQUIRY_TOTAL_PAGES,
  SET_PRODUCT_INQUIRY_TAB,
  SET_PRODUCT_FILTERED_DATA,
} from "./../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const fetchProductsInquiry = (data?: any, callback?: any): Action => {
  return {
    type: FETCH_PRODUCT_INQUIRY,
    data,
    callback,
  };
};

export const updateProductInquiryForStore = (data?: any): Action => {
  return {
    type: SAVE_PRODUCT_INQUIRY,
    data,
  };
};

export const toggleProductInquiryEnableDisable = (data?: any, callback?: any): Action => {
  return {
    type: TOGGLE_PRODUCT_INQUIRY_ENABLE,
    data,
    callback,
  };
};

export const fetchProductInquiryEnableDisableStatus = (data?: any, callback?: any): Action => {
  return {
    type: FETCH_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS,
    data,
    callback,
  };
};

export const updateProductInquiryToggleStatus = (data?: any): Action => {
  return {
    type: SAVE_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS,
    data,
  };
};

export const updateProductInquiryTotalPages = (data?: any): Action => {
  return {
    type: SAVE_PRODUCT_ENQUIRY_TOTAL_PAGES,
    data,
  };
};

export const enableProductInquiryFeature = (data?: any): Action => {
  return {
    type: ENABLE_PRODUCT_INQUIRY_FEATURE,
    data,
  };
};

export const setProductInquiryTab = (data?: any) => {
  return {
    type: SET_PRODUCT_INQUIRY_TAB,
    data,
  };
};

export const setProductFilteredData = (data?: any) => {
  return {
    type: SET_PRODUCT_FILTERED_DATA,
    data,
  };
};
