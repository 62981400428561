import {
  TOGGLE_SUBSCRIPTION_FLOW_MODAL,
  INITIATE_SUBSCRIPTION_PURCHASE,
  REQUEST_CALLBACK,
  SET_REQUEST_CALLBACK_MODAL,
  SET_SUBSCRIPTION_AMOUNT,
  SET_SUBSCRIPTION_CART,
  SAVE_SUBSCRIPTION_CART_SUCCESS_ID,
  INITIATE_DOMAIN_SUBSCRIPTION_PURCHASE,
  SAVE_ADDONS_PURCHASED,
  GET_SUBSCRIPTION_PRICING_LIST,
  SET_SUBSCRIPTION_PRICING_LIST,
  TOGGLE_SUBSCRIPTION_EXPIRE_MODAL,
  FETCH_FEATURE_LOCKS,
  SAVE_FEATURE_LOCKS,
  INITIATE_WABA_PURCHASE,
  INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON,
  TOGGLE_MEMBERS_ONLY,
} from "../ActionTypes";
import { SubscriptionType } from "../../Types";

interface Action {
  type: string;
  data?: any;
  callback?: any;
  history?: any;
}

interface SubscriptionModalConfig {
  modalView: boolean;
  subscriptionType?: string;
  ecomm_type?: SubscriptionType | "";
  featureName?: string;
}

export const initSubscriptionPurchase = (data: any, callback: any) => {
  return {
    type: INITIATE_SUBSCRIPTION_PURCHASE,
    data,
    callback,
  };
};

export const initWABAPurchase = (data: any, callback: any) => {
  return {
    type: INITIATE_WABA_PURCHASE,
    data,
    callback,
  };
};

export const initSubscriptionPurchaseUsingCoupon = (data: any, callback: any) => {
  return {
    type: INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON,
    data,
    callback,
  };
};

export const toggleSubscriptionFlowModal = (data: SubscriptionModalConfig) => {
  return {
    type: TOGGLE_SUBSCRIPTION_FLOW_MODAL,
    data,
  };
};

export const requestCallback = (data: any, callback?: any, showToast = true) => {
  return {
    type: REQUEST_CALLBACK,
    data,
    callback,
    showToast,
  };
};

export const toggleRequestCallbackModal = (data: any, disableRequestCallbackModalInRoot = false) => {
  return {
    type: SET_REQUEST_CALLBACK_MODAL,
    data,
    disableRequestCallbackModalInRoot,
  };
};

export const setSubscriptionAmount = (data: any): Action => {
  return {
    type: SET_SUBSCRIPTION_AMOUNT,
    data,
  };
};

export const setSubscriptionCart = (data: any): Action => {
  return {
    type: SET_SUBSCRIPTION_CART,
    data,
  };
};

export const saveSubscriptionIdOnSuccess = (data: any): Action => {
  return {
    type: SAVE_SUBSCRIPTION_CART_SUCCESS_ID,
    data,
  };
};

export const initiateDomainSubscriptionPurchase = (data: any, callback?: any): Action => {
  return {
    type: INITIATE_DOMAIN_SUBSCRIPTION_PURCHASE,
    data,
    callback,
  };
};

export const saveAddonsPurchased = (data: any): Action => {
  return {
    type: SAVE_ADDONS_PURCHASED,
    data,
  };
};

export const fetchSubscriptionPricingList = () => ({
  type: GET_SUBSCRIPTION_PRICING_LIST,
});

export const saveSubscriptionPricingList = (data: Record<string, Record<string, string>>[]) => ({
  type: SET_SUBSCRIPTION_PRICING_LIST,
  data,
});

export const toggleSubscriptionExpireModal = (data: boolean) => ({
  type: TOGGLE_SUBSCRIPTION_EXPIRE_MODAL,
  data,
});

export const fetchFeatureLocks = () => ({
  type: FETCH_FEATURE_LOCKS,
});

export const saveFeatureLocks = (data: any) => ({
  type: SAVE_FEATURE_LOCKS,
  data,
});

export const toggleMemberOnly = (data: any, callback?: () => Promise<any>) => ({
  type: TOGGLE_MEMBERS_ONLY,
  data,
  callback,
});
