import * as actionTypes from "../ActionTypes";

const initialState = {
  reviewSettings: {},
  storeReviews: {},
};

const customerReviewRatingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_REVIEW_SETTINGS:
      return {
        ...state,
        reviewSettings: action.data,
      };
    case actionTypes.SAVE_REVIEWS_BY_STORE:
      return {
        ...state,
        storeReviews: action.data,
      };
    default:
      return state;
  }
};

export default customerReviewRatingReducer;
