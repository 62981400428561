import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes/seoTools";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { SEO_TOOLS } from "../../Services/apiUrls";
import {
  setPageLoaderVisibility,
  saveSEODetails,
  toggleHasCustomSEO,
  saveAdvSEODetails,
  setAdvSEODetailsPerType,
} from "../Actions";
import { ToastNotifySuccess, ToastNotifyError } from "../../Components/Toast";
import { isFalsyExceptZero, parseToJson } from "../../Utils/common";
import store from "../Store";
import { formatData } from "@/Utils/advanceSeo";

/**
 * This function will make a post api call to set SEO Details.
 * @param actions
 */
function* setSEODetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + SEO_TOOLS.setSEODetails,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("SEO Details Successfully Updated!");
      actions.callback();
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an post api call to get all the coupons list
 * @param actions
 */
function* getSEODetails(actions: any) {
  const { store_id } = store.getState().storeReducer.store;
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      data: actions.data,
      url: config.base_url + SEO_TOOLS.getSEODetails + "/" + store_id,
    });
    if (response && response?.data?.status) {
      const parsedResponse = parseToJson(response.data.data);
      yield put(saveSEODetails(parsedResponse));
      yield put(toggleHasCustomSEO(true));
      yield put(setPageLoaderVisibility(false));
    } else {
      yield put(saveSEODetails(null));
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make a post api call to set Advance SEO Details.
 * @param actions
 */
function* setAdvSEODetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + SEO_TOOLS.setAdvSEODetails,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("SEO details updated!");
      actions?.callback?.();
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an get api call to get all advanced SEO details
 * @param actions
 */
function* getAdvSEODetails(actions: any) {
  const { type, typeId } = actions.data;
  const { store_id } = store.getState().storeReducer.store;
  try {
    if (store_id && !isFalsyExceptZero(typeId) && type) {
      yield put(setPageLoaderVisibility(true));
      const response = yield apiCall({
        method: "GET",
        data: actions.data,
        url: `${config.base_url}${SEO_TOOLS.getAdvSEODetails}/${store_id}?type=${type}&type_id=${typeId}`,
        parseToJson: true,
      });
      if (response && response?.data?.status) {
        const parsedResponse = response?.data?.data;
        yield put(saveAdvSEODetails(parsedResponse));
        yield put(setPageLoaderVisibility(false));
        actions?.callback?.(true);
      } else {
        yield put(saveAdvSEODetails(null));
        yield put(setPageLoaderVisibility(false));
        ToastNotifyError(response.message);
        actions?.callback?.(false);
      }
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Invalid Input");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* getAdvSEODetailsPerType(actions: any) {
  const { type } = actions.data;
  const { store_id } = store.getState().storeReducer.store;
  try {
    if (store_id && type) {
      yield put(setPageLoaderVisibility(true));
      const response = yield apiCall({
        method: "GET",
        data: actions.data,
        url: `${config.base_url}${SEO_TOOLS.getAdvSEODetailsPerType}/${store_id}?type=${type}`,
        parseToJson: true,
      });
      if (response && response?.data?.status) {
        const parsedResponse = response?.data?.data;
        const formattedData = formatData(parsedResponse);
        const payload = {
          totalCount: parsedResponse?.total_count || 0,
          seoRecommendation: formattedData,
        };
        yield put(setAdvSEODetailsPerType(payload));
        yield put(setPageLoaderVisibility(false));
      } else {
        yield put(setPageLoaderVisibility(false));
        ToastNotifyError(response.message);
      }
    } else {
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.SET_SEO_DETAILS, setSEODetails);
  yield takeEvery(actionTypes.GET_SEO_DETAILS, getSEODetails);
  yield takeEvery(actionTypes.SET_ADV_SEO_DETAILS, setAdvSEODetails);
  yield takeEvery(actionTypes.GET_ADV_SEO_DETAILS, getAdvSEODetails);
  yield takeEvery(actionTypes.GET_ADV_SEO_DETAILS_PER_TYPE, getAdvSEODetailsPerType);
}
