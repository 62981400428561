import { COUPONS_STATUSES } from "@/Constants/couponsVouchersConstants";
import * as actionTypes from "../ActionTypes/couponsActionsType";

const initialState = {
  couponsList: [],
  isNextPage: false,
  subscriptionPurchaseDetailsUsingCoupons:null,
  couponsListV2: [],
  statusCount: [],
  totalPages: 1,
  couponDetails: {},
  allCouponsCount: 0,
  currentSelectedFilter: COUPONS_STATUSES.ACTIVE,
};

const CouponsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_MERCHANT_COUPON_LIST:
      return {
        ...state,
        couponsList: action.data.coupons || [],
        isNextPage: action.data.isNextPage,
      };
    case actionTypes.SAVE_MERCHANT_COUPON_LIST_V2:
      return {
        ...state,
        couponsListV2: action.data.coupons || [],
        statusCount: action.data.statusCount,
        allCouponsCount: action?.data?.statusCount?.[COUPONS_STATUSES.ALL],
        totalPages: action.data.totalPages,
        currentSelectedFilter: action.data.currentSelectedFilter,
      };
    case actionTypes.SAVE_PROMO_CODE_DETAILS_ACTION_V2:
      return {
        ...state,
        couponDetails: action.data,
      };
    case actionTypes.SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON:
      return {
        ...state,
        subscriptionPurchaseDetailsUsingCoupons: action.data || null,
      };
    default:
      return state;
  }
};

export default CouponsReducer;
