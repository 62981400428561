export const GET_CAMPAIGN_LIST = "SMS_MARKETING/GET_CAMPAIGN_LIST";
export const SAVE_CAMPAIGN_LIST = "SMS_MARKETING/SAVE_CAMPAIGN_LIST";
export const EMPTY_CAMPAIGN_LIST = "SMS_MARKETING/EMPTY_CAMPAIGN_LIST";
export const GET_CAMPAIGN_INFO = "SMS_MARKETING/GET_CAMPAIGN_INFO";
export const SAVE_CAMPAIGN_INFO = "SMS_MARKETING/SAVE_CAMPAIGN_INFO";
export const GET_CAMPAIGN_TEMPALTE_LIST = "SMS_MARKETING/GET_CAMPAIGN_TEMPALTE_LIST";
export const SET_CAMPAIGN_TEMPALTE_LIST = "SMS_MARKETING/SET_CAMPAIGN_TEMPALTE_LIST";
export const POST_REQUESTED_CAMPAIGN_TEMPLLATE = "SMS_MARKETING/POST_REQUESTED_CAMPAIGN_TEMPLLATE";
export const SET_CAMPAIGN_TEMPALTE_DATA = "SMS_MARKETING/SET_CAMPAIGN_TEMPALTE_DATA";
export const GET_CAMPAIGN_CUSTOMERS_NAMES_LIST = "SMS_MARKETETING/GET_CAMPAIGN_CUSTOMERS_NAMES_LIST";
export const GET_CAMPAIGN_CUSTOMERS_CONTACT_LIST = "SMS_MARKETETING/GET_CAMPAIGN_CUSTOMERS_CONTACT_LIST";
export const SET_CAMPAIGN_CUSTOMERS_NAMES_LIST = "SMS_MARKETING/SET_CAMPAIGN_CUSTOMERS_NAMES_LIST";
export const GET_CAMPAIGN_BANNER_DETAILS = "SMS_MARKETING/GET_CAMPAIGN_BANNER_DETAILS";
export const SET_CAMPAIGN_BANNER_DETAILS = "SMS_MARKETING/SET_CAMPAIGN_BANNER_DETAILS";
export const SET_CAMPAIGN_LIST_APPLIED_FILTERS = "SMS_MARKETING/SET_CAMPAIGN_LIST_APPLIED_FILTERS";
export const UPDATE_CAMPAIGN_NAME = "SMS_MARKETING/UPDATE_CAMPAIGN_NAME";
export const CREATE_CAMPAIGN = "SMS_MARKETING/CREATE_CAMPAIGN";
export const SAVE_CAMPAIGN = "SMS_MARKETING/SAVE_CAMPAIGN";
export const SAVE_CAMPAIGN_ID = "SMS_MARKETING/SAVE_CAMPAIGN_ID";
export const SEND_TEXT_MESSAGE = "SMS_MARKETING/SEND_TEXT_MESSAGE";
export const INITIATE_CAMPAIGN_PURCHASE = "SMS_MARKETING/INITIATE_CAMPAIGN_PURCHASE";
export const SAVE_ON_CAMPAIGN_SUMMARY_PAGE = "SMS_MARKETING/SAVE_ON_CAMPAIGN_SUMMARY_PAGE";
export const GET_CAMPAIGN_REPORT = "SMS_MARKETING/GET_CAMPAIGN_REPORT";
