import * as bulkUploadType from "../ActionTypes/bulkUploadType";

const initialState = {
  uploadBatchId: { id: "", fileName: "" },
  bulkUploadStatusDetails: "",
  downloadBulkItemsExcelByStoreId: "",
  gstUploadBatchId: { id: "", fileName: "" },
  gstBulkUploadStatusDetails: "",
  gstDownloadBulkItemsExcelByStoreId: "",
};

const bulkUploadReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case bulkUploadType.UPLOAD_BATCH_ID:
      return {
        ...state,
        uploadBatchId: action?.data || "",
      };
    case bulkUploadType.SET_BULK_UPLOAD_DETAILS:
      return {
        ...state,
        bulkUploadStatusDetails: action?.data || "",
      };
    case bulkUploadType.DOWNLOAD_BULK_ITEMS_EXCEL_BY_STORE_ID:
      return {
        ...state,
        downloadBulkItemsExcelByStoreId: action?.data || "",
      };
    case bulkUploadType.UPLOAD_GST_BATCH_ID:
      return {
        ...state,
        gstUploadBatchId: action?.data || "",
      };
    case bulkUploadType.SET_GST_BULK_UPLOAD_DETAILS:
      return {
        ...state,
        gstBulkUploadStatusDetails: action?.data || "",
      };
    default:
      return state;
  }
};

export default bulkUploadReducer;
