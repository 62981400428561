import { FunctionComponent, PropsWithChildren, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import "./CommonStyles/reset.scss";
import CssBaseline from "@material-ui/core/CssBaseline";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "@dotpe/additional-pages/dist/styles/index.scss";
import { createGlobalStyle } from "styled-components";
import { useSelector } from "./Redux/Reducers";
import { authenticateUser, fetchSubscriptionDetails } from "./Redux/Actions";
import LocalStorageHelper from "./Utils/LocalStorageHelper";
import { BaseProps } from "@material-ui/core/OverridableComponent";

const GlobalStyle = createGlobalStyle<{ width?: string }>`
  html, body {
    background-color: #ddd;
  }  
  body #root {
    padding: 0;
    width: 100% !important;
    max-width: ${(props) => props.width} !important;
    margin: 0 auto;
    background-color: #fff;
    height: 100vh;
  }
`;

const getScreenWidth = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let width = urlParams.get("width") || "";
  if (window.location.hostname.includes("localhost") && !width) {
    width = localStorage.getItem("width") || "100vw";
  }
  return width;
};

const getAuthToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("token") || localStorage.getItem("auth_token") || "";
};

export const AppWebView: FunctionComponent<PropsWithChildren<BaseProps<any>>> = (props) => {
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    themeData: state.themeReducer.displayTags,
    storeData: state.storeReducer.data,
    domainDetails: state.domainReducer.domainDetails,
    subscriptionDetails: state.themeReducer.subscriptionDetails,
  }));

  const urlParams = new URLSearchParams(window.location.search);
  const token = getAuthToken();
  const storeid = urlParams.get("storeid") || localStorage.getItem("storeid");
  const app_version = urlParams.get("app_version"); // android -> 4.4.6
  const width = getScreenWidth();
  const app_version_code = urlParams.get("app_version_code"); // android -> 90
  const version_number = urlParams.get("version_number"); // ios -> 1.63
  const channel = urlParams.get("Channel") || urlParams.get("channel");

  const payload = {
    storeid: Number(storeid),
    token,
    storeData,
    app_version,
    app_version_code,
    version_number,
    channel,
  };

  useLayoutEffect(() => {
    const token = getAuthToken();
    if (token) {
      LocalStorageHelper.add("auth_token", token);
      token && dispatch(fetchSubscriptionDetails());
      dispatch(authenticateUser(token));
    }
  }, []);

  return (
    <div>
      <CssBaseline />
      <GlobalStyle width={width} />
      {props.children}
    </div>
  );
};
