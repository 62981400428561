import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, TextField, IconButton } from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "./../../Assets/images/black-close-icon.png";
import { useStyles } from "./style";
import { RootState } from "../../Redux/Store/type";
import { setGST, authenticateUser } from "../../Redux/Actions";
import { withStyles } from "@material-ui/styles";
import { getAuthToken } from "../../Utils/authToken";
import CTAButton from "../Common/CTAButton";
import { ToastNotifyError } from "../Toast";

const CustomDialog = withStyles({
  root: {
    "& .MuiIconButton-colorPrimary:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiIconButton-colorPrimary": {
      color: "transparent",
    },

    "& .MuiDialog-paperWidthSm": {
      maxWidth: 698,
      maxHeight: 460,
      width: "100%",
      height: "100%",
    },
    "& .MuiPaper-rounded": {
      borderRadius: 8,
    },
  },
})(Dialog);

const GSTInputModal = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [gstNumber, setGstNumber] = useState("");

  const { store_id } = useSelector((state: RootState) => state.storeReducer.store);

  /**
   * function to fetch store data again
   * @returns {void}
   *
   */
  function fetchStoreData() {
    dispatch(authenticateUser(getAuthToken(), history));
  }
  
  const onDataChange = (value: any) => {
    setGstNumber(value);
  };

  const onSave = () => {
    if (gstNumber.length === 15) {
      const dataToPost = {
        data: { store_id: store_id, text: gstNumber },
        callback: fetchStoreData,
      };
      dispatch(setGST(dataToPost));
      props.onClose();
    } else {
      ToastNotifyError("GST Number should be of 15 characters!");
    }
  };

  return (
    props.isModalView && 
      <CustomDialog
        open={props.show}
        keepMounted
        maxWidth="sm"
        onClose={() => props.onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          color="primary"
          aria-label="Close Order Details Pop Up"
          component="span"
          style={{ padding: "24px 24px 0px 12px", justifyContent: "end" }}
          onClick={props.onClose}
          className="flex "
        >
          <img src={CloseIcon} alt="close icon" loading="lazy" className="img h2" />
        </IconButton>
        <div className={clsx(classes.gstnumber, "ds-ml36 f3 fw6")}>Add your GST number</div>
        <div className="ds-mt36 ds-ml36">
          <TextField
            className={classes.textFieldRoot}
            style={{ width: "468px", height: "67px" }}
            margin="normal"
            variant="outlined"
            color={"primary"}
            placeholder="Add your 15 character GST Number"
            value={gstNumber}
            onChange={(e: any) => onDataChange(e.target.value.replace(/[^A-Za-z0-9]/g, ""))}
          />
        </div>
        <div className={clsx(classes.btn, "ds-mt150 ds-mr40 ")}>
          <CTAButton
            disabled={!gstNumber}
            customClasses={classes.saveBtn}
            customStyles={{ borderRadius: "38px" }}
            onClick={onSave}
          >
            SAVE
          </CTAButton>
        </div>
      </CustomDialog>
    
  );
};

export default GSTInputModal;
