import * as actionType from "../ActionTypes/staffManagement";

const initialState = {
  allStaffMembersList: [],
  staffMemberData: {},
  staffInvitationData: {},
};

const staffManagementReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionType.SAVE_ALL_STAFF_MEMBERS_ACTION:
      return { ...state, allStaffMembersList: action.data };
    case actionType.SAVE_STAFF_MEMBER_DETAILS:
      return { ...state, staffMemberData: action.data };
    case actionType.SAVE_STAFF_INVITATION_ACTION:
      return { ...state, staffInvitationData: action.data };
    default:
      return state;
  }
};

export default staffManagementReducer;
