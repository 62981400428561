import * as analyticsType from "../ActionTypes/analytics";

const initialState = {
  storeAnalyticsList: [],
};

const AnalyticsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case analyticsType.SET_VISITORS_SUMMARY_BY_FILTERS:
      return {
        ...state,
        storeAnalyticsList: action.data || [],
      };
    default:
      return state;
  }
};

export default AnalyticsReducer;
