import * as actionType from "../ActionTypes";

export function setCustomerReviewSettings(data: any, callback?: any) {
  return {
    type: actionType.SET_CUSTOMER_REVIEW_SETTINGS,
    data,
    callback,
  };
}

export function getCustomerReviewSettings() {
  return {
    type: actionType.GET_CUSTOMER_REVIEW_SETTINGS,
  };
}

export function getCustomerReviewsByStore(data?: any, callback?: any) {
  return {
    type: actionType.GET_CUSTOMER_REVIEWS_BY_STORE,
    data: data || "",
    callback,
  };
}

export function saveCustomerReviewSettings(data?: any, callback?: any) {
  return {
    type: actionType.SAVE_REVIEW_SETTINGS,
    data,
    callback,
  };
}

export function saveReviewsByStore(data?: any, callback?: any) {
  return {
    type: actionType.SAVE_REVIEWS_BY_STORE,
    data,
    callback,
  };
}

export function publishUnpublishReview(data?: any, callback?: any) {
  return {
    type: actionType.PUBLISH_UNPUBLISH_REVIEW,
    data,
    callback,
  };
}
