import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btn: {
    textAlign: "right",
  },
  textFieldRoot: {
    "& > .MuiInputBase-root > input": {
      height: "inherit",
      fontSize: "24px",
      fontWeight: 500,
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "#757575",
        fontSize: "18px",
      },
    },
  },
  saveBtn: {
    padding: "18px 85px",
    borderRadius: "38px",
  },
  gstnumber: {
    marginTop: "-10px",
  },
}));
