import * as actionType from "../ActionTypes/seoTools";

export function setSEODetails(data: any, callback: any) {
  return {
    type: actionType.SET_SEO_DETAILS,
    data,
    callback,
  };
}

export function fetchSEODetails() {
  return {
    type: actionType.GET_SEO_DETAILS,
  };
}

export function saveSEODetails(data: any) {
  return {
    type: actionType.SAVE_SEO_DETAILS,
    data,
  };
}

export function saveStep1Details(data: any) {
  return {
    type: actionType.SAVE_STEP1_DETAILS,
    data,
  };
}

export function saveStep2Details(data: any) {
  return {
    type: actionType.SAVE_STEP2_DETAILS,
    data,
  };
}

export function saveStep3Details(data: any) {
  return {
    type: actionType.SAVE_STEP3_DETAILS,
    data,
  };
}

export function toggleStep1Dropdown() {
  return {
    type: actionType.TOGGLE_STEP1_DROPDOWN,
  };
}

export function toggleStep2Dropdown() {
  return {
    type: actionType.TOGGLE_STEP2_DROPDOWN,
  };
}

export function toggleStep3Dropdown() {
  return {
    type: actionType.TOGGLE_STEP3_DROPDOWN,
  };
}

export function resetSEODetailsState() {
  return {
    type: actionType.RESET_SEO_DETAILS_STATE,
  };
}

export function toggleSEOFormDropdown() {
  return {
    type: actionType.TOOGLE_SEO_FORM_DROPDOWN,
  };
}

export function toggleUnsavedSEODetailsState() {
  return {
    type: actionType.TOGGLE_UNSAVED_SEO_DETAILS_STATE,
  };
}

export function toggleUnsavedSEODetailsModal() {
  return {
    type: actionType.TOGGLE_UNSAVED_DETAILS_MODAL,
  };
}

export function toggleHasCustomSEO(data: any) {
  return {
    type: actionType.TOGGLE_HAS_CUSTOM_SEO,
    data,
  };
}

// Advance Custom SEO actions
export function setAdvSEODetails(data: any, callback?: any) {
  return {
    type: actionType.SET_ADV_SEO_DETAILS,
    data,
    callback,
  };
}

export function fetchAdvSEODetails(data: any, callback?: any) {
  return {
    type: actionType.GET_ADV_SEO_DETAILS,
    data,
    callback,
  };
}

export function saveAdvSEODetails(data: any) {
  return {
    type: actionType.SAVE_ADV_SEO_DETAILS,
    data,
  };
}

/**
 * This is a master API that returns SEO data as per type ( category, collection, additional_page, homepage)
 */
export function getAdvSEODetailsPerType(data: any, callback?: any) {
  return {
    type: actionType.GET_ADV_SEO_DETAILS_PER_TYPE,
    data,
    callback,
  };
}

export function setAdvSEODetailsPerType(data: any, callback?: any) {
  return {
    type: actionType.SET_ADV_SEO_DETAILS_PER_TYPE,
    data,
    callback,
  };
}

export function updateSeoRecommendation(data: any, callback?: any) {
  return {
    type: actionType.UPDATE_SEO_RECOMMENDATION,
    data,
    callback,
  };
}
