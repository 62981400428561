const isEnvProduction = true;

export default {
  base_url: process.env.REACT_APP_BASE_API_URL,
  old_base_url: process.env.REACT_APP_OLD_API_BASE_URL,
  CDN_SERVER_URL: "https://cdn.dotpe.in/kiranaStatic/",
  report_api: process.env.REACT_APP_STORE_REPORT_API,
  gkey: process.env.REACT_APP_GMAP_API_GEOCODE,
  geocodeURL: "https://maps.googleapis.com/maps/api/geocode/json",
  STORE_NAME_FORMAT: process.env.REACT_APP_STORE_NAME_FORMAT,
  cleverTap: {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CleverTap-Account-Id": "449-6R6-695Z",
      "X-CleverTap-Passcode": "970413f92c1145638ba8953b1e1ba917",
    },
    url: "https://api.clevertap.com/1/upload",
  },
  webviewURL: process.env.REACT_APP_WEBVIEW_URL,
  tncURL: "https://www.digitalshowroom.in/terms-and-conditions",
  policyURL: "https://www.digitalshowroom.in/privacy-policy",
  env: isEnvProduction,
  showroomURL: process.env.REACT_APP_THEME_PREVIEW_URL,
  requestToCallbackURl : "https://report-api.dotpe.in/api/merchant/external/enquiry/details",
};

export const CURRENCY_SYMBOL = "₹";
