import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast.css";

const options = {
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
  autoClose: 3000,
  draggable: false,
};

const optionsInfo = {
  type: toast.TYPE.INFO,
  ...options,
};

const optionsSuccess = {
  type: toast.TYPE.SUCCESS,
  ...options,
};

const optionsError = {
  type: toast.TYPE.ERROR,
  ...options,
};

const optionsWarning = {
  type: toast.TYPE.WARNING,
  ...options,
};

const optionsDark = {
  type: toast.TYPE.DARK,
  ...options,
};

toast.configure({});

export const ToastNotifyInfo = (message: string): void => {
  toast(message, optionsInfo);
};
export const ToastNotifySuccess = (message: string): void => {
  toast(message, optionsSuccess);
};
export const ToastNotifyError = (message: string): void => {
  toast(message, optionsError);
};
export const ToastNotifyWarning = (message: string): void => {
  toast(message, optionsWarning);
};
export const ToastNotifyDark = (message: string): void => {
  toast(message, optionsDark);
};
