import clsx from "clsx";
import { Box } from "@material-ui/core";
import { useStyles } from "./style";
import { useCountdownHook } from "../../Utils/countdownTimerHook";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Reducers";

const ThemeExpiryAlertBar: any = (props: any) => {
  const classes = useStyles();
  const { storeData } = useSelector((state: RootState) => ({
    storeData: state.storeReducer.store,
  }));

  const [hour, minute, second] = useCountdownHook(storeData?.theme?.expiry);

  return (
    <Box
      className={clsx(
        classes.alertBar,
        props.fixedBar && classes.fixedBarStyles,
        "flex items-center justify-center w-100",
      )}
    >
      <p className={classes.alertBarText}>
        <strong>Your trial ends in </strong> {`${hour}h:${minute}m:${second}s`}
      </p>
    </Box>
  );
};

export default ThemeExpiryAlertBar;
