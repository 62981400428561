import { ObjectType } from "../../Types";
import * as actionTypes from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
  accountType?: "primary" | "associate" | undefined;
}

/**
 * Function to initiate workspace payment
 * @param data
 * @param callback
 */
export const initiateWorkspacePayment = (data: any, callback?: any): Action => {
  return {
    type: actionTypes.INITIATE_WORKSPACE_PAYMENT,
    data,
    callback,
  };
};

/**
 * Function to fetch workspace data
 * @param data
 * @returns
 */
export const getWorkspaceDetails = (data: any, callback?: any): Action => {
  return {
    type: actionTypes.GET_WORKSPACE_DETAILS,
    data,
    callback,
  };
};

/**
 * Save workspace data fetched from getWorkspaceDetails API
 * @param data
 * @returns
 */
export const saveWorkspaceDetails = (data: any): Action => {
  return {
    type: actionTypes.SAVE_WORKSPACE_DETAILS,
    data,
  };
};

/**
 * Provision user email and name
 * @param data domain, email, first_name, last_name
 * @param callback
 * @returns
 */
export const provisionAndAddUserEmail = (data: any, callback?: any): Action => {
  return {
    type: actionTypes.PROVISION_ADD_USER_EMAIL,
    data,
    callback,
  };
};

/**
 * fetch workspace user based on workspace id and email
 * @param data
 * @param callback
 * @returns
 */
export const getWorkspaceEmailUserById = (data: any, callback?: any): Action => {
  return {
    type: actionTypes.GET_WORKSPACE_EMAIL_USER_BY_ID,
    data,
    callback,
  };
};

export const saveWorkspaceEmailUserById = (data: any): Action => {
  return {
    type: actionTypes.SAVE_WORKSPACE_EMAIL_USER_BY_ID,
    data,
  };
};

export const setInitialNoOfEmailsBought = (data: any): Action => {
  return {
    type: actionTypes.SET_INITIAL_NO_OF_EMAILS_BOUGHT,
    data,
  };
};

export const toggleWorkspaceModal = (data: any): Action => {
  return {
    type: actionTypes.TOGGLE_WORKSPACE_MODAL,
    data,
  };
};

export const setAccountType = (data: any, accountType?: any): Action => {
  return {
    type: actionTypes.SET_ACCOUNT_TYPE,
    data,
    accountType,
  };
};

/**
 * Function to fetch workspace price data
 * @param data
 * @returns
 */
export const getWorkspacePriceDetails = (data: any, callback?: any): Action => {
  return {
    type: actionTypes.GET_WORKSPACE_PRICE_DETAILS,
    data,
    callback,
  };
};

/**
 * Save workspace data fetched from getWorkspacePriceDetails API
 * @param data
 * @returns
 */
export const saveWorkspacePriceDetails = (data: any): Action => {
  return {
    type: actionTypes.SAVE_WORKSPACE_PRICE_DETAILS,
    data,
  };
};

export const saveworkspaceUpsellModal = (data: any): Action => {
  return {
    type: actionTypes.WORKSPACE_UPSELL_MODAL,
    data,
  };
};

export const getWorkspaceRenewalDetails = (data: string, callback?: any): Action => {
  return {
    type: actionTypes.GET_WORKSPACE_RENEWAL_DETAILS,
    data,
    callback,
  };
};

export const saveWorkspaceRenewalDetails = (data: string): Action => {
  return {
    type: actionTypes.SAVE_WORKSPACE_RENEWAL_DETAILS,
    data,
  };
};

export const toggleWorkspaceRenewalModal = (data: boolean, domain?: string): Action | any => {
  return {
    type: actionTypes.TOGGLE_WORKSPACE_RENEWAL_MODAL,
    data,
    domain,
  };
};

export const initiateWorkspaceRenewal = (data: ObjectType, callback?: any): Action => {
  return {
    type: actionTypes.INITIATE_WORKSPACE_RENEWAL,
    data,
    callback,
  };
};

export const fetchTitanWorkspaceManageURL = (data: string, callback: any) => {
  return {
    type: actionTypes.GET_TITAN_WORKSPACE_MANAGE_URL,
    data,
    callback,
  };
};

export const activateTitanWorkspaceAccount = (data: any, callback: any) => {
  return {
    type: actionTypes.ACTIVATE_WORKSPACE_MANAGE_URL,
    data,
    callback,
  };
};
