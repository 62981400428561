import {
  GET_EXIT_GATING_DATA,
  POST_OR_UPDATE_EXIT_GATING_DATA,
  RESET_EXIT_GATING_EDITOR_CONFIG,
  SET_EXIT_GATING_ANALYTICS,
  SET_EXIT_GATING_DATA,
  SET_EXIT_GATING_EDITOR_CONFIG,
} from "../ActionTypes/exitGating";

export const getExitGatingData = () => {
    return {
        type: GET_EXIT_GATING_DATA,
    };
};

export const setExitGatingData = (data: any) => {
    return {
        type: SET_EXIT_GATING_DATA,
        data,
    };
};

export const postOrUpdateExitGatingData = (data: any, callback?: any) => {
    return {
        type: POST_OR_UPDATE_EXIT_GATING_DATA,
        data,
        callback,
    };
};

export const setExitGatingAnalytics = (data: any, callback?: any) => {
    return {
        type: SET_EXIT_GATING_ANALYTICS,
        data,
        callback,
    };
};

export const setExitGatingEditorConfig = (data: any) => {
    return {
        type: SET_EXIT_GATING_EDITOR_CONFIG,
        data,
    };
};

export const resetExitGatingEditorConfig = () => {
    return {
        type: RESET_EXIT_GATING_EDITOR_CONFIG,
    };
};
