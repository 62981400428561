import { SAVE_UTILITIES_DATA } from "../ActionTypes/utilities";

const initialState: any = {
  bannerList: [],
  categoryList: [],
  featureList: [],
};

const utilitiesReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SAVE_UTILITIES_DATA:
      return {
        ...state,
        bannerList: action.data?.banner_list || [],
        categoryList: action.data?.category_list || [],
        featureList: action.data?.feature_list || {},
      };
    default:
      return state;
  }
};

export default utilitiesReducer;
