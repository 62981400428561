import { SAVE_BULK_CATALOG, SAVE_BULK_CATALOG_CATEGORIES, SAVE_BULK_CATALOG_BRAND_NAME, SAVE_BULK_CATALOG_TAGS, SAVE_BULK_CATALOG_COLLECTIONS, SAVE_BULK_CATALOG_FILTERS, SET_ERROR_COUNT, SET_EMPTY_ERROR_COUNT, SAVE_DRAFT_ID, SHOW_ISSUES_SUBHEADER, SHOW_DISCARD_DRAFT_SUBHEADER, SAVE_LAST_DRAFT_DATE, SAVE_ACTIVE_BULK_EDIT_COUNT, SAVE_ROWS_HAVING_ERROR, SAVE_DRAFT_ITEMS, BULK_EDIT_VARIANT_MODAL_ERROR, BULK_EDIT_VARIANT_TOGGLE_STATES, BULK_EDIT_SAVE_TOTAL_ITEMS_CHANGED, BULK_EDIT_SAVE_PANE_SHADOW, BULK_EDIT_IS_COLOR_PICKER_ON, BULK_EDIT_DELETED_ITEMS } from '../ActionTypes/bulkEditTypes';

const initialState = {
  bulkCatalog: [],
  bulkCatalogRows: [],
  bulkCatalogCategories: [],
  bulkCatalogBrandName: [],
  bulkCatalogTags: [],
  bulkCatalogCollections: [],
  bulkCatalogFilters: {},
  showIssuesHeaderSubheader: false,
  showDiscardDraftSubheader: false,
  totalErrors: 0,
  setEmptyErrors: 0,
  draftId: 0,
  saveActiveBulkEditCount: 0,
  lastDraftDate: "",
  draftItems: [],
  rowsWithError: [],
  variantModalError: false,
  bulkEditToggleState: {},
  totalItemsChanged: {},
  paneShadow: {},
  bulkEditColorPickerOn: false,
  bulkEditDeletedItems: [],
};

function bulkEditReducer(state = initialState, action: any) {
  switch (action.type) {
    case SAVE_BULK_CATALOG: {
      return { ...state, bulkCatalog: action.data };
    }
    case SAVE_BULK_CATALOG_CATEGORIES: {
      return { ...state, bulkCatalogCategories: action.data };
    }
    case SAVE_BULK_CATALOG_BRAND_NAME: {
      return { ...state, bulkCatalogBrandName: action.data };
    }
    case SAVE_BULK_CATALOG_TAGS: {
      return { ...state, bulkCatalogTags: action.data };
    }
    case SAVE_BULK_CATALOG_COLLECTIONS: {
      return { ...state, bulkCatalogCollections: action.data };
    }
    case SAVE_BULK_CATALOG_FILTERS: {
      return { ...state, bulkCatalogFilters: action.data };
    }
    case SET_ERROR_COUNT: {
      return { ...state, totalErrors: action.data };
    }
    case SET_EMPTY_ERROR_COUNT: {
      return { ...state, setEmptyErrors: action.data };
    }
    case SHOW_ISSUES_SUBHEADER: {
      return { ...state, showIssuesHeaderSubheader: action.data };
    }
    case SHOW_DISCARD_DRAFT_SUBHEADER: {
      return { ...state, showDiscardDraftSubheader: action.data };
    }
    case SAVE_DRAFT_ID: {
      return { ...state, draftId: action.data };
    }
    case SAVE_LAST_DRAFT_DATE: {
      return { ...state, lastDraftDate: action.data };
    }
    case SAVE_ACTIVE_BULK_EDIT_COUNT: {
      return { ...state, saveActiveBulkEditCount: action.data };
    }
    case SAVE_ROWS_HAVING_ERROR: {
      return { ...state, rowsWithError: action.data };
    }
    case SAVE_DRAFT_ITEMS: {
      return { ...state, draftItems: action.data };
    }
    case BULK_EDIT_VARIANT_MODAL_ERROR: {
      return { ...state, variantModalError: action.data };
    }
    case BULK_EDIT_VARIANT_TOGGLE_STATES: {
      return { ...state, bulkEditToggleState: action.data };
    }
    case BULK_EDIT_SAVE_TOTAL_ITEMS_CHANGED: {
      return { ...state, totalItemsChanged: action.data };
    }
    case BULK_EDIT_SAVE_PANE_SHADOW: {
      return { ...state, paneShadow: action.data };
    }
    case BULK_EDIT_IS_COLOR_PICKER_ON: {
      return { ...state, bulkEditColorPickerOn: action.data };
    }
    case BULK_EDIT_DELETED_ITEMS: {
      return { ...state, bulkEditDeletedItems: action.data };
    }
    default: {
      return { ...state };
    }
  }
}

export default bulkEditReducer;
