import * as actionsType from "../ActionTypes/creditEngineActions";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const getStoreCredits = (): Action => {
  return {
    type: actionsType.GET_STORE_CREDITS,
  };
};

export const saveStoreCredits = (data: any): Action => {
  return {
    type: actionsType.SAVE_STORE_CREDITS,
    data,
  };
};

export const addCredits = (data: any, callback?: any): Action => {
  return {
    type: actionsType.ADD_CREDITS,
    data,
    callback,
  };
};

export const getCreditsTransactions = (data: any, callback?: any): Action => {
  return {
    type: actionsType.GET_CREDITS_TRANSACTIONS,
    data,
    callback,
  };
};

export const saveCreditsTransactions = (data: any): Action => {
  return {
    type: actionsType.SAVE_CREDITS_TRANSACTIONS,
    data,
  };
};

/**
 * This action will be triggered from payment page. When user select/unselect credits
 * @param data
 * @param callback
 */
export const updateStoreCredits = (data: any, callback?: any): Action => {
  return {
    type: actionsType.UPDATE_STORE_CREDITS,
    data,
    callback,
  };
};
