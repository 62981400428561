import * as workspaceTypes from "./../ActionTypes";

const initialState = {
  initialNoOfEmailsBought: 0,
  workspaceModalShowAndComponent: false,
  workspaceDetails: {},
  workspaceUserById: {},
  accountTypes: {
    primaryAccount: {},
    associateAccounts: [],
  },
  workspacePriceDetails: [],
  workspaceUpsellModal: false,
  numOfNewAccCreated: 0,
  renewalDetails: [],
  workspaceRenewalModalShow: false,
  workspaceRenewalDomain: "",
};

const workspaceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case workspaceTypes.SET_INITIAL_NO_OF_EMAILS_BOUGHT: {
      return {
        ...state,
        initialNoOfEmailsBought: action.data,
      };
    }
    case workspaceTypes.SAVE_WORKSPACE_DETAILS: {
      return {
        ...state,
        workspaceDetails: action.data,
      };
    }
    case workspaceTypes.SAVE_WORKSPACE_EMAIL_USER_BY_ID: {
      return {
        ...state,
        workspaceUserById: action.data,
      };
    }
    case workspaceTypes.TOGGLE_WORKSPACE_MODAL: {
      return {
        ...state,
        workspaceModalShowAndComponent: action.data,
      };
    }

    case workspaceTypes.SET_ACCOUNT_TYPE: {
      return {
        ...state,
        accountTypes: {
          ...state.accountTypes,
          primaryAccount: action.accountType === "primary" ? action.data : state.accountTypes.primaryAccount,
          associateAccounts: action.accountType === "associate" ? action.data : state.accountTypes.associateAccounts,
        },
      };
    }
    case workspaceTypes.SAVE_WORKSPACE_PRICE_DETAILS: {
      return {
        ...state,
        workspacePriceDetails: action.data,
      };
    }
    case workspaceTypes.WORKSPACE_UPSELL_MODAL: {
      return {
        ...state,
        workspaceUpsellModal: action.data,
      };
    }
    case workspaceTypes.NUMBER_OF_NEW_ACCOUNTS_CREATED: {
      return {
        ...state,
        numOfNewAccCreated: action.data,
      };
    }
    case workspaceTypes.SAVE_WORKSPACE_RENEWAL_DETAILS: {
      return {
        ...state,
        renewalDetails: action.data,
      };
    }
    case workspaceTypes.TOGGLE_WORKSPACE_RENEWAL_MODAL: {
      return {
        ...state,
        workspaceRenewalModalShow: action.data,
        workspaceRenewalDomain: action.domain,
      };
    }
    default:
      return state;
  }
};

export default workspaceReducer;
