import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { openedInMobile } from "../../Utils/common";
import { authenticateUser } from "../../Redux/Actions";

const urlParams = new URLSearchParams(window.location.search);

export function useMobileSpecificMethods() {
  const dispatch = useDispatch();

  const { storeData } = useSelector((state: RootStateOrAny) => ({
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    const token = (urlParams.get("token") as any) ?? null;
    if (openedInMobile() && !Object.keys(storeData).length) {
      dispatch(authenticateUser(token, history, false, openedInMobile()));
      return;
    }
  }, [storeData]);

  return null;
}
