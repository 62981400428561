/* eslint-disable no-useless-escape */
import LocalStorageHelper from "./LocalStorageHelper";
import {
  LS_AUTH_TOKEN,
  LS_STATE,
  SETTLEMENT_DATE_RANGE,
  ORDER_DATE_RANGE,
  FBE_USER_AUTH_DATA,
  SHOW_SERVICE_SELL_INTRO,
} from "../Constants/localStorageKeys";
import store from "../Redux/Store";
import history from "../Utils/history";
import {
  SUBSCRIPTION_DETAILS,
  WORKSPACE_POSTPURCHASE_ROUTE,
  CREDIT_ENGINE_ROUTE,
  SMS_CAMPAIGN_PAYMENT_SUCCESS_ROUTE,
  WHATSAPP_BUSINESS_DEMO,
  ORDERS_PAGE_ROUTE,
  DOMAIN_PURCHASE_SUCCESS,
  DOMAIN_LANDING_PAGE,
} from "../Constants/routesConstant";
import { clevertapEventPush, getLoginUrl } from "./common";
import { updataWhatsappMarketingRequestStatus } from "../Redux/Actions";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../Constants";

export const returnCommaSeparatedList = (arr: any) =>
  arr?.map((str: any, idx: number) => (idx !== arr.length - 1 ? str.name + ", " : str.name));

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: any;
  return (...args: Params) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      func(...args);
    }, timeout);
  };
}

/** Convert CDN url image to the base64 string images */
export const toDataUrl = (url: string, callback: any) => {
  const xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      // if complete
      if (xhr.status === 200) {
        // check if "OK" (200)
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
      } else {
        callback(url);
      }
    }
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

export const getBase64Image = (imgUrl: any, callback: any) => {
  const img = new Image();
  img.onload = function () {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: any = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL("image/png");
    dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    callback(dataURL); // the base64 string
  };
  // set attributes and src
  img.setAttribute("crossOrigin", "anonymous"); //
  img.src = imgUrl;
};

/** Take only Number Input Values */
export const numberFromString = (type: string, value: string) => {
  if (type === "price") {
    return value.trim().replace(/[^0-9.]/g, "");
  } else {
    return value.trim().replace(/[^0-9]/g, "");
  }
};

export const seperateEmojiFromString = (text: string) => {
  const regexExp =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  if (regexExp.test(text)) {
    const str = text.split(" ");
    const icon = str.shift();
    return [icon, str.join(" ")];
  } else {
    return [];
  }
};

export const logoutUser = () => {
  setTimeout(() => {
    LocalStorageHelper.delete(LS_AUTH_TOKEN);
    LocalStorageHelper.delete(LS_STATE);
    LocalStorageHelper.delete(SETTLEMENT_DATE_RANGE);
    LocalStorageHelper.delete(ORDER_DATE_RANGE);
    LocalStorageHelper.delete(FBE_USER_AUTH_DATA);
    LocalStorageHelper.delete(SHOW_SERVICE_SELL_INTRO);
    history.push(`${getLoginUrl()}`);
  }, 1000);
};

/**
 * This function set the premiumPurchaseType to session storage. Whenever the payment is initiated then it will set and when it's completed then
 * it will be redirected to the source page
 * @param sourceType
 */
export const setPremiumPurchaseType = (sourceType: string) => {
  sessionStorage.setItem("premiumPurchaseType", sourceType);
};

export const getPremiumPurchaseType = () => {
  return sessionStorage.getItem("premiumPurchaseType");
};

export const setPrePaymentDataToSessionStorage = (data: any) => {
  sessionStorage.setItem("prePaymentData", data);
};

export const appendCreditUsedKey = (currentData: any) => {
  return { ...currentData, credit_used: store?.getState()?.creditEngine?.userCredits || 0 };
};

/**
 * This function will help to redirect from payment page to the page from which payment has been initiated
 * @param redirectType
 */
export const redirectFromPaymentPage = (redirectType: any) => {
  const prePaymentData = store.getState()?.paymentMethodReducer?.prePaymentData;
  switch (redirectType) {
    case 1:
      history.replace(`/themes-loader`, { from: "/payment" });
      break;
    case 2:
      {
        const isDomainMarketing = getPremiumPurchaseType() === PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_MARKETING;

        const isDomainCartSubscription =
          getPremiumPurchaseType() === PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_SUBSCRIPTION;
        if (isDomainCartSubscription) {
          history.replace(`${DOMAIN_LANDING_PAGE}${DOMAIN_PURCHASE_SUCCESS}`, { from: "/payment" });
        } else {
          history.replace(!isDomainMarketing ? `/domain-success` : "/domain-completion", { from: "/payment" });
        }
      }
      break;
    case 3:
      history.replace(`/domain-connect-success`, { from: "/payment" });
      break;
    case 4:
      history.replace(`/marketing/ad-details/${prePaymentData?.theme_id}`, { from: "/payment" });
      break;
    case 5:
      history.replace(SUBSCRIPTION_DETAILS, { from: "/payment" });
      break;
    case 8:
      history.replace(WORKSPACE_POSTPURCHASE_ROUTE, { from: "/payment", firstTimePaymentSuccessful: true });
      break;
    case 9:
      history.replace(CREDIT_ENGINE_ROUTE, { from: "/payment", firstTimePaymentSuccessful: true });
      break;
    case 11:
      history.replace(SMS_CAMPAIGN_PAYMENT_SUCCESS_ROUTE, { from: "/payment" });
      break;
    case 14: {
      clevertapEventPush("WABA Payment Done");
      store.dispatch(updataWhatsappMarketingRequestStatus({ isWabaSubscriptionPurchased: true }));
      history.replace(WHATSAPP_BUSINESS_DEMO, { from: "/payment" });
      break;
    }
    case 15:
      history.replace("/brand-website-payment-success", { from: "/payment" });
      break;
    default:
      history.replace(ORDERS_PAGE_ROUTE, { from: "/payment" });
      break;
  }
};

/* basic throttle utility function to limit api calls on element clicks */
export function throttle(fn: any, delay = 300) {
  let run = false;
  return function (...args: any) {
    if (!run) {
      fn(...args);
      run = true;
      setTimeout(() => (run = false), delay);
    }
  };
}

export function validateEmail(email: string) {
  return /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(email);
}

export function loadScriptByURL(id: string, url: string) {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }
}

export const sleep = (delay = 500) => new Promise((resolve) => setTimeout(() => resolve(true), delay));
