import React, { FC, HTMLAttributes } from "react";
import SpinnerIcon from "./../../Assets/images/spinner-icon.gif";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Reducers";

interface PageLoaderProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean;
  customStyles?: React.CSSProperties;
}

const PageLoader: FC<PageLoaderProps> = (props): JSX.Element | null => {
  const { customStyles = {} } = props;
  const { pageLoaderVisibility } = useSelector((state: RootState) => state.commonReducer);
  if (!props.show) {
    return null;
  }

  return pageLoaderVisibility ? 
    <div
      className="aspect-ratio--object flex justify-center items-center-ns bg-black-60"
      style={{ zIndex: 10000, ...customStyles }}
    >
      <span className="flex items-center">
        <img className="img h3" src={SpinnerIcon} />
      </span>
    </div>
   : null;
};

export default PageLoader;
