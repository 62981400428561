import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Reducers";
import { fetchGSTBillingDetails, getBulkUploadDetails } from "../../Redux/Actions";
import { GST_BILLING_CONSTANT } from "../../Constants";

let timer: any = "";
let gstTimer: any = "";

export function useUploadTimers() {
  const dispatch = useDispatch();

  const { uploadBatchId, gstUploadBatchId, bulkUploadStatusDetails, gstBulkUploadStatusDetails } = useSelector(
    (state: RootState) => ({
      uploadBatchId: state.bulkUploadReducer.uploadBatchId,
      gstUploadBatchId: state.bulkUploadReducer.gstUploadBatchId,
      bulkUploadStatusDetails: state.bulkUploadReducer.bulkUploadStatusDetails,
      gstBulkUploadStatusDetails: state.bulkUploadReducer.gstBulkUploadStatusDetails,
      staffInvitationData: state.staffManagementReducer.staffInvitationData,
      storeData: state.storeReducer.store,
    }),
  );

  useEffect(() => {
    if (gstUploadBatchId.id) {
      clearInterval(gstTimer);
      setGstCountdown();
    }
  }, [gstUploadBatchId?.id]);

  useEffect(() => {
    if (gstUploadBatchId?.id && gstBulkUploadStatusDetails && gstBulkUploadStatusDetails.status === 2) {
      clearInterval(gstTimer);
    }
  }, [gstBulkUploadStatusDetails]);

  useEffect(() => {
    if (uploadBatchId?.id && bulkUploadStatusDetails && bulkUploadStatusDetails.status === 2) {
      clearInterval(timer);
    }
  }, [bulkUploadStatusDetails]);

  useEffect(() => {
    if (uploadBatchId.id) {
      clearInterval(timer);
      setCountdown();
    }
  }, [uploadBatchId?.id]);

  const setCountdown = () => {
    timer = setInterval(() => {
      const dataToPost = {
        id: uploadBatchId.id,
        filename: uploadBatchId.filename,
        callback: (flag: boolean) => {
          if (flag && timer) {
            clearInterval(timer);
          }
        },
      };
      dispatch(getBulkUploadDetails(dataToPost));
    }, 3000);
  };

  function setGstCountdown() {
    gstTimer = setInterval(() => {
      const dataToPost = {
        id: gstUploadBatchId.id,
        filename: gstUploadBatchId.filename,
        type: GST_BILLING_CONSTANT,
        callback: (flag: boolean) => {
          if (flag && gstTimer) {
            clearInterval(gstTimer);
            dispatch(fetchGSTBillingDetails());
          }
        },
      };
      dispatch(getBulkUploadDetails(dataToPost));
    }, 3000);
  }

  return null;
}
