export const SAVE_BULK_CATALOG = "BULK_EDIT/SAVE_BULK_CATALOG";
export const GET_BULK_CATALOG = "BULK_EDIT/GET_BULK_CATALOG";
export const SAVE_BULK_CATALOG_CATEGORIES = "BULK_EDIT/SAVE_BULK_CATALOG_CATEGORIES";
export const SAVE_BULK_CATALOG_BRAND_NAME = "BULK_EDIT/SAVE_BULK_CATALOG_BRAND_NAME";
export const SAVE_BULK_CATALOG_TAGS = "BULK_EDIT/SAVE_BULK_CATALOG_TAGS";
export const SAVE_BULK_CATALOG_COLLECTIONS = "BULK_EDIT/SAVE_BULK_CATALOG_COLLECTIONS";
export const SAVE_BULK_CATALOG_FILTERS = "BULK_EDIT/SAVE_BULK_CATALOG_FILTERS";
export const POST_BULK_CATALOG_ITEMS = "BULK_EDIT/POST_BULK_CATALOG_ITEMS";
export const SET_ERROR_COUNT = "BULK_EDIT/SET_ERROR_COUNT";
export const SET_EMPTY_ERROR_COUNT = "BULK_EDIT/SET_EMPTY_ERROR_COUNT";
export const EXPORT_CATALOG = "BULK_EDIT/EXPORT_CATALOG";
export const SAVE_DRAFT_ID = "BULK_EDIT/SAVE_DRAFT_ID";
export const SHOW_ISSUES_SUBHEADER = "BULK_EDIT/SHOW_ISSUES_SUBHEADER";
export const SHOW_DISCARD_DRAFT_SUBHEADER = "BULK_EDIT/SHOW_DISCARD_DRAFT_SUBHEADER";
export const DISCARD_DRAFT = "BULK_EDIT/DISCARD_DRAFT";
export const SAVE_LAST_DRAFT_DATE = "BULK_EDIT/SAVE_LAST_DRAFT_DATE";
export const GET_ACTIVE_BULK_EDIT_COUNT = "BULK_EDIT/GET_ACTIVE_BULK_EDIT_COUNT";
export const SAVE_ACTIVE_BULK_EDIT_COUNT = "BULK_EDIT/SAVE_ACTIVE_BULK_EDIT_COUNT";
export const SAVE_ROWS_HAVING_ERROR = "BULK_EDIT/SAVE_ROWS_HAVING_ERROR";
export const SAVE_DRAFT_ITEMS = "BULK_EDIT/SAVE_DRAFT_ITEMS";
export const BULK_EDIT_VARIANT_MODAL_ERROR = "BULK_EDIT/BULK_EDIT_VARIANT_MODAL";
export const BULK_EDIT_VARIANT_TOGGLE_STATES = "BULK_EDIT/BULK_EDIT_VARIANT_TOGGLE_STATES";
export const CLEAR_SAVE_APPLY_CHANGES_CLICKED = "BULK_EDIT/CLEAR_SAVE_APPLY_CHANGES_CLICKED";
export const BULK_EDIT_SAVE_TOTAL_ITEMS_CHANGED = "BULK_EDIT/BULK_EDIT_SAVE_TOTAL_ITEMS_CHANGED";
export const BULK_EDIT_SAVE_PANE_SHADOW = "BULK_EDIT/BULK_EDIT_SAVE_PANE_SHADOW";
export const BULK_EDIT_IS_COLOR_PICKER_ON = "BULK_EDIT/BULK_EDIT_IS_COLOR_PICKER_ON";
export const BULK_EDIT_DELETED_ITEMS = "BULK_EDIT/BULK_EDIT_DELETED_ITEMS";

