export const ACTIVE_VARIANT_INDEX = "activeVariantIndex";
export const SETTLEMENT_DATE_RANGE = "settlementDateRange";
export const ORDER_DATE_RANGE = "orderDateRange";
export const SHOW_LEAD_GEN_POPUP = "showLeadgenpopup";
export const FBE_USER_AUTH_DATA = "fbeUserData";
export const FBE_USER_ACCOUNT_DATA = "fbeAccountData";
export const SHOW_SERVICE_SELL_INTRO = "showServiceSellIntro";
export const LS_AUTH_TOKEN = "auth_token";
export const LS_STATE = "state";
export const ENCRYPTED_CARD_DETAILS = "tpcc";
export const ECOMM_RENEW_LATER = "ecommRenewLater";
