import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAppConstants } from "../../Redux/Actions";
import { getAuthToken } from "../../Utils/authToken";

export function useOpenRouteMethods() {
  const dispatch = useDispatch();

  useEffect(() => {
    // This API call is done for DLP page flow to get constants for open route
    if (process.env.REACT_APP_DS_OFFICIAL_WEBSITE?.includes(window.location.origin)) {
      dispatch(getAppConstants(true));
    } else {
      // This API call is done for authenticated merchant flow on web console
      const authToken = getAuthToken();
      if (authToken) {
        dispatch(getAppConstants(false));
      }
    }
  }, []);

  return null;
}
