import { GET_HELP_SECTION, SAVE_HELP_SECTION } from "../ActionTypes/helpSection";

export const getHelpSection = () => {
  return {
    type: GET_HELP_SECTION,
  };
};

export const saveHelpSection = (data: any) => {
  return {
    type: SAVE_HELP_SECTION,
    data,
  };
};

