import React, { FC } from "react";
import { useStyles } from "./style";
import clsx from "clsx";

interface ICTAButton {
  text?: string;
  customClasses?: string;
  onClick?: any;
  variant?: "solid" | "outline";
  children?: any;
  height?: any;
  customStyles?: React.CSSProperties;
  disabled?: boolean;
}

const CTAButton: FC<ICTAButton> = ({
  text = "",
  customClasses = "",
  onClick = null,
  variant = "solid",
  children,
  height,
  customStyles = {},
  disabled = false,
}: ICTAButton) => {
  const classes = useStyles();

  const styles: React.CSSProperties = {
    backgroundColor: variant === "solid" ? "#000000" : "transparent",
    color: variant === "solid" ? "#ffffff" : "#000000",
    height: height ? height : "auto",
    opacity: 1,
    cursor: disabled ? "not-allowed" : "pointer",
  };

  const combinedStyles: React.CSSProperties = {
    ...styles,
    ...(disabled ? { backgroundColor: "#D2D2D2" } : {}),
    ...customStyles,
  };

  return (
    <button
      disabled={disabled}
      style={combinedStyles}
      className={clsx(classes.CTAButton, customClasses, {
        // (classes.disabled): disabled == true
      })}
      onClick={onClick}
    >
      {children || text}
    </button>
  );
};

export default CTAButton;
