import { OTPLESS_LOGIN_SUCCESS } from "../ActionTypes";
import * as userTypes from "../ActionTypes/userActionType";

const initialState: any = {
  data: [],
  userSubscriptionDetails: {},
  storePageinfo: {},
  deliverySetupInfo: {},
  otplessUserInfo: null,
};

const userReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case userTypes.SAVE_SUBSCRIPTION_DETAILS:
      return { ...state, userSubscriptionDetails: action.data };
    case userTypes.SAVE_USER_GMAIL_PAGE_INFO:
      return { ...state, storePageinfo: action.data };
    case userTypes.SAVE_DELIVERY_SETUP_INFO:
      return { ...state, deliverySetupInfo: action.data };
    case OTPLESS_LOGIN_SUCCESS:
      // Only available at first time login
      return { ...state, otplessUserLoginInfo: action.data };
    default:
      return state;
  }
};

export default userReducer;
