import { AnyIfEmpty } from "react-redux";
import * as actions from "./../ActionTypes/smsMarketing";
interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const saveCampaignList = (data: any): Action => {
  return { type: actions.SAVE_CAMPAIGN_LIST, data };
};

export const getCampaignList = (data: any, callback?: any): Action => {
  return { type: actions.GET_CAMPAIGN_LIST, data, callback };
};

export const getCampaignReport = (data: any, callback: any): Action => {
  return {
    type: actions.GET_CAMPAIGN_REPORT,
    data,
    callback,
  };
};

export const emptyCampaignList = (): Action => {
  return { type: actions.EMPTY_CAMPAIGN_LIST };
};

export const saveCampaignInfo = (data: any): Action => {
  return { type: actions.SAVE_CAMPAIGN_INFO, data };
};

export const getCampaignInfo = (data: any, callback?: any): Action => {
  return { type: actions.GET_CAMPAIGN_INFO, data, callback };
};

export const saveCampaignTemplateList = (data: any): Action => {
  return { type: actions.SET_CAMPAIGN_TEMPALTE_LIST, data };
};

export const getCampaignTemplateList = (): Action => {
  return { type: actions.GET_CAMPAIGN_TEMPALTE_LIST };
};

export const postRequestedCampaignTemplate = (data: any, callback?: any): Action => {
  return { type: actions.POST_REQUESTED_CAMPAIGN_TEMPLLATE, data, callback };
};

export const setCampaignTemplateData = (data: any): Action => {
  return { type: actions.SET_CAMPAIGN_TEMPALTE_DATA, data };
};

export const getCampaignCustomersNamesList = (): Action => {
  return { type: actions.GET_CAMPAIGN_CUSTOMERS_NAMES_LIST };
};

export const setCampaignCustomersNamesList = (data: any): Action => {
  return { type: actions.SET_CAMPAIGN_CUSTOMERS_NAMES_LIST, data };
};

export const getCampaignCustomersContactList = (data: any, callback?: any): Action => {
  return { type: actions.GET_CAMPAIGN_CUSTOMERS_CONTACT_LIST, data, callback };
};

export const setCampaignBannerDetails = (data: any): Action => {
  return { type: actions.SET_CAMPAIGN_BANNER_DETAILS, data };
};

export const getCampaignBannerDetails = (): Action => {
  return { type: actions.GET_CAMPAIGN_BANNER_DETAILS };
};

export const setCampaignListAppliedFilters = (data: any): Action => {
  return { type: actions.SET_CAMPAIGN_LIST_APPLIED_FILTERS, data };
};

export const updateCampaignName = (data: any): Action => {
  return { type: actions.UPDATE_CAMPAIGN_NAME, data };
};

export const createCampaign = (data: any, callback: any): Action => {
  return { type: actions.CREATE_CAMPAIGN, data, callback };
};

export const saveCampaign = (data: any, callback?: any): Action => {
  return { type: actions.SAVE_CAMPAIGN, data, callback };
};

export const saveCampaignId = (data: any): Action => {
  return { type: actions.SAVE_CAMPAIGN_ID, data };
};

export const sendTestMessage = (data: any): Action => {
  return { type: actions.SEND_TEXT_MESSAGE, data };
};

export const initCampaignPurchase = (data: any, callback: any): Action => {
  return { type: actions.INITIATE_CAMPAIGN_PURCHASE, data, callback };
};

export const saveOnCampaignSummaryPage = (data: any): Action => {
  return { type: actions.SAVE_ON_CAMPAIGN_SUMMARY_PAGE, data };
};
