import { makeStyles } from "@material-ui/core";
import React, { ComponentType, FunctionComponent } from "react";
import { AppBar, AppBarProps } from "./AppBar";

const useStyles = makeStyles({
  appBarContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  appContent: {
    flex: 1,
    backgroundColor: "white",
    // overflow: "auto",
  },
});

export function withAppBar<Props extends Record<string, any> = Record<string, any>>(
  Component: ComponentType<Props>,
  appBarProps?: AppBarProps,
): FunctionComponent<AppBarProps & Props> {
  const HoC = (props: Props) => {
    const classes = useStyles();
    return (
      <div className={classes.appBarContainer}>
        <AppBar {...(appBarProps || ({} as AppBarProps))} />
        <div className={classes.appContent}>
          <Component {...props} />
        </div>
      </div>
    );
  };

  return HoC;
}
