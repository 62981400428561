export const GET_ALL_STAFF_MEMBERS_ACTION = "STAFF/GET_ALL_STAFF_MEMBERS_ACTION";
export const SAVE_ALL_STAFF_MEMBERS_ACTION = "STAFF/SAVE_ALL_STAFF_MEMBERS_ACTION";
export const ADD_STAFF_MEMBER_ACTION = "STAFF/ADD_STAFF_MEMBER_ACTION";
export const UPDATE_STAFF_MEMBER_PERMISSION_ACTION = "STAFF/UPDATE_STAFF_MEMBER_PERMISSION_ACTION";
export const REMOVE_STAFF_MEMBER_ACTION = "STAFF/REMOVE_STAFF_MEMBER_ACTION";
export const GET_ALL_PERMISSION_LIST = "STAFF/GET_ALL_PERMISSION_LIST";
export const GET_STAFF_MEMBER_DETAILS = "STAFF/GET_STAFF_MEMBER_DETAILS";
export const SAVE_STAFF_MEMBER_DETAILS = "STAFF/SAVE_STAFF_MEMBER_DETAILS";
export const CHECK_STAFF_MEMBER_EXISTENCE_ACTION = "STAFF/CHECK_STAFF_MEMBER_EXISTENCE_ACTION";
export const SEND_STAFF_INVITATION_ACTION = "STAFF/SEND_STAFF_INVITATION_ACTION";
export const UPDATE_USER_INVITATION_ACTION = "STAFF/UPDATE_USER_INVITATION_ACTION";
export const SAVE_STAFF_INVITATION_ACTION = "STAFF/SAVE_STAFF_INVITATION_ACTION";
export const CHECK_STAFF_INVITE_EXISTENCE = "STAFF/CHECK_STAFF_INVITE_EXISTENCE";
export const GET_REQUEST_PERMISSION_ACTION = "STAFF/GET_REQUEST_PERMISSION_ACTION";
