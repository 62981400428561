import { BULK_EDIT_DELETED_ITEMS, BULK_EDIT_IS_COLOR_PICKER_ON, EXPORT_CATALOG, BULK_EDIT_SAVE_PANE_SHADOW, BULK_EDIT_SAVE_TOTAL_ITEMS_CHANGED, BULK_EDIT_VARIANT_MODAL_ERROR, BULK_EDIT_VARIANT_TOGGLE_STATES, CLEAR_SAVE_APPLY_CHANGES_CLICKED, DISCARD_DRAFT, GET_ACTIVE_BULK_EDIT_COUNT, GET_BULK_CATALOG, POST_BULK_CATALOG_ITEMS, SAVE_ACTIVE_BULK_EDIT_COUNT, SAVE_BULK_CATALOG, SAVE_BULK_CATALOG_BRAND_NAME, SAVE_BULK_CATALOG_CATEGORIES, SAVE_BULK_CATALOG_COLLECTIONS, SAVE_BULK_CATALOG_FILTERS, SAVE_BULK_CATALOG_TAGS, SAVE_DRAFT_ID, SAVE_DRAFT_ITEMS, SAVE_LAST_DRAFT_DATE, SAVE_ROWS_HAVING_ERROR, SET_EMPTY_ERROR_COUNT, SET_ERROR_COUNT, SHOW_DISCARD_DRAFT_SUBHEADER, SHOW_ISSUES_SUBHEADER } from "../ActionTypes/bulkEditTypes";
import { selectedOptionObject } from '../../Containers/BulkEdit/types/bulkEditTypes';
import { BULK_EDIT_SAVE_TYPE } from '../../Containers/BulkEdit/Utils/bulkEditConstants';
import { MyStyles } from '../../Containers/BulkEdit/Containers/BulkEdit/BulkEdit';

export const fetchBulkCatalog = () => {
  return {
    type: GET_BULK_CATALOG,
  };
};

export const saveBulkCatalog = (data: any) => {
  return {
    type: SAVE_BULK_CATALOG,
    data,
  };
};

export const saveBulkCatalogCategories = (data: string[]) => {
  return {
    type: SAVE_BULK_CATALOG_CATEGORIES,
    data,
  };
};

export const saveBulkCatalogBrandName = (data: string[]) => {
  return {
    type: SAVE_BULK_CATALOG_BRAND_NAME,
    data,
  };
};

export const saveBulkCatalogTags = (data: selectedOptionObject[]) => {
  return {
    type: SAVE_BULK_CATALOG_TAGS,
    data,
  };
};

export const saveBulkCatalogCollections = (data: selectedOptionObject[]) => {
  return {
    type: SAVE_BULK_CATALOG_COLLECTIONS,
    data,
  };
};

export const saveBulkCatalogFilters = (data: selectedOptionObject[]) => {
  return {
    type: SAVE_BULK_CATALOG_FILTERS,
    data,
  };
};

export const postBulkCatalogFilters = (data: any, saveType: typeof BULK_EDIT_SAVE_TYPE[keyof typeof BULK_EDIT_SAVE_TYPE]) => {
  return {
    type: POST_BULK_CATALOG_ITEMS,
    data,
    saveType,
  };
};

export const setErrorCount = (data: number) => {
  return {
    type: SET_ERROR_COUNT,
    data,
  };
};

export const setEmptyErrorCount = (data: number) => {
  return {
    type: SET_EMPTY_ERROR_COUNT,
    data,
  };
};

export const showIssuesSubheader = (data: boolean) => {
  return {
    type: SHOW_ISSUES_SUBHEADER,
    data,
  };
};

export const shouldDiscardDraftSubheader = (data: boolean) => {
  return {
    type: SHOW_DISCARD_DRAFT_SUBHEADER,
    data,
  };
};

export const clearSaveApplyChangesClicked = () => {
  return {
    type: CLEAR_SAVE_APPLY_CHANGES_CLICKED,
  };
};

export const saveDraftId = (data: number) => {
  return {
    type: SAVE_DRAFT_ID,
    data,
  };
};

export const discardDraft = () => {
  return {
    type: DISCARD_DRAFT,
  };
};

export const lastDraftDate = (data: string) => {
  return {
    type: SAVE_LAST_DRAFT_DATE,
    data,
  };
};

export const getActiveBulkEditCount = (callback?: any) => {
  return {
    type: GET_ACTIVE_BULK_EDIT_COUNT,
    callback,
  };
};

export const saveActiveBulkEditCount = (data: number) => {
  return {
    type: SAVE_ACTIVE_BULK_EDIT_COUNT,
    data,
  };
};

export const saveRowsHavingError = (data: number[]) => {
  return {
    type: SAVE_ROWS_HAVING_ERROR,
    data,
  };
};

export const saveDraftItems = (data: any) => {
  return {
    type: SAVE_DRAFT_ITEMS,
    data,
  };
};

export const toggleVariantModalError = (data: boolean) => {
  return {
    type: BULK_EDIT_VARIANT_MODAL_ERROR,
    data,
  };
};

export const bulkEditVariantToggleStates = (data: any) => {
  return {
    type: BULK_EDIT_VARIANT_TOGGLE_STATES,
    data,
  };
};

export const bulkEditSaveTotalItemsChanged = (data: Record<string, boolean>) => {
  return {
    type: BULK_EDIT_SAVE_TOTAL_ITEMS_CHANGED,
    data,
  };
};

export const bulkEditSavePaneShadow = (data: MyStyles) => {
  return {
    type: BULK_EDIT_SAVE_PANE_SHADOW,
    data,
  };
};

export const bulkEditIsColorPickerOn = (data: boolean) => {
  return {
    type: BULK_EDIT_IS_COLOR_PICKER_ON,
    data,
  };
};

export const bulkEditDeletedItems = (data: any) => {
  return {
    type: BULK_EDIT_DELETED_ITEMS,
    data,
  };
};

export const exportCatalog = (data: any, callback?: any) => {
  return {
    type: EXPORT_CATALOG,
    data,
    callback,
  };
};
