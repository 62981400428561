import * as React from "react";
import { Dialog as MuiDialog, DialogContent as MuiDialogContent } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../Redux/Reducers";
import { ecommEliteCallbackModalAction } from "../../../Redux/Actions/common";
import { requestCallback } from "../../../Redux/Actions";
import { SUBSCRIPTION_TYPE, USER_PLAN_TYPES } from "../../../Constants";
import { ECOMM_ELITE } from "../../../Constants/subscription";
import CommonCallbackModal from "@/Components/CommonCallbackModal";

const playTypeString = (planType: string) => {
  switch (planType) {
    case SUBSCRIPTION_TYPE.THEME:
      return "Theme Plan";
    case SUBSCRIPTION_TYPE.THEME_PRO:
      return "Theme Plus Plan";
    case ECOMM_ELITE:
      return "eCommerce Elite Plan";
    case USER_PLAN_TYPES.GST_PACK:
      return "GST & Payments Plan";
    default:
      return "Advanced Plan";
  }
};

const returnPackNames = (planType: string): string | null => {
  switch (planType) {
    case ECOMM_ELITE:
      return "ecomm_elite_pack";
    case USER_PLAN_TYPES.GST_PACK:
      return "gst_pack";
    case SUBSCRIPTION_TYPE.THEME_PRO:
      return "theme_plus";
    case SUBSCRIPTION_TYPE.THEME:
      return "theme_pack";
    default:
      return null;
  }
};

const EcommEliteCallbackModal = () => {
  const dispatch = useDispatch();
  const [planString, setPlanString] = React.useState("");
  const { ecommEliteCallbackModal } = useSelector((state: RootState) => ({
    ecommEliteCallbackModal: state.commonReducer.ecommEliteCallbackModal,
  }));

  React.useEffect(() => {
    if (ecommEliteCallbackModal?.view) {
      setPlanString(playTypeString(ecommEliteCallbackModal?.planType));
    }
  }, [ecommEliteCallbackModal?.view]);

  const closeModal = () => {
    dispatch(ecommEliteCallbackModalAction({ view: false, planType: null }));
  };

  const requestACallback = () => {
    const packName = returnPackNames(ecommEliteCallbackModal?.planType);
    if (ecommEliteCallbackModal?.planType && packName) {
      dispatch(
        requestCallback(
          {
            text: packName,
            feature_name: ecommEliteCallbackModal?.featureName,
          },
          () => {
            closeModal();
          },
        ),
      );
    }
  };

  return (
    <CommonCallbackModal
      message={`This feature is only available in our ${planString}. If you are interested in this, request a callback and our
          team will reach out to you.`}
      open={ecommEliteCallbackModal?.view || false}
      onClose={closeModal}
      onCallbackClick={requestACallback}
    />
  );
};

export default EcommEliteCallbackModal;
