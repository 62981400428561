import * as themeTypes from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
  history?: any;
}

export const fetchDisplayTags = (): Action => {
  return {
    type: themeTypes.FETCH_DISPLAY_TAGS,
  };
};

export const saveDisplayTags = (data: any): Action => {
  return {
    type: themeTypes.SAVE_DISPLAY_TAGS,
    data,
  };
};

export const searchDisplayTags = (data: any): Action => {
  return {
    type: themeTypes.SEARCH_DISPLAY_TAGS,
    data,
  };
};

export const saveSearchTags = (data: any): Action => {
  return {
    type: themeTypes.SAVE_SEARCH_TAGS,
    data,
  };
};

export const fetchDisplayThemes = (data: any): Action => {
  return {
    type: themeTypes.FETCH_DISPLAY_THEMES,
    data,
  };
};

export const saveDisplayThemes = (data: any): Action => {
  return {
    type: themeTypes.SAVE_DISPLAY_THEMES,
    data,
  };
};

export const emptyDisplayThemes = (): Action => {
  return {
    type: themeTypes.EMPTY_DISPLAY_THEMES,
  };
};

export const fetchSubscriptionDetails = (data?: any, callback?: any): Action => {
  return {
    type: themeTypes.GET_SUBSCRIPTION_DETAILS,
    data,
    callback,
  };
};

export const saveSubscriptionDetails = (data?: any): Action => {
  return {
    type: themeTypes.SAVE_SUBSCRIPTION_DETAILS,
    data,
  };
};

export const applyTheme = (data: any, callback: any) => {
  return {
    type: themeTypes.SET_THEME,
    data,
    callback,
  };
};

export const updateThemeData = (data: any) => {
  return {
    type: themeTypes.UPDATE_THEME_DATA,
    data,
  };
};

export const deactivateStoreTheme = (data: any, callback: any): Action => {
  return {
    type: themeTypes.DEACTIVATE_STORE_THEME,
    data,
    callback,
  };
};

export const getAllPresetColors = (): Action => {
  return {
    type: themeTypes.GET_ALL_PRESET_COLORS,
  };
};

export const saveAllPresetColors = (data: any): Action => {
  return {
    type: themeTypes.SAVE_ALL_PRESET_COLORS,
    data,
  };
};

export const setStoreThemeColorPalette = (data: any, callback?: any): Action => {
  return {
    type: themeTypes.SET_STORE_THEME_COLOR_PALETTE,
    data,
    callback,
  };
};

export const uploadCroppedImage = (data: any, callback?: any): Action => {
  return {
    type: themeTypes.UPLOAD_IMAGE_TO_S3,
    data,
    callback,
  };
};

export const uploadImageLinks = (data: any, callback?: any): Action => {
  return {
    type: themeTypes.UPLOAD_IMAGE_LINKS,
    data,
    callback,
  };
};

export const resetStoreBanner = (data: any, callback?: any): Action => {
  return {
    type: themeTypes.RESET_IMAGE_LINK,
    data,
    callback,
  };
};

export const reOrderStoreThemeBanners = (data: any, callback?: any): Action => {
  return {
    type: themeTypes.REORDER_STORE_THEME_BANNER,
    data,
    callback,
  };
};

export const removeStoreThemeBanner = (data: any, callback?: any): Action => {
  return {
    type: themeTypes.REMOVE_STORE_THEME_BANNER,
    data,
    callback,
  };
};

export const requestPremiumCallback = (data: any, callback?: any): Action => {
  return {
    type: themeTypes.REQUEST_PREMIUM_CALLBACK_ACTION,
    data,
    callback,
  };
};

export const getCfeCtaConfigs = (data: any): Action => {
  return {
    type: themeTypes.GET_CFE_CTA_CONFIGS,
    data,
  };
};

export const saveCfeCtaConfigs = (data: any): Action => {
  return {
    type: themeTypes.SAVE_CFE_CTA_CONFIGS,
    data,
  };
};

export const updateCfeCtaConfigs = (data: any, callback: any): Action => {
  return {
    type: themeTypes.UPDATE_CFE_CTA_CONFIGS,
    data,
    callback,
  };
};

export const updateNameAndLogoVisibility = (data: any, callback: any): Action => {
  return {
    type: themeTypes.UPDATE_LOGO_AND_NAME_VISIBILITY,
    data,
    callback,
  };
};
