import { LinearProgress } from "@material-ui/core";
import { FC, HTMLAttributes, useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authenticateUser, fetchSubscriptionDetails } from "../../Redux/Actions";
import { RootState } from "../../Redux/Reducers";
import { useCountdownHook } from "../../Utils/countdownTimerHook";
const NavigationDrawer = lazy(() => import("./../../Components/NavigationDrawer"));import { getAuthToken } from "../../Utils/authToken";
import { useStyles } from "./style";

const AppBanner = lazy(() => import("./AppBanner"));

interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  activeTabIndex?: number;
  headingText?: string;
  history?: any;
}

const Layout: FC<LayoutProps> = ({ activeTabIndex, className, headingText, ...props }): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { pageLoaderVisibility, storeData, staffMemberData } = useSelector((state: RootState) => ({
    pageLoaderVisibility: state.commonReducer.pageLoaderVisibility,
    storeData: state.storeReducer.store,
    staffMemberData: state.staffManagementReducer.staffMemberData,
  }));
  const bannerData = staffMemberData?.banner_info;

  const [timeDifference] = useCountdownHook(storeData?.theme?.expiry);

  useEffect(() => {
    if (timeDifference && timeDifference < 0) {
      dispatch(authenticateUser(getAuthToken(), history));
    }
  }, [timeDifference]);

  useEffect(() => {
    dispatch(authenticateUser(getAuthToken(), history));
    getAuthToken() && dispatch(fetchSubscriptionDetails());
  }, []);

  return (
    <>
      {!props?.hidden ? 
        <div className="banner-container">
          <AppBanner />
          <div className={`flex flex-row ${className || ""} ${bannerData?.text && classes.workspacebuyTimer}`}>
            <Suspense fallback={<div></div>}>
              <NavigationDrawer activeTabIndex={activeTabIndex}>{props.children}</NavigationDrawer>
            </Suspense>
            {/* <PageLoader show={false} /> */}
            {pageLoaderVisibility && 
              <LinearProgress
                color="primary"
                className="bottom-0 w-100"
                style={{
                  position: "fixed",
                  left: "0",
                  top: "0",
                  padding: "2px",
                }}
              />
            }
          </div>
          {/** Workspace Upsell is disabled (During Titan workspace feature development DD-4155) */}
          {/* <UpsellModal open={workspaceUpsellModal} closeModal={closeUpsellWorkspaceModal} /> */}
        </div>
       : 
        props.children
      }
    </>
  );
};

export default Layout;
