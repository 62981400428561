import { put, takeEvery } from "redux-saga/effects";
import {
  saveCampaignList,
  setPageLoaderVisibility,
  saveCampaignTemplateList,
  setCampaignCustomersNamesList,
  setCampaignBannerDetails,
  saveCampaignInfo,
  saveCampaignId,
  setCampaignTemplateData,
  savePrePaymentData,
} from "../Actions";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { SMS_MARKETING_API } from "../../Services/apiUrls";
import * as actionTypes from "./../ActionTypes/smsMarketing";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { parseToJson } from "../../Utils/common";
import { setPremiumPurchaseType, setPrePaymentDataToSessionStorage } from "../../Utils/_helper";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import store from "../Store";
function* getCampaignList(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SMS_MARKETING_API.getCampaignListApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      yield put(saveCampaignList({
        campaignList: parseToJson(response.data.data).campaign_list,
        pageNo: actions.data?.page,
      }));
    }
    actions.callback?.();
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* getCampaignReport(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: SMS_MARKETING_API.getCampaignReportApi,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response.data.data);
      actions?.callback?.(response?.data?.status, parsedData);
    } else {
      actions?.callback?.(false, "");
    }
  } catch (err) {
    actions?.callback?.(false, "");
  }
}

function* getCampaignTemplateList(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${SMS_MARKETING_API.campaignTemplateListApi}`,
    });
    if (response && response.data?.status) {
      yield put(saveCampaignTemplateList(parseToJson(response.data.data).templates));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error! Unable to get the templlate list");
  }
}

function* postRequestedCampaignTemplate(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SMS_MARKETING_API.requestedCampaignTemplateApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions?.callback(true);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* getCampaignCustomersNamesList(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${SMS_MARKETING_API.getCampaignCustomersNamesListApi}`,
      parseToJson: true,
    });
    if (response && response.data?.status) {
      yield put(setCampaignCustomersNamesList(response.data.data?.customer_lists));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* getCampaignCustomersContactList(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SMS_MARKETING_API.getCampaignCustomersContactListApi}`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data?.status) {
      actions?.callback(response.data.data.campaign_contacts);
    }
  } catch (err) {
    ToastNotifyError("Error!");
  }
}

function* getCampaignBannerDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${SMS_MARKETING_API.getCampaignBannerDetailsApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      yield put(setCampaignBannerDetails(parseToJson(response.data.data)));
    }
  } catch (err) {
    ToastNotifyError("Error!");
  }
}

function* getCampaignDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${SMS_MARKETING_API.getCampaignDetails(actions.data)}`,
    });
    if (response && response.data?.status) {
      yield put(saveCampaignInfo(parseToJson(response.data.data)));
      yield put(setCampaignTemplateData(parseToJson(response.data.data)?.campaign_details));
      actions.callback?.(parseToJson(response.data.data));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError("Error! Unable to get the campaign Details");
  }
}

function* createCampaign(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SMS_MARKETING_API.createCampaignApi}`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data?.status) {
      yield put(saveCampaignId(response.data.data.campaign_id));
    }
    actions.callback?.(response?.data?.data?.campaign_id);
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    actions?.callback();
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error in creating campaign, Try Again! ");
  }
}

function* saveCampaign(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${SMS_MARKETING_API.createCampaignApi}`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data?.status) {
      yield put(saveCampaignId(response.data.data.campaign_id));
      yield put(saveCampaignInfo(response.data.data));
      ToastNotifySuccess("Campaign saved successfully!!");
    }
    actions.callback?.(response?.data?.data?.campaign_id);
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    actions.callback?.();
    ToastNotifyError("Error! Campaign cannot be saved");
  }
}

function* sendTestMessage(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SMS_MARKETING_API.sendTestMessageApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      ToastNotifySuccess("Message sent successfully");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError(err?.data?.message);
  }
}

function* initCampaignPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SMS_MARKETING_API.initiateCampaignPurchaseApi}`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      const parsedData = JSON.parse(response.data.data);
      const prePaymentData = { ...parsedData, domain: store.getState().smsMarketingReducer.campaign_id };
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.SMS_CAMPAIGN);
      setPrePaymentDataToSessionStorage(JSON.stringify(prePaymentData));
      yield put(savePrePaymentData(prePaymentData));
      actions.callback && actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_CAMPAIGN_LIST, getCampaignList);
  yield takeEvery(actionTypes.GET_CAMPAIGN_REPORT, getCampaignReport);
  yield takeEvery(actionTypes.GET_CAMPAIGN_BANNER_DETAILS, getCampaignBannerDetails);
  yield takeEvery(actionTypes.GET_CAMPAIGN_TEMPALTE_LIST, getCampaignTemplateList);
  yield takeEvery(actionTypes.POST_REQUESTED_CAMPAIGN_TEMPLLATE, postRequestedCampaignTemplate);
  yield takeEvery(actionTypes.GET_CAMPAIGN_CUSTOMERS_NAMES_LIST, getCampaignCustomersNamesList);
  yield takeEvery(actionTypes.GET_CAMPAIGN_CUSTOMERS_CONTACT_LIST, getCampaignCustomersContactList);
  yield takeEvery(actionTypes.GET_CAMPAIGN_INFO, getCampaignDetails);
  yield takeEvery(actionTypes.CREATE_CAMPAIGN, createCampaign);
  yield takeEvery(actionTypes.SAVE_CAMPAIGN, saveCampaign);
  yield takeEvery(actionTypes.SEND_TEXT_MESSAGE, sendTestMessage);
  yield takeEvery(actionTypes.INITIATE_CAMPAIGN_PURCHASE, initCampaignPurchase);
}
