import { SAVE_HELP_SECTION } from "../ActionTypes/helpSection";

const ROUTE_PREFIX = process.env.NODE_ENV === "production" ? "https://digitalshowroom.in/" : "https://website.digitaldukaan.app/";

const initialState = {
  helpSectionStatus: {
    "/additional-pages": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/General/1/How-to-add-additional-pages-to-the-Digital-Showroom-premium-plus-website--/7fIZ`,
    },  
    "/crm": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/General/1/How-to-use-customer-management-feature-/ALqA`,
    },
    "/gst-billing": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/General/1/How-to-set-up-GST-billing-for-your-store-and-for-your-customers--/jV9s`,
    },
      
    "/customer-review-ratings": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/General/1/How-to-use-the-Customer-Reviews-&-Ratings-feature-/JYXp`,
    },
      
    "/collections": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Products-and-catalog/2/How-to-create-a-product-collection-in-a-catalog-/ZGg2`,
    },
    "/custom-delivery/setup": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Delivery/5/Pincode-Based-Delivery/t5aO`,
    },
      
    "/marketing/whatsapp-business-marketing": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/Setting-up-WhatsApp-Business-Account-/bAqP`,
    },  
    "/marketing/analytics/google_analytics": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/Steps-to-set-up-Google-Analytics/DXxu`,
    },
    "/leads": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/Lead-Generation/8uYZ`,
    },
      
    "/marketing/facebook-business-suite": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/How-to-use-a-Facebook-Pixel-account--/T9pA`,
    },
      
    "/marketing/coupons-list": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/How-to-create-vouchers/eAPQ`,
    },
      
    "/marketing/seo-tools": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/How-to-set-up-your-custom-SEO-for-your-store--/o42W`,
    },
      
    "/marketing/sms-marketing-dashboard": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/How-to-use-SMS-Marketing-/l6DJ`,
    },
      
    "/settings/add-social-media": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Marketing/6/How-to-add-social-media-on-your-footer--/zQTU`,
    },
      
    "/staff-details": {
      "is_visible": true,
      "route": `${ROUTE_PREFIX}help-section/Store-settings/7/How-to-add-staff-to-manage-your-store--/b0Ts`,
    },
      
  },
};

function helpReducer(state = initialState, action: any) {
  switch (action.type) {
    case SAVE_HELP_SECTION: {
      return {
        ...state,
        helpSectionStatus: action.data,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default helpReducer;
