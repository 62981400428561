import { put, takeEvery } from "redux-saga/effects";
import { setPageLoaderVisibility, saveStorePagesList, saveDefaultTemplatesList } from "../Actions";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { ADDITIONAL_PAGES_API } from "../../Services/apiUrls";
import * as actionTypes from "./../ActionTypes/additionalPagesActions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { parseToJson } from "../../Utils/common";

function* fetchSinglePageData(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${ADDITIONAL_PAGES_API.getPageDetailsApi}?page_id=${actions.data.page_id}&is_template=false`,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(response.data.page);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

/**
 * This function will fetch all the pages of the store and the default templates based on the query param
 * @param actions
 */
function* getPagesList(actions: any) {
  let apiUrl = `${config.base_url}${ADDITIONAL_PAGES_API.getPagesListApi}`;
  if (actions.data?.storeId) {
    apiUrl += "?store_id=" + actions.data.storeId;
  } else {
    apiUrl += "?is_template=true";
  }
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: apiUrl,
    });
    if (response && response.data?.status) {
      const parsedData = parseToJson(response.data.data);
      actions.callback && actions.callback(parsedData);
      if (actions?.data?.storeId) {
        yield put(saveStorePagesList(parsedData));
      } else {
        yield put(saveDefaultTemplatesList(parsedData));
      }
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

/**
 * This function will add the new page to store pages
 * @param actions
 */
function* addNewPageToStorePages(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${ADDITIONAL_PAGES_API.addNewPageApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      const parsedData = parseToJson(response.data.data);
      actions.callback && actions.callback(parsedData);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

/**
 * This function will add the new page to store pages
 * @param actions
 */
function* saveTemplateChangesAsDraft(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${ADDITIONAL_PAGES_API.saveTemplateChangesApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      const parsedData = parseToJson(response.data.data);
      actions.callback && actions.callback(true);
    }
  } catch (err: any) {
    actions.callback && actions.callback(false);
    ToastNotifyError(err?.data?.message);
  }
}

/**
 * This function will add the new page to store pages
 * @param actions
 */
function* publishTemplateChanges(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${ADDITIONAL_PAGES_API.publishTemplateChangesApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      const parsedData = parseToJson(response.data.data);
      actions.callback && actions.callback(parsedData);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

/*
 * This function will delete the page
 * @param actions
 */
function* deletePage(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "DELETE",
      url: `${config.base_url}${ADDITIONAL_PAGES_API.deletePageApi}/${actions.data}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(true);
      ToastNotifySuccess("Page Deleted Successfully");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

/*
 * This function will reorder the page
 * @param actions
 */
function* onReorderPage(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${ADDITIONAL_PAGES_API.reorderPageApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(true);
      ToastNotifySuccess("Page Reordered Successfully");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_SINGLE_PAGE_DATA, fetchSinglePageData);
  yield takeEvery(actionTypes.GET_PAGES_LIST, getPagesList);
  yield takeEvery(actionTypes.ADD_NEW_PAGE_TO_STORE_PAGES, addNewPageToStorePages);
  yield takeEvery(actionTypes.ON_PUBLISH_TEMPLATE_CHANGES, publishTemplateChanges);
  yield takeEvery(actionTypes.ON_SAVE_TEMPLATE_CHANGES_AS_DRAFT, saveTemplateChangesAsDraft);
  yield takeEvery(actionTypes.ON_DELETE_PAGE, deletePage);
  yield takeEvery(actionTypes.ON_REORDER_PAGE, onReorderPage);
}
