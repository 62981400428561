import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { saveBusinessList, setPageLoaderVisibility, updateStoreBusiness } from "../Actions";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import * as actionTypes from "./../ActionTypes";

function* fetchBusinessList(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/merchant/getbusinessList`,
    });
    if (response && response.data.status) {
      yield put(saveBusinessList(response.data.businesses));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* submitBusiness(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/setStoreBusinesses`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      yield put(updateStoreBusiness(response.data.store.store_businesses));
      ToastNotifySuccess("Busines Type updated!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad!");
  }
}

export default function* root() {
  yield takeEvery(actionTypes.FETCH_BUSSINESS_TYPES, fetchBusinessList);
  yield takeEvery(actionTypes.SUBMIT_BUSINESS, submitBusiness);
}
