import * as actionTypes from "../ActionTypes/crm";

const initialState = {
  selectedCustomers: [],
  storeAnalytics: {
    isFetched: false,
  },
  number_of_orders: null,
  sales_of_customer: null,
  days_from_last_order: null,
  customerList: {
    customers: [],
    next: false,
    page: 1,
    isLoading: false,
  },
  searchParams: {
    customers: [],
    page: 1,
    text: "",
    next: false,
    isLoading: false,
  },
  store_list: [],
  is_oldest_first: false,
  ordersList: {
    orders: [],
    page: 1,
    next: false,
  },
  savedExcelData: {},
  excelID: null,
  selectedHeader: {},
  activeHeaderIndex: 0,
  filterValueChanged: false,
  promoCustomerGroupList: [],
};

const CRMReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case actionTypes.SAVE_STORE_CUSTOMERS: {
      let newCustomerList: any[];
      if (action?.data?.removeList) {
        newCustomerList = [];
      } else {
        newCustomerList =
          action?.data?.customers?.length > 0
            ? [...state?.customerList?.customers, ...action.data.customers]
            : [...state?.customerList?.customers];
      }

      let pageNo: number | undefined;

      if (action?.data?.isLoading) {
        pageNo = state.customerList.page;
      } else {
        if (action?.data?.next) {
          pageNo = state.customerList.page + 1;
        } else {
          pageNo = 1;
        }
      }

      return {
        ...state,
        customerList: {
          ...state.customerList,
          customers: newCustomerList,
          page: pageNo,
          next: action?.data?.next,
          isLoading: action?.data?.isLoading || false,
        },
      };
    }
    case actionTypes.SAVE_SEARCHED_CUSTOMERS: {
      let newCustomerList: any[];
      if (action?.data?.removeList) {
        newCustomerList = [];
      } else {
        newCustomerList =
          action?.data?.customers?.length > 0
            ? [...state?.searchParams?.customers, ...action?.data?.customers]
            : [...state?.searchParams?.customers];
      }

      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          customers: newCustomerList,
          page: action?.data?.next ? state?.searchParams?.page + 1 : 1,
          next: action?.data?.next,
          isLoading: action?.data?.isLoading || false,
        },
      };
    }
    case actionTypes.SAVE_STORE_ANALYTICS_DATA: {
      return {
        ...state,
        storeAnalytics: action.data,
      };
    }
    case actionTypes.SAVE_STORE_LIST: {
      return {
        ...state,
        store_list: action.data,
      };
    }
    case actionTypes.SET_SEARCH_VALUE: {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          text: action.data,
        },
      };
    }
    case actionTypes.SET_SORT_VALUE: {
      return {
        ...state,
        is_oldest_first: action.data,
      };
    }
    case actionTypes.SAVE_NO_OF_ORDERS: {
      return {
        ...state,
        number_of_orders: action.data,
      };
    }
    case actionTypes.SAVE_SALE_OF_CUSTOMER: {
      return {
        ...state,
        sales_of_customer: action.data,
      };
    }
    case actionTypes.SAVE_DAYS_FROM_LAST_ORDER: {
      return {
        ...state,
        days_from_last_order: action.data,
      };
    }
    case actionTypes.SET_FILTER_VALUES: {
      return {
        ...state,
        ...action.data,
      };
    }
    case actionTypes.SET_SELECTED_CUSTOMERS: {
      return {
        ...state,
        selectedCustomers: action.data,
      };
    }
    case actionTypes.CLEAR_ALL_FILTER: {
      return {
        ...state,
        ...action.data,
        filterValueChanged: false,
      };
    }
    case actionTypes.SET_CUSTOMER_LIST_EMPTY: {
      return {
        ...state,
        customerList: {
          ...state.customerList,
          customers: [],
          page: 1,
        },
      };
    }
    case actionTypes.SAVE_ORDER_LIST_BY_CUSTOMER_PHONE: {
      let newOrderList: any[];
      if (action?.data?.removeList) {
        newOrderList = [];
      } else {
        newOrderList =
          action?.data?.orders.length > 0
            ? [...state.ordersList.orders, ...action.data.orders]
            : [...state.ordersList.orders];
      }

      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          orders: newOrderList,
          page: action?.data?.next ? state.ordersList.page + 1 : 1,
          next: action?.data?.next,
        },
      };
    }
    case actionTypes.SET_SELECTED_HEADER: {
      return {
        ...state,
        selectedHeader: action.data,
      };
    }
    case actionTypes.SET_ACTIVE_HEADER_INDEX: {
      return {
        ...state,
        activeHeaderIndex: action.data,
      };
    }
    case actionTypes.APPLY_GLOBAL_FILTER: {
      return {
        ...state,
        ...action.data,
      };
    }
    case actionTypes.SAVE_EXCEL_DATA: {
      return {
        ...state,
        savedExcelData: action.data,
      };
    }
    case actionTypes.SAVE_EXCEL_ID: {
      return {
        ...state,
        excelID: action.data,
      };
    }
    case actionTypes.SAVE_PROMO_CUSTOMER_GROUP_LISTS: {
      return {
        ...state,
        promoCustomerGroupList: action.data?.promo_customer_group_list,
      };
    }
    default:
      return state;
  }
};

export default CRMReducer;
