import {
  ADD_NEW_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  FETCH_ANNOUNCEMENTS,
  HIDE_ANNOUNCEMENT_BAR,
  EDIT_ANNOUNCEMENT_BAR_COLOR,
  REORDER_ANNOUNCEMENTS,
} from "./../ActionTypes";
interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const addAnnouncement = (data: any, callback?: any): Action => {
  return {
    type: ADD_NEW_ANNOUNCEMENT,
    data,
    callback,
  };
};

export const updateAnnouncement = (data: any, callback?: any): Action => {
  return {
    type: UPDATE_ANNOUNCEMENT,
    data,
    callback,
  };
};

export const deleteAnnouncement = (data: any, callback?: any): Action => {
  return {
    type: DELETE_ANNOUNCEMENT,
    data,
    callback,
  };
};

export const fetchAnnouncements = (): Action => {
  return {
    type: FETCH_ANNOUNCEMENTS,
  };
};

export const hideAnnouncementBar = (data: any): Action => {
  return {
    type: HIDE_ANNOUNCEMENT_BAR,
    data,
  };
};

export const editAnnouncementThemeColor = (data: any, callback?: any): Action => {
  return {
    type: EDIT_ANNOUNCEMENT_BAR_COLOR,
    data,
    callback,
  };
};

export const reorderAnnouncement = (data: any): Action => {
  return {
    type: REORDER_ANNOUNCEMENTS,
    data,
  };
};
