import { IMAGE_TYPE } from "./../Constants/imageType";
import { EImageType } from "./../Types/imageType";
import * as blobUtil from "blob-util";

export default function checkImageType(type: EImageType) {
  return [IMAGE_TYPE.JPEG, IMAGE_TYPE.JPG, IMAGE_TYPE.PNG].some((i) => i === type);
}

// get the image type for the base64 image
function returnImageType(image: any) {
  let imageType = image.src.split(";")[0].split(":")[1];

  if (!checkImageType(imageType)) {
    imageType = IMAGE_TYPE.JPEG;
  }
  return imageType;
}

const createImage = (url: any) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

// function getRadianAngle(degreeValue: any) {
//   return (degreeValue * Math.PI) / 180;
// }

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export async function getCroppedImg(imageSrc: any, pixelCrop: any) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * (maxSize / 2 * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  return Promise.resolve(canvas.toDataURL(returnImageType(image)));

  // // As a blob
  // return new Promise(resolve => {
  //   canvas.toBlob(file => {
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
}

// export async function getRotatedImage(imageSrc: any, rotation = 0) {
//   const image: any = await createImage(imageSrc);
//   const canvas = document.createElement('canvas');
//   const ctx: any = canvas.getContext('2d');

//   const orientationChanged =
//     rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
//   if (orientationChanged) {
//     canvas.width = image.height;
//     canvas.height = image.width;
//   } else {
//     canvas.width = image.width;
//     canvas.height = image.height;
//   }

//   ctx.translate(canvas.width / 2, canvas.height / 2);
//   ctx.rotate((rotation * Math.PI) / 180);
//   ctx.drawImage(image, -image.width / 2, -image.height / 2);

//   return Promise.resolve(canvas.toDataURL('image/jpeg'));
//   // return new Promise(resolve => {
//   //   canvas.toBlob(file => {
//   //     resolve(URL.createObjectURL(file))
//   //   }, 'image/jpeg')
//   // })
// }

export async function resizedataURL(datas: any, wantedWidth?: any, wantedHeight?: any, imageType?: any) {
  return new Promise((resolve, reject) => {
    // We create an image to receive the Data URI
    const img = document.createElement("img");
    // putting cropped image to img src to calculate cropped image width and height
    // to draw on canvas.
    img.src = datas;

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      const canvas = document.createElement("canvas");
      const ctx: any = canvas.getContext("2d");

      // We set the dimensions at the wanted size.
      canvas.width = img.width; // width of cropped image
      canvas.height = img.height; // height of cropped image

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(this, 0, 0, img.width, img.height);

      const dataURI = canvas.toDataURL(imageType || "image/jpeg");
      const dataBlob = blobUtil.dataURLToBlob(dataURI);
      resolve(dataBlob);
    };
    // We put the Data URI in the image's src attribute
    img.src = datas;
  });
}

/**
 * function to convert upload image from MIME type to base64 for upload purpose
 * @param datas
 * @param wantedWidth
 * @param wantedHeight
 * @returns base64 image format
 */
export async function resizeImageIntoDataUrl(datas: any, wantedWidth?: any, wantedHeight?: any) {
  return new Promise((resolve, reject) => {
    // We create an image to receive the Data URI
    const img = document.createElement("img");

    // putting cropped image to img src to calculate cropped image width and height
    // to draw on canvas.
    img.src = datas;

    // When the event "onload" is triggered we can resize the image.
    img.onload = async function () {
      // We create a canvas and get its context.
      const canvas = document.createElement("canvas");
      const ctx: any = canvas.getContext("2d");

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth; // width of cropped image
      canvas.height = wantedHeight; // height of cropped image

      // We resize the image with the canvas method drawImage();
      await ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

      const dataURI = canvas.toDataURL(returnImageType(img));
      resolve(dataURI);
    };
    // We put the Data URI in the image's src attribute
    img.src = datas;
  });
}
