import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useIsDashy() {
  const location = useLocation();
  const [isDashy, setIsDashy] = useState<boolean>(false);

  useEffect(() => {
    setIsDashy(!location.pathname.includes("webview"));
  }, [location]);

  return isDashy;
}

export default useIsDashy;
