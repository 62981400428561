import { LAZYPAY_VIEWS } from "../../Constants";
import * as paymentTypes from "../ActionTypes";

const initialState = {
  prePaymentData: {},
  paymentInfo: {},
  postPayment: {},
  paymentModesData: {},
  paymentOptionsChange: {},
  paymentMethodChange: false,
  activePaymentMethods: [],
  lazyPayAuth: {
    show: false,
    view: LAZYPAY_VIEWS.OTP,
  },
  emiOptionsForCard: {},
  allEmiOptions: {},
};

const paymentMethodReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case paymentTypes.SAVE_PRE_PAYMENT_DATA:
      return { ...state, prePaymentData: action.data };
    case paymentTypes.SAVE_PAYMENT_INFORMATIONS:
      return { ...state, paymentInfo: action.data };
    case paymentTypes.SAVE_POST_PAYMENT_DATA:
      return { ...state, postPayment: action.data };
    case paymentTypes.SAVE_PAYMENT_MODES_DATA:
      return { ...state, paymentModesData: action.data };
    case paymentTypes.PAYMENT_OPTIONS_CHANGE:
      return { ...state, paymentOptionsChange: action.data };
    case paymentTypes.PAYMENT_METHOD_CHANGE:
      return { ...state, paymentMethodChange: action.data };
    case paymentTypes.SET_ACTIVE_PAYMENT_METHODS:
      return { ...state, activePaymentMethods: action.data };
    case paymentTypes.TOGGLE_LAZYPAY_AUTH:
      return {
        ...state,
        lazyPayAuth: {
          show: action.data.show,
          view: action.data.view || LAZYPAY_VIEWS.OTP,
        },
      };
      case paymentTypes.SAVE_EMI_OPTION_FOR_CARD:
        return {
          ...state,
          emiOptionsForCard: action.data,
        };
      case paymentTypes.SAVE_ALL_EMI_OPTIONS:
        return {
          ...state,
          allEmiOptions: action.data,
        };
    default:
      return state;
  }
};

export default paymentMethodReducer;
