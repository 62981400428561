import { put, takeEvery } from "redux-saga/effects";
import {
  SUBMIT_WHATSAPP_MARKETING_DEMO_REQUEST,
  GET_WHATSAPP_MARKETING_REQUEST_STATUS,
  SET_WABA_CONFIG,
  GET_WABA_CONFIG,
} from "./../ActionTypes";
import { ToastNotifyError } from "./../../Components/Toast";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import {
  CHECK_WABA_LEAD_REQUEST_STATUS,
  REQUEST_WABA_DEMO,
  GET_WABA_CONFIG_API,
  UPDATE_WABA_CONFIG_API,
} from "../../Services/apiUrls";
import { setPageLoaderVisibility, updataWhatsappMarketingRequestStatus, updateWabaConfig } from "../Actions";

function* submitRequest(actions) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${REQUEST_WABA_DEMO}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      actions.callback();
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getRequestStatus() {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CHECK_WABA_LEAD_REQUEST_STATUS}`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(updataWhatsappMarketingRequestStatus({ isDemoRequestAlreadyGenerated: response.data.data }));
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
  }
}

function* setWabaConfig(actions) {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}${UPDATE_WABA_CONFIG_API}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      yield put(updateWabaConfig(response?.data));
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
  }
}

function* getWabaConfig(action) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GET_WABA_CONFIG_API}/${action.data}`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      if (response?.data?.config) {
        yield put(updateWabaConfig(response?.data));
      } else {
        ToastNotifyError("Config Missing");
      }
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(GET_WHATSAPP_MARKETING_REQUEST_STATUS, getRequestStatus);
  yield takeEvery(SUBMIT_WHATSAPP_MARKETING_DEMO_REQUEST, submitRequest);
  yield takeEvery(GET_WABA_CONFIG, getWabaConfig);
  yield takeEvery(SET_WABA_CONFIG, setWabaConfig);
}
