import { put, takeEvery } from "redux-saga/effects";
import * as adsTypes from "../ActionTypes";
import apiCall from "../../Services/api";
import {
  saveAdsList,
  savePrePaymentData,
  saveSearchedAdLocations,
  saveAdDetails,
  getAdDetails,
  setPageLoaderVisibility,
} from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import config from "./../../Config";
import { setPremiumPurchaseType, setPrePaymentDataToSessionStorage, appendCreditUsedKey } from "../../Utils/_helper";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import store from "../Store";

function* fetchAdsList(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/marketing/getAdsList`,
      headers: {
        app_version: 1,
      },
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      yield put(saveAdsList(parsedData));
      actions.callback && actions.callback(parsedData);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch Ads, try later!");
  }
}

function* searchAdsLocations(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/searchLocation`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      yield put(saveSearchedAdLocations(parsedData));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to search Locations, try later!");
  }
}

function* saveAd(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/saveAd`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      actions.callback && actions.callback(parsedData);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* createAd(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/createAd/${actions.data}`,
    });
    if (response && response.data.status) {
      ToastNotifySuccess("Ad created Successfully !");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* initAdPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/initiateAdPurchase`,
      data: appendCreditUsedKey(actions.data),
    });
    if (response && response?.data?.status) {
      const parsedData = JSON.parse(response.data.data);
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.GOOGLE_ADDS);
      setPrePaymentDataToSessionStorage(JSON.parse(JSON.stringify(parsedData)));
      yield put(savePrePaymentData(parsedData));
      actions.callback && actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* fetchAdDetails(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/marketing/getAdDetails/${actions.data}`,
    });
    if (response && response?.data?.status) {
      const parsedData = JSON.parse(response.data.data);
      yield put(saveAdDetails(parsedData));
      actions.callback && actions.callback(response?.data?.status, parsedData);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch Ad details, try later!");
  }
}

function* updateAdState(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/updateAdState`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      yield put(getAdDetails(actions.data.ad_id, () => null));
      actions?.callback?.(response?.data?.status);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to update Ad state, try later!");
  }
}

export default function* root() {
  yield takeEvery(adsTypes.FETCH_ADS_LIST, fetchAdsList);
  yield takeEvery(adsTypes.SEARCH_AD_LOCATIONS, searchAdsLocations);
  yield takeEvery(adsTypes.SAVE_AD, saveAd);
  yield takeEvery(adsTypes.CREATE_AD, createAd);
  yield takeEvery(adsTypes.INITIATE_AD_PURCHASE, initAdPurchase);
  yield takeEvery(adsTypes.FETCH_AD_DETAILS, fetchAdDetails);
  yield takeEvery(adsTypes.UPDATE_AD_STATE, updateAdState);
}
