export const FB_APP_ID = "501301701610860";

export const FB_API_VERSION = "v13.0";

export const FB_LOGIN_STATUS = {
  CONNECTED: "connected",
  NOT_CONNECTED: "not_authorized",
  UNKNOWN: "unknown",
};

export const FB_LOGIN_CONFIG = {
  CURRENCY: "INR",
  TIMEZONE: "Asia/Kolkata", // Intl.DateTimeFormat().resolvedOptions().timeZone,
  BUSINESS_VERTICAL: "ECOMMERCE",
  AUTH_TYPE_REAUTHENTICATE: "reauthenticate",
  SCOPE: {
    MANAGE_BUSINESS_EXTENSION: "manage_business_extension",
    ADS_MANAGEMENT: "ads_management",
    BUSINESS_MANAGEMENT: "business_management",
    // CATALOG_MANAGEMENT: "catalog_management",
    PAGE_READ_ENGAGEMENT: "pages_read_engagement",
    PUBLIC_PROFILE: "public_profile",
    EMAIL: "email",
    INSTAGRAM_BASIC: "instagram_basic",
    INSTAGRAM_CONTENT_PUBLISH: "instagram_content_publish",
    INSTAGRAM_MANAGE_INSIGHTS: "instagram_manage_insights",
    // PAGES_SHOW_LIST: "pages_show_list",
  },
};
