import { call, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_FBE_ACCOUNT,
  GET_FBE_ACCOUNT_DETAILS,
  GET_FBE_ACCOUNT_DETAILS_FROM_SERVER,
  GET_FBE_ADS_VISIBILITY_STATUS,
  GET_FBE_FEED_ID,
  GET_FBE_USER_CATALOG,
  SET_FBE_ACCOUNT_DETAILS_ON_SERVER,
} from "../ActionTypes";
import { saveFbeAccountDetails, setFbeAccountDetailsOnServer, setFBEAdsVisibilityStatus } from "../Actions";
import apiCall from "./../../Services/api";
import store from "../Store";
import { FACEBOOK_ADS_API } from "../../Services/apiUrls";
import { AccountDetails } from "../../Types/facebookBusiness";
import { getFbeFeedId } from "../../Services/fbe";
import { ToastNotifyError } from "../../Components/Toast";

function* getFbeVisibilityStatus(): any {
  try {
    const response = yield apiCall({
      url: FACEBOOK_ADS_API.getFbeAdsVisibility,
      method: "GET",
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(setFBEAdsVisibilityStatus(response.data.data.fb_ads_visibility));
    }
  } catch (err) {
    //
  }
}

/** Hit after getting login status or loggin in,  read the current feature configuration of current fb account
 * Use either Webhook, or FB App installation API endpoint (in this case) to get several App IDs,
 * like Ad ID, Catalogue ID ,Pixel ID (helpful in firing Pixel events) etc
 */
function* getFbeAccountDetailsFromFacebook(actions: any): any {
  try {
    const response = yield fetch(
      FACEBOOK_ADS_API.getFbeAccountDetailsFromFacebook(actions.data.externalBusinessId, actions.data.accessToken),
      {
        method: "GET",
      },
    );
    const data = yield response.json();
    if (data?.error?.code) {
      throw new Error(data.error);
    } else {
      actions.callback && actions.callback(true, data);
      const userData: AccountDetails = data.data[0];
      const payload: AccountDetails | any = {
        store_id: store.getState().storeReducer.store.store_id,
        ad_account_id: userData.ad_account_id,
        business_manager_id: userData.business_manager_id,
        catalog_id: userData.catalog_id,
        pixel_id: userData.installed_features[0].connected_assets.pixel_id,
        page_id: userData.profiles[0] || "",
        instagram_profile_id: userData.profiles[1] || "",
        user_id: actions.data.userID || "",
        source: "ds",
      };
      // const feed_id = yield call(getFbeFeedId, payload.catalog_id);
      // payload.feed_id = feed_id;
      actions.callback(true, payload);
    }
  } catch (err) {
    actions.callback && actions.callback(false);
  }
}

function* deleteFbeAccount(actions: any): any {
  try {
    const response = yield fetch(
      FACEBOOK_ADS_API.deleteFbeAccount(actions.data.externalBusinessId, actions.data.accessToken),
      {
        method: "DELETE",
      },
    );
    const data = yield response.json();
    if (data?.error?.code) {
      throw data.error;
    } else {
      const payload = {
        store_id: store.getState().storeReducer.store.store_id,
        ad_account_id: "",
        business_manager_id: "",
        pixel_id: "",
        catalog_id: "",
        page_id: "",
        instagram_profile_id: "",
        user_id: "",
        source: "ds",
      };
      yield put(setFbeAccountDetailsOnServer(payload));
      actions.callback(true, data);
    }
  } catch (err) {
    actions.callback(false);
  }
}

function* setFbeAccountDetailsToServer(actions: any): any {
  try {
    const response = yield apiCall({
      url: FACEBOOK_ADS_API.setFbeAccountDetailsToServer,
      method: "POST",
      data: actions.data,
    });
    if (response?.data?.status) {
      yield put(saveFbeAccountDetails(actions.data));
    }
    actions.callback && actions.callback(true);
  } catch (err) {
    actions.callback && actions.callback(false);
  }
}

function* getFbeAccountDetailsViaServer(actions: any): any {
  try {
    const response = yield apiCall({
      url: FACEBOOK_ADS_API.getFbeAccountDetailsViaServer(actions.data),
      method: "GET",
    });
    if (response && response.data.status) {
      actions.callback(!!response.data.fb_ads_ids.business_manager_id);
      yield put(saveFbeAccountDetails(response.data.fb_ads_ids));
    } else {
      actions.callback(false);
    }
  } catch (err) {
    actions.callback(false);
  }
}

function* getFeedId(actions: any): any {
  const userData = { ...actions.data };
  try {
    const response = yield apiCall({
      url: FACEBOOK_ADS_API.getFeedId(actions.data.catalog_id),
      method: "GET",
    });
    if (response && response.data.status) {
      const payload = {
        ...userData,
        feed_id: response.data[0],
      };
      yield put(setFbeAccountDetailsOnServer(payload));
      yield put(saveFbeAccountDetails(payload));
      // yield put(saveFbeAccountDetails(response.data.fb_ads_ids));
    } else {
      actions.callback?.(false);
    }
  } catch (err) {
    actions.callback?.(false);
  }
}

function* getFbeUserCatalog(actions: any): any {
  try {
    const response = yield apiCall({
      url: FACEBOOK_ADS_API.getUserCatalog(actions.data),
      method: "GET",
    });
    if (response && response.data.status) {
      actions.callback?.(true, response.data.url);
    } else {
      actions.callback(false);
    }
  } catch (err) {
    actions.callback(false);
  }
}

export default function* root() {
  yield takeEvery(GET_FBE_ACCOUNT_DETAILS, getFbeAccountDetailsFromFacebook);
  yield takeEvery(DELETE_FBE_ACCOUNT, deleteFbeAccount);
  yield takeEvery(SET_FBE_ACCOUNT_DETAILS_ON_SERVER, setFbeAccountDetailsToServer);
  yield takeEvery(GET_FBE_ACCOUNT_DETAILS_FROM_SERVER, getFbeAccountDetailsViaServer);
  // yield takeEvery(GET_FBE_FEED_ID, getFeedId);
  yield takeEvery(GET_FBE_USER_CATALOG, getFbeUserCatalog);
  yield takeEvery(GET_FBE_ADS_VISIBILITY_STATUS, getFbeVisibilityStatus);
}
