import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { setPageLoaderVisibility, fetchAnnouncements, updateStoreAnnouncements, authenticateUser } from "../Actions";

import apiCall from "./../../Services/api";
import * as storeTypes from "./../ActionTypes";
import config from "./../../Config";
import { parseToJson } from "../../Utils/common";
import { getAuthToken } from "../../Utils/authToken";

/** Hello bar announcement related API Calls */

function* addAnnouncement(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/premium/storeWidgets`,
      data: actions.data,
    });

    if (response && response.data.status) {
      yield put(fetchAnnouncements());
      actions.callback && actions.callback();
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Announcement Saved");
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* getAnnouncements(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/premium/storeWidgets`,
    });

    if (response && response.data.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(updateStoreAnnouncements(parsedData));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err: any) {
    ToastNotifyError("Error loading announcements!");
    yield put(setPageLoaderVisibility(false));
  }
}

function* deleteAnnouncement(actions: any): any {
  try {
    const response = yield apiCall({
      method: "DELETE",
      url: `${config.base_url}/dotk/vm1/premium/storeWidgets/${actions.data.storeWidgetId}`,
    });
    if (response && response.data.status) {
      yield put(fetchAnnouncements());
      actions.callback && actions.callback();
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Announcement deleted");
    }
  } catch (err: any) {
    ToastNotifyError("Error deleting announcement");
    yield put(setPageLoaderVisibility(false));
  }
}

function* updateAnnouncement(actions: any): any {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}/dotk/vm1/premium/storeWidgets`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchAnnouncements());
      actions.callback && actions.callback();
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Announcement edited successfully!");
    }
  } catch (err: any) {
    ToastNotifyError("Error editing announcement");
    yield put(setPageLoaderVisibility(false));
  }
}

function* hideAnnouncementBar(actions: any): any {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}/dotk/vm1/premium/storeWidgetsStatus`,
      data: actions.data,
    });

    if (response && response.data.status) {
      yield put(fetchAnnouncements());
      yield put(setPageLoaderVisibility(false));
      actions?.data?.status == 0
        ? ToastNotifySuccess("Announcement bar removed from your website")
        : ToastNotifySuccess("Announcement bar added to your website");
    }
  } catch (err: any) {
    ToastNotifyError("Error hiding announcement");
    yield put(setPageLoaderVisibility(false));
  }
}

function* editAnnouncementThemeColor(actions: any): any {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}/dotk/vm1/premium/storeWidgetsColor`,
      data: actions.data,
    });

    if (response && response.data.status) {
      yield put(fetchAnnouncements());
      actions.callback && actions.callback();
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Announcement bar theme changed successfully!");
    }
  } catch (err: any) {
    ToastNotifyError("Error changing theme of announcement");
    yield put(setPageLoaderVisibility(false));
  }
}

function* reorderAnnouncements(actions: any): any {
  try {
    const token = getAuthToken();
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}/dotk/vm1/premium/storeWidgetsOrder`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchAnnouncements());
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Announcement reordered successfully!");
    }
  } catch (err: any) {
    ToastNotifyError("Error reordering announcement");
    yield put(setPageLoaderVisibility(false));
  }
}

export default function* root() {
  yield takeEvery(storeTypes.ADD_NEW_ANNOUNCEMENT, addAnnouncement);
  yield takeEvery(storeTypes.DELETE_ANNOUNCEMENT, deleteAnnouncement);
  yield takeEvery(storeTypes.FETCH_ANNOUNCEMENTS, getAnnouncements);
  yield takeEvery(storeTypes.UPDATE_ANNOUNCEMENT, updateAnnouncement);
  yield takeEvery(storeTypes.HIDE_ANNOUNCEMENT_BAR, hideAnnouncementBar);
  yield takeEvery(storeTypes.EDIT_ANNOUNCEMENT_BAR_COLOR, editAnnouncementThemeColor);
  yield takeEvery(storeTypes.REORDER_ANNOUNCEMENTS, reorderAnnouncements);
}
