import * as actionType from "../ActionTypes/couponsActionsType";

export function createCoupon(data: any) {
  return {
    type: actionType.CREATE_COUPON,
    data,
  };
}

// for V2
export function createPromo(data: any, isCreate=true, callback?: any) {
  return {
    type: actionType.CREATE_PROMO,
    data,
    isCreate,
    callback,
  };
}

export function fetchCouponList(data: any, callback?: any) {
  return {
    type: actionType.GET_ALL_COUPON_LIST,
    data,
    callback,
  };
}

export function fetchCouponListV2(
  data: { store_id: number; status: number; page: number; sort_by: number; sort_type: number },
  callback?: any,
) {
  return {
    type: actionType.GET_ALL_COUPON_LIST_V2,
    data,
    callback,
  };
}

export function getPromoCodeDetailsV2(
  data: { id: string },
  callback?: any,
) {
  return {
    type: actionType.GET_PROMO_CODE_DETAILS_ACTION_V2,
    data,
    callback,
  };
}

export function savePromoCodeDetailsV2(data: any) {
  return {
    type: actionType.SAVE_PROMO_CODE_DETAILS_ACTION_V2,
    data,
  };
}

export function saveMerchantCouponsList(data: any) {
  return {
    type: actionType.SAVE_MERCHANT_COUPON_LIST,
    data,
  };
}

export function saveMerchantCouponsListV2(data: any) {
  return {
    type: actionType.SAVE_MERCHANT_COUPON_LIST_V2,
    data,
  };
}

export function updatePromoCodeStatus(data: any) {
  return {
    type: actionType.UPDATE_PROMO_CODE_STATUS,
    data,
  };
}

export function getPromoCodeDetails(data: any) {
  return {
    type: actionType.GET_PROMO_CODE_DETAILS_ACTION,
    data,
  };
}

export function shareCouponCode(data: any) {
  return {
    type: actionType.SHARE_COUPON_CODE_ACTION,
    data,
  };
}

export function getPaymentDetailsForSubscriptionPurchaseUsingCoupon(data: any) {
  return {
    type: actionType.GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
    data,
  };
}

export function setPaymentDetailsForSubscriptionPurchaseUsingCoupon(data: any) {
  return {
    type: actionType.SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
    data,
  };
}
