import * as businessTypes from "../ActionTypes";

const initialState = {
  businesses: [],
  businessDetails: {},
};

const businessSelectionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case businessTypes.SAVE_BUSSINESS_TYPES:
      return { ...state, businesses: action.data };
    default:
      return state;
  }
};

export default businessSelectionReducer;
