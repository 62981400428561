import * as actionTypes from "../ActionTypes";

const initialState = {
  productInquirySetting: {
    storeId: null,
    productInquiryFeatureEnabled: -1,
    totalPages: 0,
    tabSelected: false,
    selectedItem: null,
  },
  productInquiries: {},
};

const productInquiryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_PRODUCT_INQUIRY:
      return {
        ...state,
        productInquiries: action?.data,
      };
    case actionTypes.ENABLE_PRODUCT_INQUIRY_FEATURE:
      return {
        ...state,
        productInquirySetting: {
          ...state.productInquirySetting,
          storeId: action?.data,
        },
      };
    case actionTypes.SAVE_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS:
      return {
        ...state,
        productInquirySetting: {
          ...state.productInquirySetting,
          productInquiryFeatureEnabled: action?.data,
        },
      };
    case actionTypes.SAVE_PRODUCT_ENQUIRY_TOTAL_PAGES:
      return {
        ...state,
        productInquirySetting: {
          ...state.productInquirySetting,
          totalPages: action?.data,
        },
      };
    case actionTypes.SET_PRODUCT_INQUIRY_TAB:
      return {
        ...state,
        productInquirySetting: {
          ...state.productInquirySetting,
          tabSelected: action?.data,
        },
      };
    case actionTypes.SET_PRODUCT_FILTERED_DATA:
      return {
        ...state,
        productInquirySetting: {
          ...state.productInquirySetting,
          selectedItem: action?.data,
        },
      };
    default:
      return state;
  }
};

export default productInquiryReducer;
