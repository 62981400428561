import { put, takeEvery } from 'redux-saga/effects';
import { GET_HELP_SECTION } from '../ActionTypes/helpSection';
import apiCall from "./../../Services/api";
import { HELP_SECTION } from '../../Services/apiUrls';
import { saveHelpSection } from '../Actions/helpSection';

function* fetchHelpSectionStatus() {
  try {
    const response = yield apiCall({
      method: "GET",
      url: HELP_SECTION.GET_HELP_SECTION,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveHelpSection(response?.data?.data?.routes
        ? response?.data?.data?.routes : response?.data?.routes));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(GET_HELP_SECTION, fetchHelpSectionStatus);
}
