
export const getVerificationQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailVerificationQueryParams = {
        store_id:urlParams.get('store_id'),
        email:urlParams.get('email'),
        status:urlParams.get('status'),
    };
    return emailVerificationQueryParams;
};

