import * as brandWebsiteTypes from "../ActionTypes/brandWebsiteTypes";

const initialState = {
    previewThemeData: {},
    themeCategories:[],
    themesData:{
        themes: [],
        is_next_page: false,
    },
};

const brandWebsiteReducer = (state = initialState, action: any) => {
    switch (action.type){
        case brandWebsiteTypes.SET_BUSINESS_CATEGORIES:{
            return {
                ...state,
                themeCategories: action?.data || [],
            };
        }
        case brandWebsiteTypes.SET_CATEGORY_THEMES: {
            return {
                ...state,
                themesData: {
                    themes: state.themesData.themes.concat(action.data.themes),
                    is_next_page: action.data.is_next_page,
                },
            };
        }
        case brandWebsiteTypes.EMPTY_CATEGORY_THEMES: {
            return {
                ...state,
                themesData: {
                    themes: [],
                    is_next_page: false,
                },
            };
        }
        case brandWebsiteTypes.SET_PREVIEW_THEME: {
            return {
                ...state,
                previewThemeData: action?.data || {},
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default brandWebsiteReducer;
