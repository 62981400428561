import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { setPageLoaderVisibility, fetchSocialMediaProfiles, updateStoreSocialMediaDetails } from "../Actions";
import apiCall from "./../../Services/api";
import * as storeTypes from "./../ActionTypes";
import config from "./../../Config";

function* addSocialMediaProfile(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/socialMediaProfile`,
      data: actions.data,
    });

    if (response && response.data.status) {
      yield put(fetchSocialMediaProfiles());
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Social Media Profile Updated");
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* getSocialMediaProfiles(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/marketing/socialMediaDetail`,
    });

    if (response && response.data.status) {
      const socialMediaProfileData = response?.data?.social_media_detail;
      yield put(updateStoreSocialMediaDetails(socialMediaProfileData));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err: any) {
    ToastNotifyError("Error loading social media profile details!");
    yield put(setPageLoaderVisibility(false));
  }
}

function* deleteSocialMediaProfile(actions: any): any {
  try {
    const response = yield apiCall({
      method: "DELETE",
      url: `${config.base_url}/dotk/vm1/marketing/deleteSocialMediaProfile`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchSocialMediaProfiles());
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Social Media profile deleted");
    }
  } catch (err: any) {
    ToastNotifyError("Error deleting social media profile");
    yield put(setPageLoaderVisibility(false));
  }
}

function* updateSocialMediaProfile(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/socialMediaProfile`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchSocialMediaProfiles());
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Social media profile edited successfully!");
    }
  } catch (err: any) {
    ToastNotifyError("Error editing social media profile");
    yield put(setPageLoaderVisibility(false));
  }
}

export default function* root() {
  yield takeEvery(storeTypes.ADD_SOCIAL_MEDIA_PROFILE, addSocialMediaProfile);
  yield takeEvery(storeTypes.DELETE_SOCIAL_MEDIA_PROFILE, deleteSocialMediaProfile);
  yield takeEvery(storeTypes.FETCH_SOCIAL_MEDIA_PROFILE, getSocialMediaProfiles);
  yield takeEvery(storeTypes.EDIT_SOCIAL_MEDIA_PROFILE, updateSocialMediaProfile);
}
