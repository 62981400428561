import { ElementType, FC, ReactChild } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/Reducers";
import ThemeExpiryAlertBar from "@/Components/ThemeExpiryAlertBar";
import { showWorkspaceBuytimerBanner, isDomainBannerActive } from "../../../Utils/common";
import { isSubscriptionExpiring } from "../../../Utils/subscription";
import useIsDashy from "../../../Utils/useIsDashy";

interface IPremiumHeader {
  customClassName?: string;
  customStyle?: any;
  children?: ReactChild;
  customComponent?: ElementType<any>;
  noAlertbar?: boolean;
  fullWidth?: boolean;
}

const PremiumHeader: FC<any> = (props: IPremiumHeader) => {
  const classes = useStyles();
  const isDashy = useIsDashy();

  const { storeData, workspaceDetails } = useSelector((state: RootState) => ({
    storeData: state.storeReducer.store,
    workspaceDetails: state.workspaceReducer.workspaceDetails,
  }));

  /**
   * add margin to display workspace buy banner
   */
  const workspaceBuyTimerBanner = () => {
    if (
      isDashy && !props.noAlertbar &&
      (showWorkspaceBuytimerBanner(storeData?.constants?.workspace_domain_upsell_expiry_time) &&
        !workspaceDetails?.workspace_info?.email_count ||
        isDomainBannerActive() ||
        isSubscriptionExpiring(true))
    ) {
      return classes.workspaceTimer;
    }
  };

  return (
    <>
      {!props.noAlertbar && storeData?.theme?.is_demo === 1 && <ThemeExpiryAlertBar fixedBar />}
      <Box
        component={props.customComponent || "header"}
        className={clsx(
          classes.HeaderComponent,
          !props.noAlertbar && storeData?.theme?.is_demo === 1 && classes.classWhenThemeAlertBarPresent,
          props.fullWidth && classes.fullWidth,
          props.customClassName || "",
          "bb b--black-10 bg-white flex items-center fixed right-0",
          !props.fullWidth && "ph4",
          !props.noAlertbar ? workspaceBuyTimerBanner() : "",
        )}
        style={{ ...props.customStyle }}
      >
        <Box component="section" className="flex justify-between items-center w-100">
          {props.children}
        </Box>
      </Box>
    </>
  );
};

export default PremiumHeader;
