import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  HeaderComponent: {
    width: "calc(100% - 240px)",
    height: 0,
    paddingTop: "2.5rem",
    paddingBottom: "2.5rem",
    zIndex: 100,
  },
  fullWidth: {
    width: "100%",
  },
  classWhenThemeAlertBarPresent: {
    top: "2rem",
  },
  workspaceTimer: {
    top: 48,
  },
}));
