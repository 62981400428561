import {
  CATALOG_PRODUCT,
  MARKETING_ROUTE,
  ORDERS_PAGE_ROUTE,
  SETTINGS_ROUTE,
  SUBSCRIPTION_STATUS_DETAILS,
} from "./routesConstant";

export const useOTPLessForWebConsoleSignIn = true;
export const useOTPLessForDLPSignIn = false;
export const FLAT_DISCOUNT_TYPE_CONSTANT = "flat";
export const PERCENTAGE_DISCOUNT_TYPE_CONSTANT = "percentage";
export const CURRENT_CURRENCY_SYMBOL = "₹";
export const GENERIC_COUPON_TYPE_ID = 0;
export const CATEGORY_BASED_COUPON_TYPE_ID = 1;
export const TAG_BASED_COUPON_TYPE_ID = 2;
export const ALL_COUPONS_BUTTON_ID = "all-coupons";
export const GENERIC_COUPONS_BUTTON_ID = "generic-coupons";
export const CATEGORY_TAG_BASED_COUPONS_BUTTON_ID = "category-tag-specific-coupons";
export const CATEGORY_CONSTANT = "category";
export const CATEGORIES_CONSTANT = "categories";
export const TAGS_CONSTANT = "tags";
export const TAG_CONSTANT = "tag";
export const LOGOUT_CONSTANT = "logout";
export const DELETE_STORE_CONSTANT = "delete-store";
export const ORDER_CONSTANT = "order";
export const CAMPAIGN_CONSTANT = "campaign";
export const GST_BILLING_CONSTANT = "gst-billing";
export const EXTRA_CHARGE_COD = "cod_charges";
export const CUSTOMER_REVIEW_RATINGS_CONSTANT = "customer-review-ratings";
export const PAYMENT_MODE_CONSTANT = "payment-mode";
export const PREPAID_ORDER_CONSTANT = "prepaid-order";

export const COUPON_TYPES = [
  {
    id: "general",
    isNew: false,
    label: "Create General Coupon",
    desc: "Create a general coupon applicable on your entire catalog",
  },
  {
    id: "category",
    isNew: true,
    label: "Coupon for Category",
    desc: "Create coupons specific to a category of products",
  },
  {
    id: "tags",
    isNew: true,
    label: "Coupon for Tags",
    desc: "Create coupons for products with specific tags",
  },
];

export const COUPON_LIST_BUTTONS = [
  {
    label: "All Coupons",
    id: ALL_COUPONS_BUTTON_ID,
  },
  {
    label: "Generic Coupons",
    id: GENERIC_COUPONS_BUTTON_ID,
  },
  {
    label: "Category/Tag Specific Coupons",
    id: CATEGORY_TAG_BASED_COUPONS_BUTTON_ID,
  },
];

export const PRO_TIP_PROMO_CODE_SUGGESTIONS = ["LuckyDay", "HappyDaisy", "HotDeal", "AajKiDeal"];

export const YOUTUBE_UPLOAD_LEARN_STEPS_DATA = [
  `<strong><a href=${"https://www.youtube.com/watch?v=6C4dEpT0rYg"} target="__blank">Follow the instructions</a> </strong> to publish your product video on YouTube.`,
  "<b>Copy the video URL</b> of your uploaded Product Video.",
  "<b>Paste the URL</b> in the YouTube Video Link box.",
];

export const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.digitaldukaan";
export const APP_STORE_LINK = "https://apps.apple.com/in/app/id1544439745?mt=8";

export const LAZYPAY_VIEWS: {
  OTP: "otp";
  ELIGIBLE: "eligible";
  NONELIGIBLE: "noneligible";
} = {
  OTP: "otp",
  ELIGIBLE: "eligible",
  NONELIGIBLE: "noneligible",
};

export const STAFF_PERMISSION_MAPPING: any = {
  1: ORDERS_PAGE_ROUTE,
  2: CATALOG_PRODUCT,
  3: SUBSCRIPTION_STATUS_DETAILS,
  4: MARKETING_ROUTE,
  5: SETTINGS_ROUTE,
};

export const USER_ROLE_OWNER_TEXT = "owner";
export const USER_ROLE_ADMIN_TEXT = "admin";
export const USER_ROLE_STAFF_TEXT = "staff";
export const CUSTOM_TAG_COLORS = ["#8A57FF", "#3CADFF", "#FCA2FF", "#13C3A1", "#D98718", "#808080"];

export const INVENTORY_CONSTANTS = {
  MANAGED_INVENTORY: "managed_inventory",
  AVAILABLE_QUANTITY: "available_quantity",
  LOW_QUANTITY: "low_quantity",
  CLEAR_VARIANTS_INVENTORY: "variants-empty",
  INVENTORY_AVAILABLE_LIMIT: 99999,
};

export const WHATSAPP_SHARE_STORE_LINK =
  "https://api.whatsapp.com/send?text=Visit%20my%20online%20store%20and%20place%20your%20orders%20from%20";

export const WHATSAPP_SHARE_COLLECTION_LINK =
  "https://api.whatsapp.com/send?text=Visit%20my%20online%20store%20and%20checkout%20my%20new%20collection%20";

export const PREMIUM_PURCHASE_TYPE_CONSTANTS = {
  GOOGLE_ADDS: "google-ads",
  DOMAIN: "domain",
  DOMAIN_SUBSCRIPTION: "domain-subscription",
  DOMAIN_CONNECT: "domain-connect",
  DOMAIN_MARKETING: "domain-marketing",
  SUSBSCRIPTION: "subscription",
  THEME: "theme",
  WORKSPACE: "workspace",
  CREDIT_ENGINE: "credit_engine",
  SMS_CAMPAIGN: "sms_campaign",
  WABA_PURCHASE: "waba-purchase",
  SUBSCRIPTION_USING_COUPONS: "subscription-using-coupon",
  PURCHASE_BRAND_WEBSITE_THEME: "brand-website-theme",
} as const;

export const CART_CONSTANTS = {
  ABANDONED: 0,
  ACTIVE: 1,
  SUBSCRIBERS: 2,
  PRODUCT_INQUIRY: 3,
};

export const LEAD_CONSTANTS = {
  EMAIL: 1,
  PHONE: 2,
};

export const PAYMENT_TIMELINE = {
  POSTPAID: 0, // pay on delivery / pickup
  PREPAID: 1,
  BOTH: 2, // both prepaid and postpaid
} as const;

export const PAYMENT_METHOD_OPTIONS = {
  UPI: "UPI",
  COD: "Cash on delivery",
  MORE_OPTIONS: "More Payment Options",
} as const;

export const PAYMENT_METHOD_TYPES = {
  AIRTEL: "airtel",
};

export const TRANSACTION_ID = "txnID";

export const DEFAULT_IMAGE_CDN = "https://cdn.dotpe.in/longtail/store-items/default_image.png";
export const DELIVERY_CHARGE_TYPE = {
  UNSET: 0,
  FREE: 1,
  FIXED: 2,
  CUSTOM_CHARGES: 3,
  PINCODE_BASED: 4,
};

export const COD_CHARGE_TYPE = {
  FREE: 0,
  FIXED: 1,
  PERCENTAGE: 2,
};

export const KYC_STATUS = {
  NOT_STARTED: 0,
  INITIATED: 1,
  FAILED: 2,
  COMPLETED: 3,
};

export const KYC_VENDORS = {
  INVOID: 1,
  HYPERVERGE: 2,
};

export const HYPERVERGE_SDK_RESPONSE_STATUS = {
  USER_CANCELLED: "user_cancelled",
  ERROR: "error",
  AUTO_APPROVED: "auto_approved",
  AUTO_DECLINED: "auto_declined",
  NEEDS_REVIEW: "needs_review",
};

export const GST_STATUS = {
  NOT_STARTED: 0,
  INITIATED: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const STORE_CUSTOMIZATION: {
  ORDER_BASED: 0;
  SERVICE_BASED_WITH_ADDRESS_DISABLED: 1;
  SERVICE_BASED_WITH_ADDRESS_ENABLED: 2;
  B2B: 3;
} = {
  ORDER_BASED: 0,
  SERVICE_BASED_WITH_ADDRESS_DISABLED: 1,
  SERVICE_BASED_WITH_ADDRESS_ENABLED: 2,
  B2B: 3,
} as const;

export const SERVICE_THEMES_CATEGORY_ID = 7266;

export const PAYMENT_TYPE_CONSTANTS = {
  CREDIT_CARD: "creditCard",
  DEBIT_CARD: "debitCard",
  CARD: "card",
  PAYTM: "paytm",
  AMEX_CARD: "amexCard",
  GOOGLE_PAY: "googlepay",
  PHONEPE: "phonepe",
  PAYTM_UPI: "paytmupi",
  LAZYPAY: "lazyPay",
  COD: "cod",
  EMI: "cemi",
  AIRTEL: "airtel",
  DUPI: "dupi",
};

export const SEND_REVIEW_LINK = {
  COMM_TYPE_SMS: 1,
  COMM_TYPE_WA: 2,
  COMM_TYPE_EMAIL: 3,
} as const;

export const SUBSCRIPTION_TYPE = {
  THEME_PRO: "theme_plus",
  CUSTOM_THEME: "custom_theme",
  THEME: "premium",
} as const;

export const USER_PLAN_TYPES = {
  GST_PACK: "gst_pack",
} as const;

export const PARTIAL_PAYMENT_INPUT_MODE = {
  PERCENTAGE: "percentage",
  FLAT_AMOUNT: "flatAmount",
};

export const ADV_SEO_ACTION_TYPES = {
  ITEM: "item",
  COLLECTION: "collection",
  CATEGORY: "category",
  ADDITIONAL_PAGES: "additional_page",
};

export const CONTACT_US_WHATSAPP_LINK = "https://wa.me/+917428533884?text=Hello";

export const TRANSACTION_STATUS = {
  PAYOUT_ON_HOLD: 7,
} as const;

export const COLORS = {
  BLACK: "#000000",
  TEXT_DARKER: "#111C36",
  TEXT_DARK: "#111C36E6",
  PARA: "#111C3699",
  BORDER_12: "#111C361F",
  WHITE: "#FFFFFF",
  PRIMARY: "#2B74D8",
  SECONDARY_BG: "#111C360F",
  DANGER: "#E24848",
  SHADOW_INPUT: "#00000014",
  SHADOW_DROPDOWN: "#E9ECF0",
  SHADOW_BUTTON: "#0000001F",
  TRANSPARENT: "transparent",
} as const;
