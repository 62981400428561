import { IAppConstants } from "../Types/reducerTypes/commonTypes";

export const APP_CONSTANTS_DEFAULTS: IAppConstants = {
  workspace: {
    sell_titan_email_free_with_domain: false,
    free_titan_licenses_count: 1,
    titan_emails_mrp: 499,
    titan_webmail_link: "",
    sell_titan_email_free_with_domain_renewal: false,
    free_titan_licenses_count_with_domain_renewal: 1,
    titan_upgrade_days_before_expiry: 15,
  },
  domain: {
    tld_prices: {},
  },
} as const;

export const DOMAIN_CART_PRICES_CONSTANTS = {
  SSL_ENCRYPTION: 499,
  PREMIUM_HOSTING: 499,
} as const;
