export const SETTING_PAGE_OPTIONS = {
  SITEMAP: "Sitemap",
  MY_PAYMENTS: "my-payments",
  STORE_POLICY: "store-policy",
  SUGGESTION: "submit-suggestion",
  MY_INVOICES: "my-invoices",
  HELP: "help",
  CONTACT_US: "conatct-us",
};

export const SETTING_PAGE_SUBROUTES = {
  CONTROLS: "/settings/controls",
  PROFILE: "/settings/profile",
  DELIVERY: "/settings/delivery",
  PAYMENTS: "/settings/payments",
  ORDERS: "/settings/orders",
  CUSTOMER: "/settings/customer",
  STAFF: "/settings/staff",
  STORE_POLICIES: "/settings/store-policies",
  HELP_CENTER: `${process.env.REACT_APP_DIGITALSHOWROOM_URL}help-section`,
  SUGGEST_IDEA: "/submit-suggestion",
  CONTACT_US: "/contact-us",
  OTHERS: "/settings/others",
  QUOTES: "/settings/quotes",
  ABOUT_US: `${process.env.REACT_APP_DIGITALSHOWROOM_URL}about-us`,
  PRIVACY_POLICY: `${process.env.REACT_APP_DIGITALSHOWROOM_URL}privacy-policy`,
  TERMS_CONDITIONS: `${process.env.REACT_APP_DIGITALSHOWROOM_URL}terms-and-conditions`,
};
