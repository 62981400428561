import * as React from "react";
import { Box, Dialog as MuiDialog, DialogContent as MuiDialogContent } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import CloseIcon from "@/Assets/images/cross-icon.svg";

const Dialog = withStyles({
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: 530,
      paddingRight: 4,
      paddingTop: 12,
      paddingBottom: 18,
    },
    "& .MuiPaper-rounded": {
      borderRadius: 8,
    },
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    "& .callback-button": {
      borderRadius: 40,
      backgroundColor: "#000000",
      color: "#ffffff",
      fontSize: 14,
      outline: "none",
      border: "none",
      boxShadow: "none",
      padding: "10px 18px",
    },
  },
})(MuiDialogContent);

const CommonCallbackModal = ({ message, open, onClose, onCallbackClick }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <img src={CloseIcon} className="absolute top-1 right-1 pointer" onClick={onClose} />
        <Box component="p" className="mv0 md-text18">
          {message}
        </Box>
        <Box className="flex justify-end" paddingTop="40px">
          <button className="callback-button flex items-center" onClick={onCallbackClick}>
            <span className="mr1 flex items-center justify-center">
              <PhoneIcon style={{ fontSize: 16, color: "#ffffff" }} />
            </span>
            Request Call
          </button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CommonCallbackModal;
