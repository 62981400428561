import { put, takeEvery } from "redux-saga/effects";
import apiCall from "./../../Services/api";
import * as leadTypes from "./../ActionTypes";
import { setPageLoaderVisibility, setStoreLeadGenTemplates } from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { LEAD_GENERATION_API } from "../../Services/apiUrls";
import { parseToJson } from "../../Utils/common";
// fetch filter cart data
function* setLeadGenTemplate(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: LEAD_GENERATION_API.setLeadGenTemplate,
      data: actions.data,
    });
    if (response?.data?.status) {
      actions && actions.callback();
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch data");
  }
}

function* getStoreActiveLeadGenTemplate(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: LEAD_GENERATION_API.getStoreActiveLeadGenTemplate(actions.data.store_id),
    });
    if (response?.data?.status) {
      yield put(setStoreLeadGenTemplates(parseToJson(response?.data?.data)));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to send notification");
  }
}

export default function* root() {
  yield takeEvery(leadTypes.SET_LEADGEN_TEMPLATE, setLeadGenTemplate);
  yield takeEvery(leadTypes.GET_STORE_ACTIVE_LEADGEN_TEMPLATE, getStoreActiveLeadGenTemplate);
  yield takeEvery(leadTypes.SET_STORE_LEADGEN_TEMPLATES, setStoreLeadGenTemplates);
}
