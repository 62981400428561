import * as actionTypes from "../ActionTypes";

const initialState = {
  marketingTags: {
    facebook_pixel: "",
    g_tag: "",
    gmc_verification: "",
    google_ads: "",
    google_analytics: "",
    store_id: "",
    address_fields_list: [],
  },
  /** This list contains the Merchant Store IDs that have been selected for FB Ads release
   * to monitor their sentiments towards the feature
   * (The first 2 Ids are our Test accounts)
   */
  fbeAdsVisibility: {
    fetchStatus: true,
    showFbAdsCard: false,
  },
};

const MarketingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_MARKETING_TAGS:
      return { ...state, marketingTags: action.data };
    case actionTypes.SET_FBE_ADS_VISIBILITY_STATUS:
      return { ...state, fbeAdsVisibility: { fetchStatus: false, showFbAdsCard: action.data } };
    case actionTypes.SAVE_ADDRESS_LIST:
      return { ...state, marketingTags: { address_fields_list: action.data } };
    default:
      return state;
  }
};

export default MarketingReducer;
