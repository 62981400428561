import { FEATURE_LOCKS_INITIAL_VALUE } from "../../Constants/subscription";
import { transformBEDataToFE } from "../../Utils/common";
import {
  TOGGLE_SUBSCRIPTION_FLOW_MODAL,
  SET_REQUEST_CALLBACK_MODAL,
  SET_SUBSCRIPTION_AMOUNT,
  SAVE_SUBSCRIPTION_CART_SUCCESS_ID,
  SAVE_ADDONS_PURCHASED,
  SET_SUBSCRIPTION_PRICING_LIST,
  TOGGLE_SUBSCRIPTION_EXPIRE_MODAL,
  SAVE_FEATURE_LOCKS,
} from "./../ActionTypes";

const initialState: any = {
  subscriptionFlowModal: {
    modalView: false,
    subscriptionType: null,
    ecomm_type: null,
    featureName: "",
  },
  showRequestCallbackModal: false,
  disableRequestCallbackModalInRoot: false,
  isReferral: false,
  subscriptionCartId: "",
  addonsPurchased: [],
  subscriptionPricingList: {},
  showSubscriptionExpireModal: false,
  featureLocks: FEATURE_LOCKS_INITIAL_VALUE,
};

const subscriptionReducer = (state: any = initialState, action: any): any => {
  switch (action.type) {
    case TOGGLE_SUBSCRIPTION_FLOW_MODAL:
      return {
        ...state,
        subscriptionFlowModal: {
          modalView: action.data.modalView,
          subscriptionType: !action.data.modalView ? null : action.data.subscriptionType,
          ecomm_type: action.data?.ecomm_type ?? null,
          featureName: action.data?.featureName ?? null,
        },
      };
    case SET_REQUEST_CALLBACK_MODAL:
      return {
        ...state,
        showRequestCallbackModal: action.data,
        disableRequestCallbackModalInRoot: action.disableRequestCallbackModalInRoot,
      };
    case SET_SUBSCRIPTION_AMOUNT:
      return {
        ...state,
      };
    case SAVE_SUBSCRIPTION_CART_SUCCESS_ID:
      return {
        ...state,
        subscriptionCartId: action.data,
      };
    case SAVE_ADDONS_PURCHASED:
      return {
        ...state,
        addonsPurchased: action.data,
      };
    case SET_SUBSCRIPTION_PRICING_LIST:
      return {
        ...state,
        subscriptionPricingList: action.data,
      };
    case SAVE_FEATURE_LOCKS:
      return {
        ...state,
        featureLocks: transformBEDataToFE(action.data),
      };
    case TOGGLE_SUBSCRIPTION_EXPIRE_MODAL:
      return {
        ...state,
        showSubscriptionExpireModal: action.data ?? !state.showSubscriptionExpireModal,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
