import {
  SET_EXIT_GATING_ANALYTICS,
  SET_EXIT_GATING_DATA,
  SET_EXIT_GATING_EDITOR_CONFIG,
} from "../ActionTypes";

const initialState = {
  exitGatingData: {
    showGetStartedScreen: null,
    status: null,
    selectedTemplate: null,
    imagePath: null,
    minUserTimeSpent: null,
    perDayPopUpLimit: null,
    offerSelected: null,
    couponData: null,
  },
  analyticsData: null,
  editorConfig: {
    isAnyChange: false,
    selectedTemplate: null,
    imagePath: null,
    imageFileName: null,
    minUserTimeSpent: null,
    perDayPopUpLimit: null,
    offerSelected: null,
    couponData: null,
  },
};

const exitGatingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_EXIT_GATING_DATA: {
      return {
        ...state,
        exitGatingData: action.data || null,
      };
    }
    case SET_EXIT_GATING_ANALYTICS: {
      return {
        ...state,
        analyticsData: action.data || null,
      };
    }
    case SET_EXIT_GATING_EDITOR_CONFIG: {
      return {
        ...state,
        editorConfig: {
          ...state.editorConfig,
          ...action.data,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default exitGatingReducer;
