/**
 * 0 -> d-{temporary, others}
 * 1,2 -> normal domain urls
 */
export const DOMAIN_TYPE = {
  TEMPORARY: 0,
  PURCHASED: 1,
  EXTERNAL: 2,
} as const;

/**
 * store.domain_info values.
 * whether domain is active/inactive
 */
export const STORE_DOMAIN_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
} as const;

export const URL_REGEX_DOMAIN = new RegExp(
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
);

/**
 * All statuses used for domain are defined here.
 * refer this for more details - "https://dotpe.atlassian.net/wiki/spaces/DS/pages/302514177/Domain+related+DB+information"
 */
export const DOMAIN_STATUS = {
  PAYMENT_RECEIVED: 3,
  PURCHASE_SUCCESSFULL_EXTERNAL_REGISTRAR: 4,
  SSL_CERTIFICATE_GENERATED: 5,
  DOMAIN_ACTIVE: 6,
  ISSUE_WHILE_PURCHASE_FROM_REGISTRAR: 7,
  DOMAIN_EXPIRED: 9,
  DOMAIN_BLOCKED_AS_STORE_CLOSED: 10,
  DOMAIN_OUT_OF_GRACE_PERIOD: 11,
  DOMAIN_IN_QUEUE: 12,
  DOMAIN_STUCK_IN_QUEUE: 13,
  DOMAIN_BLOCKED: 14,
  DNS_RECORD_MISSING_EXTERNAL_DOMAIN: 15,
} as const;

export const DOMAIN_STATUSES_UNDER_PROCESS = [
  DOMAIN_STATUS.PAYMENT_RECEIVED,
  DOMAIN_STATUS.PURCHASE_SUCCESSFULL_EXTERNAL_REGISTRAR,
  DOMAIN_STATUS.SSL_CERTIFICATE_GENERATED,
  DOMAIN_STATUS.ISSUE_WHILE_PURCHASE_FROM_REGISTRAR,
  DOMAIN_STATUS.DOMAIN_IN_QUEUE,
  DOMAIN_STATUS.DOMAIN_STUCK_IN_QUEUE,
];

export const SUBSCRIPTION_DOMAIN_STATUS = {
  STATUS_DOMAIN_PURCHASED_FROM_REGISTRAR: 4,
  STATUS_SSL_CERTI_GENERATED: 5,
  STATUS_UNABLE_TO_PURCHASE_FROM_REGISTRAR: 7,
  STATUS_ALREADY_IN_QUEUE: 12,
} as const;

export const DAYS_BEFORE_EXPIRATION_WARNING = {
  TEMPORARY_DOMAIN: 7,
};
