import { put, takeEvery } from "redux-saga/effects";
import * as workspaceActions from "../ActionTypes";
import apiCall from "../../Services/api";
import {
  setPageLoaderVisibility,
  savePrePaymentData,
  saveWorkspaceDetails,
  saveWorkspaceEmailUserById,
  saveWorkspacePriceDetails,
  saveWorkspaceRenewalDetails,
} from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";
import history from "../../Utils/history";
import { parseToJson } from "../../Utils/common";
import { WORKSPACE_API } from "../../Services/apiUrls";
import { setPremiumPurchaseType, setPrePaymentDataToSessionStorage, appendCreditUsedKey } from "../../Utils/_helper";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import { PAYMENT } from "../../Constants/routesConstant";

const ERROR_MSG = {
  CANNOT_PURCHASE_EMAILS: "Cannot purchase emails currently. Please try again later.",
  WRKS_INFO_NOT_FOUND: "Workspace information not found. Please try again later.",
  EMAIL_EXIST: "EmailID already exists",
  WRKS_PRICE_INFO_NOT_FOUND: "Workspace price information not found. Please try again later.",
  CANNOT_RENEW_EMAILS: "Cannot renew emails currently. Please try again later.",
  TITAN_ACTIVATION_EMAIL_NOT_FOUND: "Cannot fetch titan manage link. Please try again later",
  UNABLE_TO_ACTIVATE_TITAN_EMAIL: "We were unable to activate your titan account. Please try again later.",
};

function* initiateWorkspacePayment(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const paymentResponse = yield apiCall({
      method: "POST",
      url: WORKSPACE_API.initiateWorkspacePurchase,
      data: appendCreditUsedKey(actions.data),
    });

    if (paymentResponse?.data?.status) {
      const parsedData = parseToJson(paymentResponse.data.data);
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.WORKSPACE);
      setPrePaymentDataToSessionStorage(JSON.stringify(parsedData));
      yield put(savePrePaymentData(parsedData));
      actions?.callback?.(paymentResponse?.data?.status);
      history.push(PAYMENT);
    } else {
      actions?.callback?.(false);
      ToastNotifyError(ERROR_MSG.CANNOT_PURCHASE_EMAILS);
    }
  } catch (err: unknown) {
    actions?.callback?.(false);
    ToastNotifyError(ERROR_MSG.CANNOT_PURCHASE_EMAILS);
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

/**
 * Generator function to fetch workspace data
 * @param actions
 */
function* getWorkspaceDetails(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: WORKSPACE_API.getWorkspaceDetails(actions.data.domainName),
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveWorkspaceDetails(parsedData));
      actions?.callback?.(true, parsedData?.workspace_user_list);
    }
  } catch (err) {
    !actions?.noErrorMsg ? ToastNotifyError(ERROR_MSG.WRKS_INFO_NOT_FOUND) : false;
    actions?.callback?.(false, []);
  }
}

/**
 * Generator function to create email and user
 * @param actions
 */
function* provisionAndAddUserMail(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: WORKSPACE_API.provisionAndAddUserMail,
      data: actions.data,
    });
    if (!response?.data?.status) {
      if (response?.data?.error_type === "data_already_exists_error") {
        ToastNotifyError(ERROR_MSG.EMAIL_EXIST);
      }
    }
    actions?.callback?.(response?.data?.status);
  } catch (err: any) {
    actions?.callback?.(false);
  }
}

/**
 * Generator function to fetch individual user data
 * @param actions
 */
function* getWorkspaceEmailUserById(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: WORKSPACE_API.getWorkspaceEmailUser(actions.data),
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveWorkspaceEmailUserById(parsedData));
    }
    actions?.callback?.(response?.data?.status);
  } catch (err: any) {
    actions?.callback?.(false);
  }
}

/**
 * Generator function to fetch workspace data
 * @param actions
 */
function* getWorkspacePriceDetails(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: WORKSPACE_API.workspacePriceDetails(actions.data),
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveWorkspacePriceDetails(parsedData));
      actions?.callback?.(response?.data?.status);
    }
  } catch (err) {
    ToastNotifyError(ERROR_MSG.WRKS_PRICE_INFO_NOT_FOUND);
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getWorkspaceRenewalDetails(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: WORKSPACE_API.workspaceRenewalDetails(actions.data),
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveWorkspaceRenewalDetails(parsedData));
      actions?.callback?.(true);
    }
  } catch (err) {
    console.log(err);
    actions?.callback?.(false);
  }
}

function* initiateWorkspaceRenewal(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: WORKSPACE_API.initiateWorkspaceRenewal,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.WORKSPACE);
      setPrePaymentDataToSessionStorage(JSON.stringify(parsedData));
      yield put(savePrePaymentData(parsedData));
      actions?.callback?.(response?.data?.status);
      history.push(PAYMENT);
    } else {
      ToastNotifyError(ERROR_MSG.CANNOT_RENEW_EMAILS);
      actions?.callback?.(false);
    }
  } catch (err) {
    console.log(err);
    ToastNotifyError(ERROR_MSG.CANNOT_RENEW_EMAILS);
    actions?.callback?.(false);
  }
}

function* getTitanWorkspaceManageURL(actions: any): any {
  try {
    const response = yield apiCall({
      url: WORKSPACE_API.getTitanWorkspaceManageURL(actions.data),
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback(response.data.data);
    } else {
      ToastNotifyError(ERROR_MSG.TITAN_ACTIVATION_EMAIL_NOT_FOUND);
    }
  } catch (err) {
    console.log(err);
    ToastNotifyError(ERROR_MSG.TITAN_ACTIVATION_EMAIL_NOT_FOUND);
  }
}

function* activateTitanWorkspace(actions: any): any {
  try {
    const response = yield apiCall({
      url: WORKSPACE_API.ACTIVATE_TITAN_EMAIL,
      method: "POST",
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback(true);
    } else {
      ToastNotifyError(ERROR_MSG.UNABLE_TO_ACTIVATE_TITAN_EMAIL);
      actions.callback(false);
    }
  } catch (err) {
    console.log(err);
    actions.callback(false);
    ToastNotifyError(ERROR_MSG.UNABLE_TO_ACTIVATE_TITAN_EMAIL);
  }
}

export default function* root() {
  yield takeEvery(workspaceActions.INITIATE_WORKSPACE_PAYMENT, initiateWorkspacePayment);
  yield takeEvery(workspaceActions.GET_WORKSPACE_DETAILS, getWorkspaceDetails);
  yield takeEvery(workspaceActions.PROVISION_ADD_USER_EMAIL, provisionAndAddUserMail);
  yield takeEvery(workspaceActions.GET_WORKSPACE_EMAIL_USER_BY_ID, getWorkspaceEmailUserById);
  yield takeEvery(workspaceActions.GET_WORKSPACE_PRICE_DETAILS, getWorkspacePriceDetails);
  yield takeEvery(workspaceActions.GET_WORKSPACE_RENEWAL_DETAILS, getWorkspaceRenewalDetails);
  yield takeEvery(workspaceActions.INITIATE_WORKSPACE_RENEWAL, initiateWorkspaceRenewal);
  yield takeEvery(workspaceActions.GET_TITAN_WORKSPACE_MANAGE_URL, getTitanWorkspaceManageURL);
  yield takeEvery(workspaceActions.ACTIVATE_WORKSPACE_MANAGE_URL, activateTitanWorkspace);
}
