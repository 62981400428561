import {
  ADD_SOCIAL_MEDIA_PROFILE,
  FETCH_SOCIAL_MEDIA_PROFILE,
  EDIT_SOCIAL_MEDIA_PROFILE,
  DELETE_SOCIAL_MEDIA_PROFILE,
} from "./../ActionTypes";
interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const addSocialMediaProfile = (data: any): Action => {
  return {
    type: ADD_SOCIAL_MEDIA_PROFILE,
    data,
  };
};

export const updateSocialMediaProfile = (data: any): Action => {
  return {
    type: EDIT_SOCIAL_MEDIA_PROFILE,
    data,
  };
};

export const deleteSocialMediaProfile = (data: any): Action => {
  return {
    type: DELETE_SOCIAL_MEDIA_PROFILE,
    data,
  };
};

export const fetchSocialMediaProfiles = (): Action => {
  return {
    type: FETCH_SOCIAL_MEDIA_PROFILE,
  };
};
