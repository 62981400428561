import { useEffect } from "react";
import { JSONObject } from "../Redux/Store/type";

export const MOBILE_EVENT_TARGET = {
  BOTH: 0,
  ANDROID: 1,
  IOS: 2,
};

export const goBackWebview = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  if (win.Android) {
    win.Android.onNativeBackPress();
  }
  if (win.ReactNativeWebView) {
    win.ReactNativeWebView.postMessage(JSON.stringify({ goBack: true }));
  }
};

export const sendMessageWebview = (data: JSONObject, target = MOBILE_EVENT_TARGET.BOTH): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  let jsonData: string | null = null;
  try {
    jsonData = JSON.stringify(data);
  } catch (error) {
    jsonData = null;
  }
  if (win.Android && [MOBILE_EVENT_TARGET.BOTH, MOBILE_EVENT_TARGET.ANDROID].includes(target)) {
    win.Android.sendData(jsonData);
  }
  if (win.ReactNativeWebView && [MOBILE_EVENT_TARGET.BOTH, MOBILE_EVENT_TARGET.IOS].includes(target)) {
    win.ReactNativeWebView.postMessage(jsonData);
  }
};

interface EventData extends Event {
  type: string;
  data?: string;
}

type MessageListenerHandler<MessageType> = (message: MessageType, type?: string) => void;

const useMessageListenerWebview = <MessageType extends JSONObject = JSONObject>(
  messageHandler: MessageListenerHandler<MessageType>,
): void => {
  function onMessageReceived(message: EventData) {
    let messageJson: MessageType;
    try {
      messageJson = JSON.parse(message.data || "null");
    } catch {
      messageJson = JSON.parse("null");
    }
    messageHandler && messageHandler(messageJson, message.type);
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win = window as any;
    if (win.Android || win.ReactNativeWebView) {
      window.addEventListener("message", onMessageReceived);
      return () => {
        window.removeEventListener("message", onMessageReceived);
      };
    }
  }, []);
};

export default useMessageListenerWebview;
