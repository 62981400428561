let internalCookies: Record<string, string> = {};
export const populateCookies = (cookies: Record<string, string> = {}): void => {
  internalCookies = cookies;
};

export const setCookie = (cookieName: string, cookieValue: string, expiryDays: number): void => {
  const d = new Date();
  d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

export const getCookie = (cookieName: string, fromCookie?: string): string => {
  const name = `${cookieName}=`;
  let decodedCookie = fromCookie || "";
  if (typeof document !== "undefined") {
    decodedCookie = decodeURIComponent(document.cookie);
  }
  if (!decodedCookie) {
    decodedCookie = internalCookies[cookieName];
  }
  if (decodedCookie) {
    const cookies = decodedCookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const deleteCookie = (cname: string): void => {
  const d = new Date();
  d.setTime(d.getTime() - 1);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=;${expires};path=/`;
};
