import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  CTAButton: {
    background: "0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 24,
    opacity: 1,
    color: "#ffffff",
    fontWeight: 600,
    fontSize: 17,
    cursor: "pointer",
    border: "0.4px solid #000000",
    overflow: "hidden",
    outline: "none",
    textDecoration: "none",
  },
  disabled: {},
}));
