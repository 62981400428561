import { cancel, put, take, takeEvery, fork } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import {
  saveSearchImage,
  saveSuggestiveCategories,
  setItemModState,
  setPageLoaderVisibility,
  saveRecentlyCreatedVariants,
  saveCatalogItemsByBasicDetails,
  fetchCatalogItemsByBasicDetails,
  saveClickedCategoryItemsOfCollection,
  saveCatalogItemsByBasicDetailsForRearrange,
  saveTagItemsByBasicDetailsForRearrange,
  saveSuggestiveVariants,
  fetchCategories,
  setActiveCategoryId,
  setActiveTagId,
  saveSearchedData,
  setCatalogItemSearchText,
  saveCatalogForCreateBillRender,
  saveDetailedItemById,
  setVariantModalVisibility,
  setAddProductModalVisible,
  addToCart,
  saveSearcheditems,
  getStaffMemberDetails,
  saveFetchedCollections,
  fetchCollections,
  saveBrandNames,
  saveCustomerReviewSettings,
  saveReviewsByStore,
} from "./../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { parseToJson } from "../../Utils/common";
import store from "./../Store";
import { CATALOG_PAGE_API, CUSTOMER_REVIEW_RATINGS } from "../../Services/apiUrls";
import { saveSortFilters } from "../Actions/catalog";

// for product stock on/off
function* toggleItemAvailability(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/products/updateStock`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const { show_pop_up = false, subscription_type = "" } = response?.data?.item;
      if (show_pop_up) {
        actions?.additionalData?.showSubscriptionPurchaseModal &&
          actions?.additionalData?.showSubscriptionPurchaseModal(subscription_type);
      } else {
        const reduxStore = store.getState();
        const storeData = reduxStore.storeReducer.store;
        const activeCategoryId = reduxStore.catalogReducer.activeCategoryId;
        if (actions?.callback) {
          actions?.callback(actions.data);
        } else if (activeCategoryId || activeCategoryId === 0) {
          yield put(
            fetchCatalogItemsByBasicDetails({
              store_id: storeData.store_id,
              category_id: activeCategoryId,
            }),
          );
        }
      }
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err.messages);
  }
}

// Save Item to the catalog / Update Item
function* saveItem(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: CATALOG_PAGE_API.setItemApi,
    });
    if (response && response.data.status) {
      const data = parseToJson(response.data.data);
      if (actions.callback) {
        actions.callback(data);
      }
      yield put(setCatalogItemSearchText(""));
      const storeData = store.getState().storeReducer.store;
      const activeCategoryId = store.getState().catalogReducer.activeCategoryId;
      yield put(fetchCategories(storeData.store_id));
      yield put(setItemModState({ show: false, mode: "", item: {} }));
      yield put(getStaffMemberDetails({ phone: storeData?.display_phone, storeId: storeData?.store_id }));
      if (activeCategoryId || activeCategoryId === 0) {
        yield put(
          fetchCatalogItemsByBasicDetails({
            store_id: storeData.store_id,
            category_id: activeCategoryId,
          }),
        );
      }
      ToastNotifySuccess("Successfully Updated!");
      yield put(setPageLoaderVisibility(false));
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err.data?.message);
  }
}

// Save collection
function* saveCollection(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: CATALOG_PAGE_API.setCollectionApi,
    });
    if (response && response.data.status) {
      yield put(setCatalogItemSearchText(""));
      const storeData = store.getState().storeReducer.store;
      yield put(fetchCollections(storeData.store_id));
      yield put(getStaffMemberDetails({ phone: storeData?.display_phone, storeId: storeData?.store_id }));
      if (actions.data.id) {
        ToastNotifySuccess("Collection Updated Successfully");
      } else {
        ToastNotifySuccess("Collection Created Successfully");
      }
    } else {
      ToastNotifyError(response.message);
    }
    actions?.callback && actions?.callback(response.data.status);
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err.data?.message);
  }
}

// save Other collection
function* saveOtherCollection(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: CATALOG_PAGE_API.setOtherCollectionApi,
    });
    if (response?.data?.status) {
      const storeData = store.getState().storeReducer.store;
      yield put(fetchCollections(storeData.store_id));
      ToastNotifySuccess("Collection Updated Successfully");
    } else {
      ToastNotifyError("Cannot Update Collection. Try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifySuccess("Collection Updated Successfully");
  }
}

// Update collection status
function* updateCollectionStatus(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PATCH",
      data: actions.data,
      url: CATALOG_PAGE_API.updateCollectionStatusApi,
    });
    if (response?.data?.status) {
      actions?.callback?.();
      ToastNotifySuccess(response?.data?.message);
    } else {
      ToastNotifyError("Cannot Update Collection. Try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Cannot Update Collection. Try again later");
  }
}

// for fetching all collections with their respective categories
function* fetchCatalogueCollections(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CATALOG_PAGE_API.getCollectionsApi}/${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveFetchedCollections(parseToJson(response.data.data) || []));
    } else {
      yield put(saveFetchedCollections([]));
      !actions?.additionalData?.hideErrorMessage && ToastNotifyError("Unable to fetch Suggestive Collections !");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    !actions?.additionalData?.hideErrorMessage && ToastNotifyError(err?.data?.message);
    yield put(saveFetchedCollections([]));
    yield put(setPageLoaderVisibility(false));
  }
}

// for fetching all Items inside the Clicked category of Collection
function* fetchItemsInsideClickedCategoryOfCollections(actions: any) {
  const { store_id, collection_id, category_id } = actions.data;
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CATALOG_PAGE_API.getItemsInsideClickedCategoryOfCollectionsApi}?store_id=${store_id}&collection_id=${collection_id}&category_id=${category_id}`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(saveClickedCategoryItemsOfCollection(response.data.data || []));
      actions.callback && actions.callback();
    } else {
      yield put(saveClickedCategoryItemsOfCollection([]));
      ToastNotifyError("Unable to fetch Clicked Category Items!");
    }
  } catch (err) {
    ToastNotifyError(err.data?.message);
    yield put(saveClickedCategoryItemsOfCollection([]));
  }
}

// for fetching all catalogue categories
function* fetchCatalogueCategories(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CATALOG_PAGE_API.getCategoriesApi}/${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveSuggestiveCategories(response.data));
    } else {
      ToastNotifyError("Unable to fetch Suggestive Categories !");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError(err.data?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

// for product delete
function* deleteProductItem(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const data = { store_item_id: actions.data.store_item_id, store_id: actions.data.store_id };
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/catalog/deleteItem`,
      data: data,
    });
    if (response && response.data.status) {
      const storeData = store.getState().storeReducer.store;
      const { activeCategoryId, catalog_items } = store.getState().catalogReducer;
      if (catalog_items?.length === 1 || (actions.data?.tags && actions.data.tags.length > 0)) {
        yield put(fetchCategories(storeData.store_id));
      } else if (activeCategoryId || activeCategoryId === 0) {
        yield put(
          fetchCatalogItemsByBasicDetails({
            store_id: storeData.store_id,
            category_id: activeCategoryId,
          }),
        );
        yield put(getStaffMemberDetails({ phone: storeData?.display_phone, storeId: storeData?.store_id }));
      }

      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Item successfully deleted!");
      actions?.callback?.(true);
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Not able to delete the product!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to delete item currently. Please try again later");
  }
}

// for category delete
function* deleteCategory(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/catalog/deleteCategory`,
      data: actions.data,
    });
    if (response.data.status) {
      const storeData = store.getState().storeReducer.store;
      yield put(fetchCategories(storeData.store_id));
      ToastNotifySuccess("Category deleted successfully!");
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Unable to delete category");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to delete category");
  }
}

// for category Update
function* updateCategory(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/products/updateCategory`,
      data: actions.data,
    });
    if (response.data.status) {
      const storeData = store.getState().storeReducer.store;
      yield put(fetchCategories(storeData.store_id));
      actions?.callback && actions?.callback();
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Unable to Update Category!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to Update Category!");
  }
}

// for image upload
function* uploadImage(actions: any) {
  try {
    // yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/products/addItemImage`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data.status) {
      if (actions.callback) {
        // yield put(setPageLoaderVisibility(false));
        ToastNotifySuccess("Image uploaded");
        actions.callback(response.data.data);
      }
    } else {
      // yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.data.data);
    }
  } catch (err) {
    console.log("uploadImage saga", err);
    // yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to upload Image!");
  }
}

// for image search
function* searchImage(actions: any): Generator<any, any, any> {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/products/searchImages?search_text=${actions.searchTxt}&store_id=${actions.storeid}`,
    });
    if (response.data.status) {
      yield put(saveSearchImage(response.data.images));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Cannot search for desired images");
  }
}

function* saveReorderdCategories(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const reorderResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/products/updateCategoriesRank`,
      data: actions.data,
    });
    if (reorderResponse && reorderResponse.data.status) {
      yield put(setPageLoaderVisibility(false));
      if (actions.callback) {
        actions.callback();
      }
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to reorder, try later!");
  }
}

function* saveReorderdTag(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const reorderResponse = yield apiCall({
      method: "POST",
      url: `${CATALOG_PAGE_API.updateTagsRank}`,
      data: actions.data,
    });
    if (reorderResponse && reorderResponse.data.status) {
      yield put(setPageLoaderVisibility(false));
      if (actions.callback) {
        actions.callback();
      }
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to reorder, try later!");
  }
}

// for single item data
function* fetchIndividualItemData(actions: any): any {
  yield put(setPageLoaderVisibility(true));
  const regExp = /[a-zA-Z]/g; // to check only for characters
  let itemID = actions.data.itemId;
  if (regExp.test(actions.data.itemId)) {
    itemID = "web-console";
  }
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${CATALOG_PAGE_API.getItemByIdApi}?store_id=${actions.data.storeId}&item_id=${itemID}`,
      parseToJson: true,
    });
    if (response && response?.data?.data) {
      actions.callback && actions.callback(response?.data?.data);
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

// Save Item to the catalog / Update Item without closing modal
function* updateCatalogData(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + "/dotk/vm1/products/setItem",
    });

    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      if (actions && actions?.callback) {
        actions.callback();
      }
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err.message);
  }
}

// func to fetch recently created variants
function* fetchRecentlyCreatedVariants(): any {
  try {
    const storeData = store.getState().storeReducer.store;
    const response = yield apiCall({
      method: "GET",
      url: config.base_url + `/dotk/catalog/getRecentVariants/${storeData.store_id}`,
    });
    if (response && response.data.status) {
      if (response.data.recent_variants && response.data.recent_variants.length > 0) {
        yield put(saveRecentlyCreatedVariants(response.data.recent_variants));
      } else {
        yield put(saveRecentlyCreatedVariants([]));
      }
    }
  } catch (err) {
    yield put(saveRecentlyCreatedVariants([]));
  }
}

// func to fetch variant suggestions
// WARN: code not in use. Need to decide what to do after discussion
function* fetchSuggestiveVariants(): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: config.base_url + `/dotk/catalog/getAllMasterVariants`,
    });
    if (response && response.data.status) {
      if (response.data.master_variants && response.data.master_variants.length > 0) {
        yield put(saveSuggestiveVariants(response.data.master_variants));
      } else {
        yield put(saveSuggestiveVariants([]));
      }
    }
  } catch (err) {
    yield put(saveSuggestiveVariants([]));
  }
}

// func to save reordered items
function* saveReorderedItems(actions: any) {
  try {
    const reorderResponse = yield apiCall({
      method: "POST",
      url: CATALOG_PAGE_API.updateItemsRanking,
      data: actions.data,
    });
    if (reorderResponse && reorderResponse.data.status) {
      if (actions.callback) {
        actions.callback();
      }
    }
  } catch (err) {
    ToastNotifyError("Unable to reorder items, try later!");
  }
}

// func to save reordered items
function* saveReorderedTagItems(actions: any) {
  try {
    const reorderResponse = yield apiCall({
      method: "POST",
      url: CATALOG_PAGE_API.updateItemTagsRanking,
      data: actions.data,
    });
    if (reorderResponse && reorderResponse.data.status) {
      if (actions.callback) {
        actions.callback();
      }
    }
  } catch (err) {
    ToastNotifyError("Unable to reorder items, try later!");
  }
}

// get catalog items based on category id
function* fetchCatalogItemsBasicDetailsByCategoryId({ data, callback, controller, clickedCategoryID }: any) {
  try {
    const { forCreateBill = false, forRearrange = false } = data;
    yield put(setPageLoaderVisibility(true));
    const config: any = {
      method: "GET",
      url: `/dotk/catalog/getItemsBasicDetails/${data.store_id}?category_id=${data.category_id}`,
    };
    if (controller?.signal) {
      config.signal = controller.signal;
    }
    const catalogResponse = yield apiCall(config);
    if (catalogResponse && catalogResponse.data.status) {
      yield put(setPageLoaderVisibility(false));
      if (callback) {
        callback(catalogResponse.data.items);
      }
      if (forCreateBill) {
        yield put(
          saveCatalogForCreateBillRender({
            categoryId: data.category_id,
            items: catalogResponse.data.items || [],
          }),
        );
      } else if (forRearrange) {
        yield put(saveCatalogItemsByBasicDetailsForRearrange(catalogResponse.data.items));
      } else {
        if (!clickedCategoryID || clickedCategoryID === store.getState().catalogReducer.activeCategoryId) {
          yield put(saveCatalogItemsByBasicDetails(catalogResponse.data.items));
        }
      }
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(setPageLoaderVisibility(false));
  }
}

function* fetchCatalogItemById(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const regExp = /[a-zA-Z]/g; // to check only for characters
    let itemID = actions.data.item_id;
    if (regExp.test(actions.data.item_id)) {
      itemID = "web-console";
    }
    const response = yield apiCall({
      method: "GET",
      url: `${CATALOG_PAGE_API.getItemByIdApi}?store_id=${actions.data.store_id}&item_id=${itemID}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(setPageLoaderVisibility(false));
      if (actions.isVariantData) {
        yield put(saveDetailedItemById(response?.data?.data));
        yield put(setVariantModalVisibility(true));
      }
      actions?.callback && actions?.callback(response?.data?.data);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    actions.callback && actions.callback(false);
    ToastNotifyError("Unable to get data, try later!");
  }
}

// func to fetch items based on search data
function* fetchSearchData(actions: any): any {
  try {
    const storeData = store.getState().storeReducer.store;
    const searchedItemData = store.getState().catalogReducer.searchedItemData;
    const payload = {
      store_id: storeData.store_id,
      page: searchedItemData.page || 1,
      search_text: actions.data.searchText,
    };
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/catalog/searchItems`,
      data: JSON.stringify(payload),
    });
    if (response && response.data.status) {
      const data = {
        items: response.data.items.length > 0 ? response.data.items : [],
        next: response.data.next,
        page: response.data.next ? searchedItemData.page + 1 : 1,
      };
      yield put(setActiveCategoryId(null));
      yield put(setActiveTagId(null));
      yield put(saveSearchedData(data));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(saveSearchedData([]));
    yield put(setActiveCategoryId(null));
    yield put(setActiveTagId(null));
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to search items of keyword!");
  }
}

function* getItemsByTagId(actions: any) {
  try {
    const { forRearrange = false, id } = actions.data;
    yield put(setPageLoaderVisibility(true));
    const storeData = store.getState().storeReducer.store;
    const apiResponse = yield apiCall({
      method: "GET",
      url: `${CATALOG_PAGE_API.getItemsBasicDetails}/${storeData.store_id}?category_type=1&category_id=${id}`,
    });
    if (apiResponse && apiResponse.data.status) {
      yield put(setPageLoaderVisibility(false));

      if (forRearrange) {
        yield put(saveTagItemsByBasicDetailsForRearrange(apiResponse.data.items));
      } else {
        yield put(saveCatalogItemsByBasicDetails(apiResponse.data.items));
        yield setActiveTagId(id);
        yield setActiveCategoryId(null);
      }
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* setCatalogItem(action: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const apiResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/catalog/setItem`,
      data: action.data,
    });
    const storeData = store.getState().storeReducer.store;
    if (apiResponse && apiResponse.data.status) {
      const cartData = store.getState().cartReducer.data;
      yield put(setAddProductModalVisible(false));
      yield put(setPageLoaderVisibility(false));
      const item = {
        item_name: apiResponse.data.item.name,
        name: apiResponse.data.item.name,
        item_quantity: 1,
        discounted_price: apiResponse.data.item.discounted_price,
        item_price: apiResponse.data.item.price,
        price: apiResponse.data.item.price,
        id: apiResponse.data.item.id,
        item_type: "catalog",
      };

      cartData.items.push(item);
      yield put(addToCart({ ...cartData, items: [...cartData.items] }));

      if (action?.callback) {
        action.callback();
      }

      yield put(getStaffMemberDetails({ phone: storeData?.display_phone, storeId: storeData?.store_id }));

      ToastNotifySuccess("Added Successfully!");
    } else {
      ToastNotifySuccess(apiResponse.data.message);
      yield put(setPageLoaderVisibility(false));
    }
  } catch (error: any) {
    ToastNotifyError(error?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* fetchSearchedItems(actions: any) {
  try {
    const searchPageNo = store.getState().catalogReducer.searchedInfo.searchPageNo;
    const payload = {
      store_id: Number(actions.data.storeId),
      search_text: actions.data.searchText,
      page: searchPageNo,
    };
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/catalog/searchItems`,
      data: payload,
    });
    if (response && response.data.status) {
      const result = {
        items: response.data.items,
        next: response.data.next,
        searchText: actions.data.searchText,
      };
      yield put(
        saveSearcheditems(result),
      );
      actions?.callback?.(result);
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch items, try later!");
  }
}

function* updateTag(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: CATALOG_PAGE_API.updateTagApi,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      if (actions && actions?.callback) {
        actions.callback();
      }
      ToastNotifySuccess("Tags Updated Successfully!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data?.message);
  }
}

// for product delete from tags list
function* removeItemfromTag(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: CATALOG_PAGE_API.removeItemsfromTagApi,
      data: actions.data,
    });
    if (response && response.data.status) {
      const storeData = store.getState().storeReducer.store;
      const { catalog_items } = store.getState().catalogReducer;
      if (catalog_items?.length === 1) {
        yield put(fetchCategories(storeData.store_id));
      } else if (actions && actions?.callback) {
        actions.callback();
      }
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Item successfully deleted!");
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Not able to delete the product!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to delete item currently. Please try again later");
  }
}

function* quickUpdateItem(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const storeData = store.getState().storeReducer.store;
    const activeCategoryId = store.getState().catalogReducer.activeCategoryId;
    const apiResponse = yield apiCall({
      method: "POST",
      url: `${CATALOG_PAGE_API.quickUpdateItemApi}`,
      data: actions.data,
    });
    if (apiResponse && apiResponse.data.status) {
      yield put(setPageLoaderVisibility(false));
      if (actions?.callback) {
        actions.callback();
      } else if (activeCategoryId || activeCategoryId === 0) {
        yield put(
          fetchCatalogItemsByBasicDetails({
            store_id: storeData.store_id,
            category_id: activeCategoryId,
          }),
        );
      }
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* uploadCollectionOrder(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PATCH",
      url: CATALOG_PAGE_API.collectionRank,
      data: actions.data,
    });
    if (response?.data?.status) {
      actions?.callback?.();
    } else {
      ToastNotifyError("Cannot rearrange collection order. Try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError("Cannot rearrange collection order. Try again later");
    yield put(setPageLoaderVisibility(false));
  }
}

function* getBrandNames(): any {
  try {
    const storeId = store.getState().storeReducer.store.store_id;
    const response = yield apiCall({
      method: "GET",
      url: CATALOG_PAGE_API.getBrandNames(storeId),
      parseToJson: true,
    });

    if (response?.data?.status) {
      yield put(saveBrandNames(response?.data?.data || []));
    }
  } catch (err) {
    console.log(err);
  }
}

function* saveItemV3(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: CATALOG_PAGE_API.setItemV3,
      parseToJson: true,
    });
    if (response?.data?.status) {
      const { show_pop_up = false, subscription_type = "" } = response?.data?.data;
      if (show_pop_up) {
        actions?.additionalData?.showSubscriptionPurchaseModal &&
          actions?.additionalData?.showSubscriptionPurchaseModal(subscription_type);
        actions?.additionalData?.toggleButtonLoadingState && actions?.additionalData?.toggleButtonLoadingState();
      } else {
        actions?.callback?.(response.data.status);
      }
    } else {
      actions?.callback?.(false);
    }
  } catch (err: any) {
    if (!err?.data?.message.includes("_")) {
      err?.data?.message && ToastNotifyError(err?.data?.message);
    }
    actions?.callback?.(false, { noMessage: true });
  }
}

/**
 * This function will save customer review and ratings settings.
 *
 * @param actions
 */
function* setCustomerReviewSettings(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      data: actions.data,
      url: config.base_url + CUSTOMER_REVIEW_RATINGS.setCustomerReviewSettings,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback();
      ToastNotifySuccess("Settings successfully saved");
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will get customer review and ratings settings.
 */
function* getCustomerReviewSettings() {
  const storeData = store.getState().storeReducer.store;
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CUSTOMER_REVIEW_RATINGS.getCustomerReviewSettings}/${storeData.store_id}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveCustomerReviewSettings(response.data.data));
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will get customer review and ratings settings.
 */
function* getCustomerReviewsByStore(actions: any) {
  const storeData = store.getState().storeReducer.store;
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CUSTOMER_REVIEW_RATINGS.getReviewsByStore}?store_id=${storeData.store_id}${actions.data}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveReviewsByStore(response.data?.data));
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will be used for publishing/unpublishing a review, replying to
 * a review and marking a review as read by user.
 */
function* publishUnpublishReview(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      data: actions.data,
      url: `${config.base_url}${CUSTOMER_REVIEW_RATINGS.publishUnpublishReview}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback();
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* fetchSortFilters() {
  const store_id = store.getState().storeReducer.store.store_id;
  try {
    const response = yield apiCall({
      method: "GET",
      url: CATALOG_PAGE_API.getSortFilters(store_id),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveSortFilters(response?.data?.data));
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* browseCatalog(actions: any) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: CATALOG_PAGE_API.browseCatalogForCoupons,
      parseToJson: true,
      params: {
        type: actions?.data?.type,
        page: actions?.data?.page,
      },
    });
    if (response && response?.data?.status) {
      actions?.callback?.(response?.data?.data);
    } else {
      actions?.callback?.(false);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* setSortAndFilters(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: CATALOG_PAGE_API.setSortFilters,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      ToastNotifySuccess("Sort and Filters successfully updated");
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* fetchItemsDetailsByItemIdsSaga(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: CATALOG_PAGE_API.getItemsDetailsByItemIds,
      parseToJson: true,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(response.data.data);
    } else {
      ToastNotifyError("Unable to fetch Requested Items!");
    }
  } catch (err) {
    ToastNotifyError(err.data?.message);
  }
}

function* setCollectionCardAnimation(actions: any) {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: CATALOG_PAGE_API.setCollectionCardAnimation,
      parseToJson: true,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(response.data.data);
    } else {
      ToastNotifyError("Unable to set Animation!");
    }
  } catch (err) {
    ToastNotifyError(err.data?.message);
  }
}

function* setStoreServicesFlag(actions: any) {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: CATALOG_PAGE_API.storeServicesFlag,
      parseToJson: true,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(response.data.status);
    } else {
      actions.callback && actions.callback(response.data.status);
      ToastNotifyError("Unable to toggle priority placement for products!");
    }
  } catch (err) {
    actions.callback && actions.callback(false);
    ToastNotifyError(err.data?.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.TOGGLE_ITEM_AVAILABLITY, toggleItemAvailability);
  yield takeEvery(actionTypes.SAVE_ITEM, saveItem);
  yield takeEvery(actionTypes.SAVE_COLLECTION, saveCollection);
  yield takeEvery(actionTypes.SAVE_OTHERCOLLECTION, saveOtherCollection);
  yield takeEvery(actionTypes.UPDATE_COLLECTION_STATUS, updateCollectionStatus);
  yield takeEvery(actionTypes.FETCH_CATALOG_COLLECTIONS, fetchCatalogueCollections);
  yield takeEvery(
    actionTypes.FETCH_ITEMS_INSIDE_CLICKED_CATEGORY_OF_COLLECTIONS,
    fetchItemsInsideClickedCategoryOfCollections,
  );
  yield takeEvery(actionTypes.FETCH_CATALOG_CATEGORIES, fetchCatalogueCategories);
  yield takeEvery(actionTypes.DELETE_CATALOG_ITEM, deleteProductItem);
  yield takeEvery(actionTypes.DELETE_CATEGORY, deleteCategory);
  yield takeEvery(actionTypes.UPDATE_CATEGORY, updateCategory);
  yield takeEvery(actionTypes.UPLOAD_IMAGE, uploadImage);
  yield takeEvery(actionTypes.SEARCH_IMAGE, searchImage);
  yield takeEvery(actionTypes.SAVE_REORDERED_CATEGORY, saveReorderdCategories);
  yield takeEvery(actionTypes.SAVE_REORDERED_TAG, saveReorderdTag);
  yield takeEvery(actionTypes.GET_INDIVIDUAL_ITEM_DETAILS, fetchIndividualItemData);
  yield takeEvery(actionTypes.UPDATE_CATALOG_DATA, updateCatalogData);
  yield takeEvery(actionTypes.FETCH_RECENTLY_CREATED_VARIANTS, fetchRecentlyCreatedVariants);
  yield takeEvery(actionTypes.FETCH_SUGGESTIVE_VARIANTS, fetchSuggestiveVariants);
  yield takeEvery(actionTypes.SAVE_REORDERED_ITEMS, saveReorderedItems);
  yield takeEvery(actionTypes.SAVE_REORDERED_TAG_ITEMS, saveReorderedTagItems);
  yield takeEvery(actionTypes.FETCH_CATALOG_ITEMS_BASIC_DETAILS, fetchCatalogItemsBasicDetailsByCategoryId);
  yield takeEvery(actionTypes.FETCH_SEARCH_DATA, fetchSearchData);
  yield takeEvery(actionTypes.FETCH_CATALOG_ITEM_BY_ID, fetchCatalogItemById);
  yield takeEvery(actionTypes.ADD_CATALOG_ITEM, setCatalogItem);
  yield takeEvery(actionTypes.FETCH_SEARCHED_ITEMS, fetchSearchedItems);
  yield takeEvery(actionTypes.GET_ITEMS_BY_TAG_ID, getItemsByTagId);
  yield takeEvery(actionTypes.UPDATE_TAG, updateTag);
  yield takeEvery(actionTypes.REMOVE_ITEM_FROM_TAG, removeItemfromTag);
  yield takeEvery(actionTypes.QUICK_UPDATE_ITEM, quickUpdateItem);
  yield takeEvery(actionTypes.UPLOAD_COLLECTION_ORDER, uploadCollectionOrder);
  yield takeEvery(actionTypes.GET_BRAND_NAMES, getBrandNames);
  yield takeEvery(actionTypes.SAVE_ITEM_V3, saveItemV3);
  yield takeEvery(actionTypes.SET_CUSTOMER_REVIEW_SETTINGS, setCustomerReviewSettings);
  yield takeEvery(actionTypes.GET_CUSTOMER_REVIEW_SETTINGS, getCustomerReviewSettings);
  yield takeEvery(actionTypes.GET_CUSTOMER_REVIEWS_BY_STORE, getCustomerReviewsByStore);
  yield takeEvery(actionTypes.PUBLISH_UNPUBLISH_REVIEW, publishUnpublishReview);
  yield takeEvery(actionTypes.GET_SORT_FILTERS, fetchSortFilters);
  yield takeEvery(actionTypes.SET_SORT_FILTERS, setSortAndFilters);
  yield takeEvery(actionTypes.FETCH_ITEMS_BY_ITEM_IDS, fetchItemsDetailsByItemIdsSaga);
  yield takeEvery(actionTypes.SET_COLLECTION_CARD_ANIMATION, setCollectionCardAnimation);
  yield takeEvery(actionTypes.SET_STORE_SERVICES_FLAG, setStoreServicesFlag);
  yield takeEvery(actionTypes.BROWSE_CATALOG, browseCatalog);
}
