export const SET_CUSTOM_DELIVERY_DATA = "CUSTOM_DELIVERY/SET_CUSTOM_DELIVERY_DATA";
export const GET_CUSTOM_DELIVERY_DATA = "CUSTOM_DELIVERY/GET_CUSTOM_DELIVERY_DATA";
export const GET_CUSTOM_ZONES_DELIVERY_DATA = "CUSTOM_DELIVERY/GET_CUSTOM_ZONES_DELIVERY_DATA";
export const SET_CUSTOM_ZONES_DELIVERY_DATA = "CUSTOM_DELIVERY/SET_CUSTOM_ZONES_DELIVERY_DATA";
export const SAVE_CUSTOM_DELIVERY_DATA_FOR_STORE = "CUSTOM_DELIVERY/SAVE_CUSTOM_DELIVERY_DATA_FOR_STORE";
export const SAVE_CUSTOM_DELIVERY_DATA_FOR_ITEM = "CUSTOM_DELIVERY/SAVE_CUSTOM_DELIVERY_DATA_FOR_ITEM";
export const GET_CITY_DATA_LIST = "CUSTOM_DELIVERY/GET_CITY_DATA_LIST";
export const SAVE_CITY_DATA_LIST = "CUSTOM_DELIVERY/SAVE_CITY_DATA_LIST";
export const GET_ITEM_LIST_WITH_CHARGES_SETUP = "CUSTOM_DELIVERY/GET_ITEM_LIST_WITH_DELIVERY_CHARGES_SETUP";
export const SET_ITEM_LIST_WITH_CHARGES_SETUP = "CUSTOM_DELIVERY/SAVE_ITEM_LIST_WITH_DELIVERY_CHARGES_SETUP";
export const UPDATE_CUSTOM_ZONE_SETUP = "CUSTOM_DELIVERY/UPDATE_CUSTOM_ZONE_SETUP";
export const GET_REGIONAL_DETAILS = "CUSTOM_DELIVERY/GET_REGIONAL_DETAILS";
export const SAVE_REGIONAL_DETAILS = "CUSTOM_DELIVERY/SAVE_REGIONAL_DETAILS";
export const GET_ALL_CUSTOM_ZONES_DATA = "CUSTOM_DELIVERY/GET_ALL_CUSTOM_ZONES_DATA";
export const SAVE_ALL_CUSTOM_ZONES_DATA = "CUSTOM_DELIVERY/SAVE_ALL_CUSTOM_ZONES_DATA";
export const SAVE_ALL_CUSTOM_ZONES_DATA_FOR_ITEM = "CUSTOM_DELIVERY/SAVE_ALL_CUSTOM_ZONES_DATA_FOR_ITEM";
export const GET_CUSTOM_DELIVERY_SETUP_STATUS = "CUSTOM_DELIVERY/GET_CUSTOM_DELIVERY_SETUP_STATUS";
export const SET_ESTIMATED_DELIVERY_FLAG = "CUSTOM_DELIVERY/SET_ESTIMATED_DELIVERY_FLAG";
export const SAVE_CUSTOM_DELIVERY_CHOSEN_PRODUCT = "CUSTOM_DELIVERY/SAVE_CUSTOM_DELIVERY_CHOSEN_PRODUCT";
// fetch regions data
export const GET_ALL_REGIONS = "CUSTOM_DELIVERY/GET_ALL_REGIONS";
export const SAVE_ALL_REGIONS = "CUSTOM_DELIVERY/SAVE_ALL_REGIONS";
export const GET_ALL_STATE_BY_REGION = "CUSTOM_DELIVERY/GET_ALL_STATE_BY_REGION";
export const SAVE_ALL_STATE_BY_REGION = "CUSTOM_DELIVERY/SAVE_ALL_STATE_BY_REGION";
export const GET_ALL_CITIES_BY_STATE = "CUSTOM_DELIVERY/GET_ALL_CITIES_BY_STATE";
export const SAVE_ALL_CITIES_BY_STATE = "CUSTOM_DELIVERY/SAVE_ALL_CITIES_BY_STATE";
export const GET_ALL_PINCODES_BY_CITY = "CUSTOM_DELIVERY/GET_ALL_PINCODES_BY_CITY";
export const SAVE_ALL_PINCODES_BY_CITY = "CUSTOM_DELIVERY/SAVE_ALL_PINCODES_BY_CITY";
