import * as actionType from "../ActionTypes/gstBilling";

export function updateGSTBillingDetails(data: any, callback?: any) {
  return {
    type: actionType.UPDATE_GST_BILLING_DETAILS,
    data,
    callback,
  };
}

export function verifyGST(data: any, callback: any) {
  return {
    type: actionType.VERIFY_GST,
    data,
    callback,
  };
}

export function fetchGSTBillingDetails(callback?: any) {
  return {
    type: actionType.GET_GST_BILLING_DETAILS,
    callback,
  };
}

export function saveGSTBillingDetails(data: any) {
  return {
    type: actionType.SAVE_GST_BILLING_DETAILS,
    data,
  };
}

export function enableOrDisableGST(data: any, callback?: any) {
  return {
    type: actionType.ENABLE_OR_DISABLE_GST,
    data,
    callback,
  };
}

export function updateSameHsn() {
  return {
    type: actionType.UPDATE_SAME_HSN,
  };
}

export function toggleUploadCatalogSheetModal() {
  return {
    type: actionType.TOGGLE_UPLOAD_CATALOG_SHEET_MODAL,
  };
}

export function toggleTermsAndConditionsCheckedState() {
  return {
    type: actionType.TOGGLE_TERMS_AND_CONDITIONS_CHECKED_STATE,
  };
}

export function downloadGSTReport(data: any, callback: any) {
  return {
    type: actionType.DOWNLOAD_GST_REPORT,
    data,
    callback,
  };
}
