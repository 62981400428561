import { useEffect } from "react";
import { ff } from "../../KineticUI/src/utils";

export function useToggleFeatureFlag() {
  useEffect(() => {
    ff.isEnabled("demo") ? console.log("Enabled") : console.log("Disabled");
  }, []);

  return null;
}
