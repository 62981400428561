import {
  ECOMM,
  ECOMM_ELITE,
  ECOMM_PLUS,
  ECOMM_PLUS_TO_ECOMM_ELITE,
  ECOMM_TO_ECOMM_ELITE,
  ECOMM_TO_ECOMM_PLUS,
  SUBSCRIPTION_FLAG,
  UPGRADE_TO_VALUE,
  ECOMM_PLUS_TO_ECOMM_PLUS_RENEW,
  ECOMM_TO_ECOMM_RENEW,
} from "../Constants/subscription";
import store from "../Redux/Store";
import { SubscriptionType } from "../Types";
import { calculateRemainingTime, checkMerchantSubscriptionType } from "./common";

/**
 * Returns a boolean that denotes whether the Subscription is expiring in a given number of days.
 * By default the number of days are taken as remaining 30 days.
 * But can be configured to check for remaining 15 days
 * @param checkForFinal15Days
 * @returns {boolean}
 */
export const isSubscriptionExpiring = (checkForFinal15Days = false) => {
  const userSubscriptionDetails = store.getState().userReducer.userSubscriptionDetails;
  const storeData = store.getState().storeReducer.store;
  const calculatedDiff = calculateRemainingTime(userSubscriptionDetails?.expiry, false);
  const isExpiring =
    (checkForFinal15Days ? calculatedDiff.remainingDays <= 15 : calculatedDiff.remainingDays <= 30) &&
    calculatedDiff.remainingDays >= 0 &&
    calculatedDiff.remainingSeconds > 0;

  return storeData?.subscription_flag === SUBSCRIPTION_FLAG.SUBSCRIBED && isExpiring;
};

const subscriptionTypeString = (type: string): string => {
  switch (type) {
    case ECOMM_ELITE:
      return "Elite";
    case ECOMM_PLUS:
      return "Pro";
    case ECOMM:
      return "Essential";
    default:
      return "";
  }
};

export const getSubscriptionExpireText = (): string => {
  const userSubscriptionDetails = store.getState().userReducer.userSubscriptionDetails;
  const remainingDays = calculateRemainingTime(userSubscriptionDetails?.expiry).remainingDays;
  const remainingSeconds = calculateRemainingTime(userSubscriptionDetails?.expiry).remainingSeconds;
  const isToday = remainingDays <= 1;
  const hasExpired = remainingSeconds < 0;

  return hasExpired
    ? `Your eCommerce ${subscriptionTypeString(userSubscriptionDetails?.subscription_type)} Pack has expired.`
    : isToday
      ? `Your eCommerce ${subscriptionTypeString(userSubscriptionDetails?.subscription_type)} Pack will expire today.`
      : `Your eCommerce ${subscriptionTypeString(
          userSubscriptionDetails?.subscription_type,
        )} Pack will expire in ${Math.floor(
          calculateRemainingTime(userSubscriptionDetails?.expiry).remainingDays,
        )} days.`;
};

export const getSubscriptionUpgradeType = (upgradeType: any, isExpiring = false) => {
  const userSubscriptionDetails = store.getState().userReducer.userSubscriptionDetails;
  let value = 0;
  if (isExpiring) {
    if (checkMerchantSubscriptionType(ECOMM_ELITE) && upgradeType === ECOMM_ELITE) {
      value = 12;
    } else if (checkMerchantSubscriptionType(ECOMM_PLUS) && upgradeType === ECOMM_ELITE) {
      value = 11;
    } else if (checkMerchantSubscriptionType(ECOMM_PLUS) && upgradeType === ECOMM_PLUS) {
      value = 5;
    } else if (checkMerchantSubscriptionType(ECOMM) && upgradeType === ECOMM_ELITE) {
      value = 10;
    } else if (checkMerchantSubscriptionType(ECOMM)) {
      if (upgradeType === ECOMM) {
        value = 3;
      }
      if (upgradeType === ECOMM_PLUS) {
        value = 4;
      }
    }
  } else {
    if (userSubscriptionDetails?.subscription_type === ECOMM && upgradeType === ECOMM) {
      value = UPGRADE_TO_VALUE[ECOMM_TO_ECOMM_RENEW];
    } else if (
      userSubscriptionDetails?.subscription_type === ECOMM &&
      (upgradeType === ECOMM_TO_ECOMM_PLUS || upgradeType === ECOMM_PLUS)
    ) {
      value = UPGRADE_TO_VALUE[ECOMM_TO_ECOMM_PLUS];
    } else if (
      userSubscriptionDetails?.subscription_type === ECOMM &&
      (upgradeType === ECOMM_TO_ECOMM_ELITE || upgradeType === ECOMM_ELITE)
    ) {
      value = UPGRADE_TO_VALUE[ECOMM_TO_ECOMM_ELITE];
    } else if (
      userSubscriptionDetails?.subscription_type === ECOMM_PLUS &&
      (upgradeType === ECOMM_PLUS_TO_ECOMM_ELITE || upgradeType === ECOMM_ELITE)
    ) {
      value = UPGRADE_TO_VALUE[ECOMM_PLUS_TO_ECOMM_ELITE];
    } else if (userSubscriptionDetails?.subscription_type === ECOMM_PLUS && upgradeType === ECOMM_PLUS) {
      value = UPGRADE_TO_VALUE[ECOMM_PLUS_TO_ECOMM_PLUS_RENEW];
    } else {
      if (upgradeType === ECOMM) {
        value = UPGRADE_TO_VALUE[ECOMM];
      }
      if (upgradeType === ECOMM_PLUS) {
        value = UPGRADE_TO_VALUE[ECOMM_PLUS];
      }
      if (upgradeType === ECOMM_ELITE) {
        value = UPGRADE_TO_VALUE[ECOMM_ELITE];
      }
    }
  }
  return value;
};
