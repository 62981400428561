export const GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS = "GOOGLE_SHOOPING/GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS";
export const SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS = "GOOGLE_SHOOPING/SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS";
export const CHECK_STORE_ELIGIBILITY_FOR_SHOPPING = "GOOGLE_SHOOPING/CHECK_STORE_ELIGIBILITY_FOR_SHOPPING";
export const FETCH_SHOPPING_PRODUCT_ANALYSIS = "GOOGLE_SHOPPING/FETCH_SHOPPING_PRODUCT_ANALYSIS";
export const CHECK_ELIGIBLE_PRODUCT_NUMBER = "GOOGLE_SHOPPING/CHECK_ELIGIBLE_PRODUCT_NUMBER";
export const REQUEST_A_MANUAL_DEMO_ACTION = "GOOGLE_SHOPPING/REQUEST_A_MANUAL_DEMO_ACTION";
export const GET_STORE_SERVICE_DETAILS = "GOOGLE_SHOPPING/GET_STORE_SERVICE_DETAILS";
export const SAVE_STORE_SERVICE_DETAILS = "GOOGLE_SHOPPING/SAVE_STORE_SERVICE_DETAILS";
export const CREATE_GOOGLE_SHOPPINGACCOUNT_ACTION = "GOOGLE_SHOPPING/CREATE_GOOGLE_SHOPPINGACCOUNT_ACTION";
export const SET_USER_SHIPPING_DETAILS_ACTION = "GOOGLE_SHOPPING/SET_USER_SHIPPING_DETAILS_ACTION";
export const SET_API_CALL_ONGOING_FLAG = "GOOGLE_SHOPPING/SET_API_CALL_ONGOING_FLAG";
export const REQUEST_PHONE_NUMBER_VERIFICATION = "GOOGLE_SHOPPING/REQUEST_PHONE_NUMBER_VERIFICATION";
export const VERIFY_GOOGLE_SHOPPING_OTP = "GOOGLE_SHOPPING/VERIFY_GOOGLE_SHOPPING_OTP";
export const SAVE_GOOGLE_SHOPPING_PRODUCT_ANALYSIS = "GOOGLE_SHOPPING/SAVE_GOOGLE_SHOPPING_PRODUCT_ANALYSIS";
export const SYNC_ALL_PRODUCTS = "GOOGLE_SHOPPING/SYNC_ALL_PRODUCTS";

export const GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2 = "GOOGLE_SHOPPING/GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2";
export const SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2 = "GOOGLE_SHOPPING/SAVE_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2";
export const CREATE_GOOGLE_SHOPPING_ACCOUNT_V2 = "GOOGLE_SHOPPING/CREATE_GOOGLE_SHOPPING_ACCOUNT_V2";
export const GENERATE_OTP_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/GENERATE_GOOGLE_SHOPPING_ACCOUNT_V2";
export const VERIFY_OTP_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/VERIFY_GOOGLE_SHOPPING_ACCOUNT_V2";
export const SAVE_VERIFICATION_ID_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/SAVE_VERIFICATION_ID_GOOGLE_SHOPPING_V2";
export const GET_STORE_CATALOG_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/GET_STORE_CATALOG_GOOGLE_SHOPPING_V2";
export const SAVE_STORE_CATALOG_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/SAVE_STORE_CATALOG_GOOGLE_SHOPPING_V2";
export const GET_STORE_ANALYTICS_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/GET_STORE_ANALYTICS_GOOGLE_SHOPPING_V2";
export const SAVE_STORE_ANALYTICS_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/SAVE_STORE_ANALYTICS_GOOGLE_SHOPPING_V2";
export const AUTO_GENERATE_DESC_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/AUTO_GENERATE_DESC_GOOGLE_SHOPPING_V2";
export const ADD_ITEM_IMAGE_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/ADD_ITEM_IMAGE_GOOGLE_SHOPPING_V2";
export const QUICK_ITEM_UPDATE_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/QUICK_ITEM_UPDATE_GOOGLE_SHOPPING_V2";
export const SEND_SHIPPING_DETAILS_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/SEND_SHIPPING_DETAILS_GOOGLE_SHOPPING_V2";
export const SYNC_ALL_PRODUCTS_GOOGLE_SHOPPING_V2 = "GOOGLE_SHOPPING/SYNC_ALL_PRODUCTS_GOOGLE_SHOPPING_V2";
