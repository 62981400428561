import * as authTypes from "./../ActionTypes";
interface Action {
  type: string;
  data?: any;
  callback?: any;
  actionBool?: any;
  isMobile?: boolean;
}

export function setLeadGenTemplate(data: any, callback: any): Action {
  return {
    type: authTypes.SET_LEADGEN_TEMPLATE,
    data,
    callback,
  };
}

export function getStoreActiveLeadGenTemplate(data: any): Action {
  return {
    type: authTypes.GET_STORE_ACTIVE_LEADGEN_TEMPLATE,
    data,
  };
}

export function setStoreLeadGenTemplates(data: any): Action {
  return {
    type: authTypes.SET_STORE_LEADGEN_TEMPLATES,
    data,
  };
}
