import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError } from "../../Components/Toast";
import { setPageLoaderVisibility, updateProductInquiryForStore, updateProductInquiryToggleStatus } from "../Actions";
import store from "./../Store";
import history from "../../Utils/history";

import apiCall from "./../../Services/api";
import {
  FETCH_PRODUCT_INQUIRY,
  TOGGLE_PRODUCT_INQUIRY_ENABLE,
  FETCH_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS,
} from "./../ActionTypes";
import config from "./../../Config";
import { parseToJson } from "../../Utils/common";
import LocalStorageHelper from "../../Utils/LocalStorageHelper";

/** Notify Me ( Product Inquiry ) related API Calls */
function* getProductsInquiry(actions?: any): any {
  const storeData = store.getState().storeReducer.store;
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/products/notifyMeItemInfoByFilters?store_id=${storeData.store_id}${actions.data}`,
    });

    if (response && response?.data?.status) {
      const parsedData = response?.data?.products_info;
      yield put(updateProductInquiryForStore(parsedData));
      yield put(setPageLoaderVisibility(false));
      actions?.callback && actions.callback(parsedData.total_pages);
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err?.data?.message);
  }
}

function* toggleProductInquiryEnableDisable(actions?: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/products/notifyMeEnabledFlag`,
      data: actions.data,
    });

    if (response && response?.data?.status) {
      const parsedData = response?.data?.products_info;
      actions?.callback();
      // yield put(setPageLoaderVisibility(false));
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err?.data?.message);
  }
}

function* getProductInquiryEnableDisableStatus(actions?: any): any {
  try {
    // const storeData = store.getState().storeReducer.store;
    const payload = {
      auth_token: LocalStorageHelper.get("auth_token", history),
    };
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/authenticate`,
      data: payload,
    });
    if (response && response?.data?.status) {
      yield put(updateProductInquiryToggleStatus(response?.data?.store?.services?.notify_me_enabled));
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err?.data?.message);
  }
}

export default function* root() {
  yield takeEvery(FETCH_PRODUCT_INQUIRY, getProductsInquiry);
  yield takeEvery(TOGGLE_PRODUCT_INQUIRY_ENABLE, toggleProductInquiryEnableDisable);
  yield takeEvery(FETCH_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS, getProductInquiryEnableDisableStatus);
}
