import * as actionType from "../ActionTypes/creditEngineActions";

const initialState = {
  userCredits: 0,
  creditsTransactions: [],
};

const CreditEngine = (state = initialState, action: any) => {
  switch (action.type) {
    case actionType.SAVE_STORE_CREDITS:
      return { ...state, userCredits: parseInt(action.data) };
    case actionType.SAVE_CREDITS_TRANSACTIONS:
      return { ...state, creditsTransactions: action.data.transactions };
    default:
      return state;
  }
};

export default CreditEngine;
