import { ComponentType, lazy } from "react";
import { withRouter } from "react-router-dom";
import Layout from '../Containers/Layout/index';

export default function withLazyComponent<T>(importComponent: () => Promise<{ default: ComponentType<T> }>) {
  const LazyComponent = lazy(importComponent);

  function WithLazyComponent(props: any) {
    return (
      <Layout>
        <LazyComponent {...props} />
      </Layout>
    );
  }
  return withRouter(WithLazyComponent);
}
