import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes/bulkUploadType";
import { ToastNotifyError, ToastNotifySuccess } from "./../../Components/Toast";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import LocalStorageHelper from "./../../Utils/LocalStorageHelper";
import {
  uploadBatchId,
  setPageLoaderVisibility,
  setBulkUploadDetails,
  gstUploadBatchId,
  setGstBulkUploadDetails,
  downloadBulkItemsExcelByStoreId,
} from "./../Actions";
import { BULK_UPLOAD } from "../../Services/apiUrls";
import { parseToJson } from "../../Utils/common";
import store from "../Store";
import { GST_BILLING_CONSTANT } from "../../Constants";

function* bulkUploadBatch(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${BULK_UPLOAD.bulkUpload}`,
      data: actions.data.data,
    });
    if (response && response.data.status) {
      if (actions.data.type === GST_BILLING_CONSTANT) {
        yield put(gstUploadBatchId({ id: response.data.data, filename: actions.data.filename }));
      } else {
        yield put(uploadBatchId({ id: response.data.data, filename: actions.data.filename }));
      }
      actions?.data?.callback && actions?.data?.callback(true);
      ToastNotifySuccess("Bulk upload started");
    } else {
      actions?.data?.callback(false);
      ToastNotifyError("Error !");
    }
  } catch (err) {
    actions?.data?.callback(false);
    ToastNotifyError(err?.data?.message);
    console.log(err);
  }
}

function* bulkUploadDetails(actions: any) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${BULK_UPLOAD.bulkUploadDetails}/${actions.data.id}`,
    });
    if (response && response.data.status) {
      if (actions.data.type === GST_BILLING_CONSTANT) {
        yield put(setGstBulkUploadDetails(parseToJson(response.data.data)));
        yield put(gstUploadBatchId({ id: "", filename: actions.data.filename }));
      } else {
        yield put(setBulkUploadDetails(parseToJson(response.data.data)));
        yield put(uploadBatchId({ id: "", filename: actions.data.filename }));
      }
      actions?.data?.callback(parseToJson(response.data.data).status === 2);
    } else {
      ToastNotifyError(response.data.message);
      actions?.data?.callback(false);
    }
  } catch (err) {
    ToastNotifyError(err?.data?.message);
    actions?.data?.callback(false);
  }
}

function* getStoreItemsInExcel(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${BULK_UPLOAD.getStoreItemsInExcel}?${actions.data.queryParams}`,
    });
    if (response && response.data.status) {
      actions && actions.data.callback && actions.data.callback(true);
      yield put(setPageLoaderVisibility(false));
    } else {
      actions && actions.data.callback && actions.data.callback(false);
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Error !");
    }
  } catch (err) {
    actions && actions.data.callback && actions.data.callback(false);
    yield put(setPageLoaderVisibility(false));
    console.log(err);
  }
}

function* downloadItemsExcelByStoreId(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${BULK_UPLOAD.downloadItemsExcelByStoreId}?${actions.data.queryParams}`,
    });
    if (response && response.data.status) {
      actions && actions.data.callback && actions.data.callback(true, parseToJson(response.data.data));
      yield put(setPageLoaderVisibility(false));
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Error !");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.BULK_UPLOAD_BATCH, bulkUploadBatch);
  yield takeEvery(actionTypes.GET_BULK_UPLOAD_DETAILS, bulkUploadDetails);
  yield takeEvery(actionTypes.GET_STORE_ITEMS_IN_EXCEL, getStoreItemsInExcel);
  yield takeEvery(actionTypes.GET_ITEMS_EXCEL_BY_STORE_ID, downloadItemsExcelByStoreId);
}
