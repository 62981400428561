import apiCall from '../../Services/api';
import { BULK_EDIT_API } from '../../Services/apiUrls';
import { diceLoaderVisibilty } from '../Actions/common';
import { put, takeEvery } from 'redux-saga/effects';
import { fetchBulkCatalog, lastDraftDate, saveActiveBulkEditCount, saveBulkCatalog, saveDraftId, saveDraftItems, shouldDiscardDraftSubheader } from '../Actions/bulkEdit';
import { DISCARD_DRAFT, GET_ACTIVE_BULK_EDIT_COUNT, GET_BULK_CATALOG, POST_BULK_CATALOG_ITEMS, EXPORT_CATALOG } from '../ActionTypes/bulkEditTypes';
import { ToastNotifySuccess, ToastNotifyError } from '../../Components/Toast/index';
import { getPayloadForBulkEditItems } from '../../Containers/BulkEdit/Utils/getPayloadForBulkEditItems';
import { getBulkCatalogWithDraft } from '../../Containers/BulkEdit/Utils/getBulkCatalogWithDraft';
import { getStoreId } from '../../Utils/common';
import store from '../Store/index';

function* fetchBulkCatalogSaga() {
  yield put(diceLoaderVisibilty(true));

  try {
    const response = yield apiCall({
      method: "GET",
      url: BULK_EDIT_API.getBulkCatalog,
      parseToJson: true,
    });
    if (response && response.status) {
      yield put(saveDraftId(response.data?.data?.draft_id));
      yield put(lastDraftDate(response.data?.data?.created_at));
      yield put(saveBulkCatalog(getBulkCatalogWithDraft(response.data.data)));
      yield put(saveDraftItems(response.data.data?.draft_items));
    }
  } catch {
    yield put(diceLoaderVisibilty(false));
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* postBulkEditItems(actions: any) {
  yield put(diceLoaderVisibilty(true));
  const payloadForItems = getPayloadForBulkEditItems(actions.data, actions.saveType);

  if (!payloadForItems?.items?.length) {
    return;
  }

  try {
    const response = yield apiCall({
      method: "POST",
      url: BULK_EDIT_API.postBulkCatalog,
      data: payloadForItems,
    });
    if (response && response.status) {
      ToastNotifySuccess("Changes Saved Successfully");
    }
  } catch (err) {
    yield put(diceLoaderVisibilty(false));
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* discardDraft() {
  yield put(diceLoaderVisibilty(true));

  const payload = {
    store_id: getStoreId(),
    draft_id: store.getState().bulkEditReducer.draftId,
    status: 3,
  };

  try {
    const response = yield apiCall({
      method: "PUT",
      url: BULK_EDIT_API.discardDraft,
      data: payload,
    });
    if (response && response.status) {
      yield put(shouldDiscardDraftSubheader(false));
      yield put(fetchBulkCatalog());
      ToastNotifySuccess("Draft Removed Successfully");
    }
  } catch {
    yield put(diceLoaderVisibilty(false));
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* getActiveBulkEditCount(action: any) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: BULK_EDIT_API.activeBulkEditCount,
      parseToJson: true,
    });
    if (response && response.status) {
      yield put(saveActiveBulkEditCount(response.data.data?.count));
      action?.callback && action?.callback(response.data.data?.count);
    }
  } catch (err) {
    console.log(err);
  }
}

function* exportCatalog(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: BULK_EDIT_API.exportCatalog,
      parseToJson: true,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions?.callback?.({ status: response.data.status });
    } else {
      actions?.callback?.({ status: false });
      ToastNotifyError("Unable to export Requested Items!");
    }
  } catch (err) {
    actions?.callback?.({ status: false });
    ToastNotifyError(err.data?.message);
  }
}

export default function* root() {
  yield takeEvery(GET_BULK_CATALOG, fetchBulkCatalogSaga);
  yield takeEvery(POST_BULK_CATALOG_ITEMS, postBulkEditItems);
  yield takeEvery(DISCARD_DRAFT, discardDraft);
  yield takeEvery(GET_ACTIVE_BULK_EDIT_COUNT, getActiveBulkEditCount);
  yield takeEvery(EXPORT_CATALOG, exportCatalog);

}
