import { put, takeEvery } from "redux-saga/effects";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import * as actionTypes from "./../ActionTypes";
import { setPageLoaderVisibility, saveDnsManagementData } from "../Actions";
import { DNS_MANAGEMENT_API } from "../../Services/apiUrls";
import { ToastNotifyError } from "../../Components/Toast";

function* getAllDNSData(action: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${DNS_MANAGEMENT_API.getAllData(action.domainName)}`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(saveDnsManagementData(response.data.data));
    } else {
      if (response?.data?.message) {
        ToastNotifyError(response?.data?.message);
      }
    }
    action.callback?.(response.data.status);
  } catch (err) {
    ToastNotifyError(err);
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

function* addCustomRecords(action: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: action.data.record_id ? "PATCH" : "POST",
      url: `${config.base_url}${DNS_MANAGEMENT_API.updateCustomRecords()}`,
      data: action.data,
      parseToJson: true,
    });
    if (response?.data?.message && !response?.data?.status) {
      ToastNotifyError(response?.data?.message);
    }

    action.callback?.(response.data.status, response?.data);
  } catch (err) {
    ToastNotifyError(err?.data?.message);
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

function* deleteCustomRecord(action: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "DELETE",
      url: `${config.base_url}${DNS_MANAGEMENT_API.deleteCustomRecords(action.data)}`,
      parseToJson: true,
    });
    // if (response && response.data.status) {
    //   yield put(updateAddCustomRecords(action.data));
    // }
    action.callback?.(response.data.status);
    if (response?.message && !response?.data?.status) {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    ToastNotifyError(err?.data?.message);
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

function* updateNameServers(action: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${DNS_MANAGEMENT_API.updateNameServers()}`,
      data: action.data,
    });
    // if (response && response.data.status) {
    //   yield put(updateAddCustomRecords(action.data));
    // }
    if (response?.message && !response?.data?.status) {
      ToastNotifyError(response?.data?.message);
    }
    action.callback?.(response.data.status);
  } catch (err) {
    ToastNotifyError(err?.data?.message);
  } finally {
    action.callback?.(false);
    yield put(setPageLoaderVisibility(false));
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_ALL_DNS_MANAGEMENT_RECORDS, getAllDNSData);
  yield takeEvery(actionTypes.UPDATE_CUSTOM_RECORDS, addCustomRecords);
  yield takeEvery(actionTypes.DELETE_CUSTOM_RECORDS, deleteCustomRecord);
  yield takeEvery(actionTypes.UPDATE_NAME_SERVERS, updateNameServers);
}
