import * as actionTypes from "../ActionTypes";

const initialState = {
  data: {
    primary_result: {},
    results: [],
  },
  searchData: {
    primary_result: {},
    results: [],
    searchStatus: null,
    searchedStringBE: "",
  },
  transfomedsSearchData: {},
  domainRenewalYear: 1,
  domainDetails: null,
  allDomainDetails: null,
  domainRenewalDetail: null,
  domainRenewalExpiry: { day: "00", hour: "00", minute: "00", second: "00" },
  domainCart: {
    cart_id: 0,
    type: 0,
    amount: 0,
    discounted_amount: 0,
    items: [],
  },
  selectedDomainsInCart: {},
  latestDomainPurchase: {},
};

const DomainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_DOMAIN_LIST:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.SAVE_SEARCH_DOMAIN_LIST:
      return {
        ...state,
        searchData: action.data,
      };
    case actionTypes.SAVE_DOMAIN_DETAILS:
      return {
        ...state,
        domainDetails: action.data,
      };
    case actionTypes.SAVE_ALL_DOMAIN_DETAILS:
      return {
        ...state,
        allDomainDetails: action.data,
      };
    case actionTypes.SAVE_DOMAIN_RENEWAL_DETAILS:
      return {
        ...state,
        domainRenewalDetail: action.data,
      };
    case actionTypes.SAVE_DOMAIN_RENEWAL_EXPIRY:
      return {
        ...state,
        domainRenewalExpiry: action.data,
      };
    case actionTypes.SAVE_DOMAIN_YEAR:
      return {
        ...state,
        domainRenewalYear: action.data,
      };
    case actionTypes.SAVE_DOMAIN_CART:
      return {
        ...state,
        domainCart: action.data,
      };
    case actionTypes.SAVE_LATEST_DOMAIN_PURCHASE_DETAILS:
      return {
        ...state,
        latestDomainPurchase: action.data,
      };
    default:
      return state;
  }
};

export default DomainReducer;
