import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  BodyComponent: {
    paddingTop: "5rem",
    overflow: "scroll",
  },
  classWhenThemeAlertBarPresent: {
    paddingTop: "7rem",
  },
}));
