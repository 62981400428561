import { put, takeEvery } from "redux-saga/effects";
import apiCall from "./../../Services/api";
import { ToastNotifyError } from "../../Components/Toast";
import {
  saveAllCitiesByState,
  saveAllCustomZonesData,
  saveAllCustomZonesDataForItem,
  saveAllPincodesByCity,
  saveAllRegions,
  saveAllStatesByRegion,
  saveCustomDeliveryDataForItem,
  saveCustomDeliveryDataForStore,
  saveItemListWithChargesSetup,
  setPageLoaderVisibility,
} from "../Actions";
import { CUSTOM_DELIVERY } from "../../Services/apiUrls";
import {
  GET_ALL_CITIES_BY_STATE,
  GET_ALL_CUSTOM_ZONES_DATA,
  GET_ALL_PINCODES_BY_CITY,
  GET_ALL_REGIONS,
  GET_ALL_STATE_BY_REGION,
  GET_CUSTOM_DELIVERY_DATA,
  GET_CUSTOM_DELIVERY_SETUP_STATUS,
  GET_ITEM_LIST_WITH_CHARGES_SETUP,
  GET_REGIONAL_DETAILS,
  SET_CUSTOM_DELIVERY_DATA,
  UPDATE_CUSTOM_ZONE_SETUP,
  SET_ESTIMATED_DELIVERY_FLAG,
} from "./../ActionTypes";

function* getCustomDeliveryData(actions: any): any {
  try {
    const itemID = actions.data;
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getCustomDeliveryData(itemID),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      itemID === 0
        ? yield put(saveCustomDeliveryDataForStore(response.data.data))
        : yield put(saveCustomDeliveryDataForItem(response.data.data));
    } else {
      ToastNotifyError("Unable to get delivery data. Please try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to get delivery data. Please try again later");
  }
}

function* setCustomDeliveryData(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: CUSTOM_DELIVERY.setCustomDeliveryData(),
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions.callback(true);
    } else {
      ToastNotifyError("Unable to set delivery data. Please try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to set delivery data. Please try again later");
  }
}

function* getItemListWithChargesSetup(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getItemListWithChargesSetup(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveItemListWithChargesSetup(response.data.data));
    }
    // else ToastNotifyError("Unable to get items. Please try again later");
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to get items. Please try again later");
  }
}

function* getAllRegions(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getAllRegions(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback?.(response.data.data);
      yield put(saveAllRegions(response.data.data));
    } else {
      ToastNotifyError("Unable to fetch regional data. Please try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch regional data. Please try again later");
  }
}

function* getAllStateByRegion(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getAllStatesByRegion(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback?.(response.data.data);
      yield put(saveAllStatesByRegion({ region: actions.data, data: response.data.data }));
    } else {
      ToastNotifyError("Unable to fetch states for this region. Please try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch states for this region. Please try again later");
  }
}

function* getAllCitiesByState(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getAllCitiesByState(actions.data, actions.isMetroRegion),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback?.(response.data.data);
      yield put(saveAllCitiesByState({ state: actions.data, data: response.data.data }));
    } else {
      ToastNotifyError("Unable to fetch cities for this state. Please try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch cities for this state. Please try again later");
  }
}

function* getAllPincodesByCity(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getAllPincodesByCity(actions.data, actions.isMetroRegion),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback?.(response.data.data);
      yield put(saveAllPincodesByCity({ city: actions.data, data: response.data.data }));
    } else {
      ToastNotifyError("Unable to pincodes for this city. Please try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to pincodes for this city. Please try again later");
  }
}

function* updateCustomZoneSetup(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: CUSTOM_DELIVERY.updateCustomZoneSetup(),
      data: actions.data,
      parseToJson: true,
    });
    if (!response?.data?.status) {
      ToastNotifyError("Unable to update custom zones. Please try again later");
    }
    actions.callback?.(response?.data?.status, response.data.data);
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to update custom zones. Please try again later");
  }
}

function* getRegionalDetails(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getRegionalDetails(actions.data),
      parseToJson: true,
    });
    if (!response?.data?.status) {
      ToastNotifyError("Unable to fetch particular regional data. Please try again later");
    }
    actions.callback?.(response?.data?.status, response.data.data);
    actions.resolve?.(response.data.data);
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    console.log(error);
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch particular regional data. Please try again later");
  }
}

function* getAllCustomZonesDetails(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getAllCustomZonesDetails(actions.data, actions.itemID, actions.zoneName),
      parseToJson: true,
    });
    const payload = response?.data?.status ? response.data.data : [];
    actions.itemID != 0
      ? yield put(saveAllCustomZonesDataForItem(payload))
      : yield put(saveAllCustomZonesData(payload));
    actions.callback?.(payload);
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    console.log(error);
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch custom zones data. Please try again later");
  }
}

function* getCustomDeliveryDataStatus(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      url: CUSTOM_DELIVERY.getCustomDeliveryDataStatus(),
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback?.(response.data.data);
    } else {
      ToastNotifyError("Unable to get custom setup details. Please try again later");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    console.log(error);
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to get custom setup details. Please try again later");
  }
}

function* updateEstimateDeliveryFlag(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      data: actions.data,
      url: CUSTOM_DELIVERY.enableOrDisableEstimateDeliveryFlag,
    });
    if (response && !response?.data?.status) {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(GET_CUSTOM_DELIVERY_DATA, getCustomDeliveryData);
  yield takeEvery(SET_CUSTOM_DELIVERY_DATA, setCustomDeliveryData);
  yield takeEvery(GET_ITEM_LIST_WITH_CHARGES_SETUP, getItemListWithChargesSetup);
  yield takeEvery(GET_ALL_REGIONS, getAllRegions);
  yield takeEvery(GET_ALL_STATE_BY_REGION, getAllStateByRegion);
  yield takeEvery(GET_ALL_CITIES_BY_STATE, getAllCitiesByState);
  yield takeEvery(GET_ALL_PINCODES_BY_CITY, getAllPincodesByCity);
  yield takeEvery(UPDATE_CUSTOM_ZONE_SETUP, updateCustomZoneSetup);
  yield takeEvery(GET_REGIONAL_DETAILS, getRegionalDetails);
  yield takeEvery(GET_ALL_CUSTOM_ZONES_DATA, getAllCustomZonesDetails);
  yield takeEvery(GET_CUSTOM_DELIVERY_SETUP_STATUS, getCustomDeliveryDataStatus);
  yield takeEvery(SET_ESTIMATED_DELIVERY_FLAG, updateEstimateDeliveryFlag);
}
