import { useEffect, useState } from "react";

export const useCountdownHook = (expiryDate: string | null) => {
  let x: any = null;
  let timeDifference: any = null;

  const [timeLeft, setTimeLeft] = useState<any>(
    expiryDate
      ? getTimeLeft()
      : {
          hour: "00",
          minute: "00",
          second: "00",
        },
  );

  useEffect(() => {
    expiryDate && _timer();
    return () => {
      clearInterval(x);
      x = null;
      while (x !== null) {
        x = null;
      }
    };
  }, [expiryDate]);

  function getTimeLeft() {
    const now = new Date().getTime();
    const countDownDate = new Date(expiryDate ? expiryDate.replace(" ", "T") : "").getTime();
    timeDifference = countDownDate - now;
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return {
      hour: hours > 0 ? hours : "00",
      minute: minutes < 10 ? "0" + minutes : minutes,
      second: seconds < 10 ? "0" + seconds : seconds,
    };
  }

  const _timer = () => {
    clearInterval(x);

    x = setInterval(() => {
      setTimeLeft(getTimeLeft());
      if (timeDifference <= 0) {
        clearInterval(x);
        setTimeLeft({
          hour: "00",
          minute: "00",
          second: "00",
        });
      }
    }, 1000);
  };

  return [timeLeft.hour || "00", timeLeft.minute || "00", timeLeft.second || "00", timeDifference];
};
