import optimizely, { Client } from "@optimizely/optimizely-sdk";
import { getCookie } from "../cookies";

const SDK_KEYS: Record<string, string> = {
  dev: "6xQyd8xvnBV7EgxLhoUWC",
  production: "QPqLkc98ftKMLW9zz8sky",
};

export type FFOverrides = Record<string, boolean>;
export interface FFConfig {
  environment: string;
  overrides?: string;
}

interface FFContext {
  userId: string;
  storeId?: string;
  storeDomain?: string;
}

const FF_OVERRIDE_KEY = "ff";

const parseJSONOverrides = (previousOverrides: FFOverrides = {}, jsonOverrides = "{}") => {
  let jsonOverridesObj: FFOverrides = previousOverrides;
  try {
    const overridesFromCookie = JSON.parse(jsonOverrides) as Record<string, boolean>;
    jsonOverridesObj = { ...previousOverrides, ...overridesFromCookie };
  } catch {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      // eslint-disable-next-line no-console
      console.log("Error parsing FF overrides from cookies");
    } else {
      // eslint-disable-next-line no-console
      console.log("Skipping FF parsing from cookies");
    }
  }
  return jsonOverridesObj;
};

const parseUrlFFOverrides = (previousOverrides: FFOverrides = {}) => {
  let urlOverridesObj: FFOverrides = previousOverrides;
  try {
    const overridesFromUrl: Record<string, boolean> = {};
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      if (key.startsWith(FF_OVERRIDE_KEY)) {
        const ffKey = key.replace(`${FF_OVERRIDE_KEY}.`, "");
        overridesFromUrl[ffKey] = value === "true";
      }
    });
    urlOverridesObj = { ...previousOverrides, ...overridesFromUrl };
  } catch {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line no-console
      console.log("Error parsing FF overrides from url");
    } else {
      // eslint-disable-next-line no-console
      console.log("Skipping FF parsing from url");
    }
  }
  return urlOverridesObj;
};

const FF = () => {
  let instance: Client | null;
  let context: FFContext = {
    userId: "",
  };
  let isInitialized = false;
  let overrides: FFOverrides = {};

  const init = (initOptions: FFConfig): Promise<Client | null> => {
    return new Promise((resolve) => {
      setupOverrides(initOptions.overrides);
      if (!isInitialized) {
        const sdkKey = SDK_KEYS[initOptions.environment];
        instance = optimizely.createInstance({
          eventBatchSize: 10,
          eventFlushInterval: 1000,
          sdkKey,
          datafileOptions: {
            autoUpdate: typeof document === "undefined",
            updateInterval: 10000,
          },
        });
        optimizely.setLogLevel("warn");
        if (instance) {
          instance.onReady().then(() => {
            resolve(instance);
          });
        }
        isInitialized = true;
      } else {
        resolve(instance);
      }
    });
  };

  const setupOverrides = (preDefinedOverrides = "{}") => {
    let ffOverrides: FFOverrides = {};
    ffOverrides = parseJSONOverrides(ffOverrides, preDefinedOverrides);
    ffOverrides = parseJSONOverrides(ffOverrides, getCookie(FF_OVERRIDE_KEY));
    ffOverrides = parseUrlFFOverrides(ffOverrides);
    overrides = { ...overrides, ...ffOverrides };
  };

  const setContext = (ctx?: Partial<FFContext>) => {
    if (ctx) {
      context = {
        ...context,
        ...ctx,
      };
    } else {
      context = {
        userId: context.userId || "",
      };
    }
  };

  const getContext = () => {
    return context;
  };

  /**
   * Disabled FF will have more preference over enabled FFs
   * @param override Override params with enabled and disabled FFs
   */
  const setOverrides = (ffOverrides: string) => {
    if (ffOverrides) {
      setupOverrides(ffOverrides);
    }
  };

  const isEnabled = (key: string) => {
    const overrideValue = overrides[key];
    if (typeof overrideValue === "boolean") {
      return overrideValue;
    }
    const enabled = instance?.isFeatureEnabled(key, context.userId, context);
    return enabled;
  };

  // const getAllFeatureToggles = () => {
  //   return
  // };

  const getFeatureVariables = (key: string) => {
    return instance?.getAllFeatureVariables(key, context.userId, context);
  };

  const getFeatureVariable = (key: string, variableName: string) => {
    return instance?.getFeatureVariable(key, variableName, context.userId, context);
  };

  return {
    init,
    setContext,
    getContext,
    setOverrides,
    isEnabled,
    getFeatureVariables,
    getFeatureVariable,
  };
};

export const ff = FF();
