import { STORE_PAYLOAD } from "../../Utils/constants";
import * as storeTypes from "../ActionTypes";

const initialState: any = {
  store: {},
  data: {
    store: STORE_PAYLOAD,
  },
  b2bCustomerDetails: {},
};

const storeReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case storeTypes.SAVE_STORE_INFO:
      return { ...state, store: { ...state.store, ...action.data, ...action.data.store || {} } };
    case storeTypes.UPDATE_STORE_SERVICES:
      return { ...state, store: { ...state.store, services: action.data } };
    case storeTypes.UPDATE_STORE_INFO:
      return { ...state, store: { ...state.store, ...action.data } };
    case storeTypes.UPDATE_STORE_ADDRESS:
      return { ...state, store: { ...state.store, address: action.data } };
    case storeTypes.UPDATE_STORE_BUSINESS:
      return { ...state, store: { ...state.store, store_businesses: action.data } };    
    case storeTypes.SET_STORE_INVOICES:
      return { ...state, store: { ...state.store, invoices: action.data } };
    case storeTypes.UPDATE_THEME_DATA: {
      return {
        ...state,
        data: action.data,
      };
    }
    case storeTypes.UPDATE_STORE_ANNOUNCEMENTS:
      return { ...state, store: { ...state.store, announcements: action.data } };
    case storeTypes.UPDATE_STORE_SOCIAL_MEDIA_DETAILS:
      return { ...state, store: { ...state.store, social_media_profiles: action.data } };
    case storeTypes.SAVE_CUSTOMER_DETAILS_FORM:
      return { ...state, b2bCustomerDetails: action.data };
    default:
      return state;
  }
};

export default storeReducer;
