import * as bankDetailTypes from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const fetchBankAccountDetails = (callback?: any): Action => {
  return {
    type: bankDetailTypes.FETCH_BANK_ACCOUNT_DETAILS,
    callback,
  };
};

export const submitBankAccountDetails = (data: any, callback?: any): Action => {
  return {
    type: bankDetailTypes.SUBMIT_BANK_ACCOUNT_DETAILS,
    data,
    callback,
  };
};

export const saveBankAccountDetails = (data: any): Action => {
  return {
    type: bankDetailTypes.SAVE_BANK_ACCOUNT_DETAILS,
    data,
  };
};
