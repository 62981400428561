import moment from "moment";
import { DATE_FORMAT } from "../Constants/dateFormats";

/**
 * Format Time in following ways
 * 1. Today at 4:00pm
 * 2. Yesteday at 4:00pm
 * 3. March 12, 4:00pm
 */

export const formatTime = (time: string) => {
  const today = moment().endOf("day");
  const yesterday = moment().add(-1, "day").endOf("day");
  const date = moment(time).endOf("day");
  if (date.isSame(today)) {
    return `Today at ${moment(time).format("hh:mm a")}`;
  } else if (date.isSame(yesterday)) {
    return `Yesterday at ${moment(time).format("hh:mm a")}`;
  }
  return `${moment(time).format("MMMM Do")}, ${moment(time).format("hh:mm a")}`;
};

export const UtcToCurrentTime = (data: Date) => {
  data = new Date(Date.UTC(data.getFullYear(), data.getMonth(), data.getDate(), data.getHours(), data.getMinutes()));
  return data;
};

/** Formats the date according to the formats created in constants file, that you will get in suggestions when typing
 *  in format argument   */
type DateFormatKeys = keyof typeof DATE_FORMAT;
type DateFormatValues = (typeof DATE_FORMAT)[DateFormatKeys];
export const formatDate = (date: string, format: DateFormatValues) => moment(date).format(format);
