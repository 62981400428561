import * as actionType from "../ActionTypes/additionalPagesActions";

const initialState = {
  storePagesList: [],
  defaultTemplateList: [],
};

const AdditonalPagesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionType.SAVE_STORE_PAGES_LIST:
      return { ...state, storePagesList: action.data || [] };
    case actionType.SAVE_DEFAULT_TEMPLATE_LIST:
      return { ...state, defaultTemplateList: action.data || [] };
    default:
      return state;
  }
};

export default AdditonalPagesReducer;
