import { useSelector } from "react-redux";
import { RootState } from "../Redux/Reducers";

enum subscriptionKey {
  DOMAIN = "domain",
  ECOMM_THEME = "theme",
  BRAND_WEBSITE = "brandWebsiteSubscription",
  ECOM_PACK = "ecomSubscription",
  UPCOMING_ECOM_PACK = "upcomingEcomSubscription",
  WABA = "waba",
  GST = "gstSubscription",
  WORKSPACE = "google_workspace",
  ADDITIONAL_PAGE = "additional_page",
  EXTERNAL_DOMAIN = "externalDomain",
}

const useActiveSubscription = () => {
  const { allSubscriptionDetails } = useSelector((state: RootState) => ({
    allSubscriptionDetails: state.themeReducer.subscriptionDetails,
  }));

  function getStatus(subscriptionKey: subscriptionKey, checkStatus = true) {
    const availableSubscriptionkey = Object.keys(allSubscriptionDetails).find((subKey) => {
      if (subKey === subscriptionKey || subKey.includes(subscriptionKey)) {
        return true;
      }
      return false;
    });

    if (!checkStatus) {
      return !!allSubscriptionDetails?.[availableSubscriptionkey];
    }
    return !!allSubscriptionDetails?.[availableSubscriptionkey]?.status;
  }

  function checkIfAnythingPurchased(allSubscriptionStatus: any) {
    return Object.values(allSubscriptionStatus).some((subscriptionStatus) => subscriptionStatus);
  }

  const allSubscriptionStatus = {
    isDomainPurchased: getStatus(subscriptionKey.DOMAIN),
    isEcommThemePurchased: getStatus(subscriptionKey.ECOMM_THEME),
    isBrandWebsiteThemePurchased: getStatus(subscriptionKey.BRAND_WEBSITE),
    isEcommercePackPurchased: getStatus(subscriptionKey.ECOM_PACK),
    isUpcomingEcommPackPurchased: getStatus(subscriptionKey.UPCOMING_ECOM_PACK),
    isWabaPurchased: getStatus(subscriptionKey.WABA),
    isGSTPurchased: getStatus(subscriptionKey.GST),
    isGoogleWorkspacePurchased: getStatus(subscriptionKey.WORKSPACE, false),
    isAdditionalPagesPurchased: getStatus(subscriptionKey.ADDITIONAL_PAGE),
    isExternalDomainPurchased: getStatus(subscriptionKey.EXTERNAL_DOMAIN),
  };

  const isAnythingPurchased = checkIfAnythingPurchased(allSubscriptionStatus);

  return { ...allSubscriptionStatus, isAnythingPurchased };
};

export default useActiveSubscription;
