import { autoGenerateTextLoader } from './../Actions/common';
import { put, takeEvery } from "redux-saga/effects";
import {
  saveGoogleShoppingAccountDetails,
  setPageLoaderVisibility,
  setApiCallOngoingFlag,
  getGoogleShoppingAccountDetails,
  saveProductAnalysis,
  setServices,
  syncAllProducts,
  saveShoppingAccountDetailsV2,
  saveVerificationIdGoogleShoppingV2,
  saveStoreCatalogGoogleShoppingV2,
  saveStoreAnalyticsGoogleShoppingV2,
  syncAllProductsGoogleShoppingV2,
} from "../Actions";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { GOOGLE_SHOPPING_API, GOOGLE_SHOPPING_API_V2 } from "../../Services/apiUrls";
import * as actionTypes from "./../ActionTypes/googleShoppingActions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import store from "./../Store";
import { getHybridRoute, getStoreId, parseToJson, googleShopBase64EncodedUrl, clevertapEventPush } from '../../Utils/common';
import { GOOGLE_SHOPPING_V2_SUCCESS_PAGE } from "../../Constants/routesConstant";
import { diceLoaderVisibilty } from '../Actions/common';
import { EVENTS_GOOGLE_SHOPPING_V2 } from '../../Utils/cleverTapEventsConstant';
import { getStoreCatalogGoogleShoppingV2 } from '../Actions/googleShopping';

function* fetchUserShoppingAccountDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.getShoppingAccountDetailsApi}${actions.data}`,
    });
    if (response && response.data?.status) {
      yield put(saveGoogleShoppingAccountDetails(response.data?.google_shopping_account_details));
      actions.callback && actions.callback(response.data?.google_shopping_account_details);
    } else {
      yield put(saveGoogleShoppingAccountDetails({}));
      actions.callback && actions.callback({});
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(saveGoogleShoppingAccountDetails({}));
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* fetchShoppingProductAnalysis(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.getProductAnalysis}${actions.data}`,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(response.data?.google_shopping_products_analytics?.products_statuses || {});
      yield put(saveProductAnalysis(response.data.google_shopping_products_analytics?.products_statuses));
    } else {
      yield put(saveProductAnalysis({}));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* checkStoreEligibilityCriteria(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    yield put(setApiCallOngoingFlag(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.checkStoreEligibilityApi}`,
      data: actions.data,
    });
    if (response && response.data?.status && response.data.response) {
      actions.callback && actions.callback(response.data.response);
    } else {
      actions.callback && actions.callback(false);
      yield put(setApiCallOngoingFlag(false));
    }
    yield put(getGoogleShoppingAccountDetails(actions.data.store_id));
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setApiCallOngoingFlag(false));
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* checkEligibleProductNumber(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    yield put(setApiCallOngoingFlag(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.checkEligibleProductAPi}${actions.data}`,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(response.data.result);
    } else {
      actions.callback && actions.callback({});
    }
    yield put(setApiCallOngoingFlag(false));
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    yield put(setApiCallOngoingFlag(false));
    ToastNotifyError("Error!");
  }
}

function* requestAMaunalDemo(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.requestManualDemo}${actions.data}`,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(response.data.result);
    } else {
      actions.callback && actions.callback({});
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* createGoogleShoppingAccount(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    yield put(setApiCallOngoingFlag(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.createAccountApi}${actions.data}`,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(true);
    } else {
      ToastNotifyError("Something went bad!");
      yield put(setPageLoaderVisibility(false));
      yield put(setApiCallOngoingFlag(false));
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
    yield put(setPageLoaderVisibility(false));
    yield put(setApiCallOngoingFlag(false));
  }
}

function* setUserShippingDetails(actions: any) {
  try {
    yield put(setApiCallOngoingFlag(true));
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.setShippingDetailsApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(true);
    } else {
      ToastNotifyError("Something went bad!");
      yield put(setApiCallOngoingFlag(false));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    yield put(setApiCallOngoingFlag(false));
    ToastNotifyError("Error!");
  }
}

function* requestPhoneNumberVerification(actions: any) {
  try {
    yield put(setApiCallOngoingFlag(true));
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.phoneNumberVerificationApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(response?.data?.id);
    } else {
      ToastNotifyError("Something went bad!");
    }
    yield put(setApiCallOngoingFlag(false));
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setApiCallOngoingFlag(false));
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* syncProducts(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.syncAllProducts}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      const storeData = store.getState().storeReducer.store;
      const store_id = storeData.store_id;
      const pickup_flag = storeData.services.pickup_flag;
      const payload = {
        delivery_flag: 1,
        pickup_flag: pickup_flag,
        store_flag: 1,
        store_id: store_id,
      };
      yield put(setServices(payload));
    }
  } catch (err) {
    ToastNotifyError("Something went wrong! Please try again");
  }
}

function* verifyOtp(actions: any) {
  try {
    yield put(setApiCallOngoingFlag(true));
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API.verifyOtp}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(true);
      const { store_id } = store.getState().storeReducer.store;
      yield put(syncAllProducts({ store_id }));
    } else {
      actions.callback && actions.callback(false);
    }
    yield put(setPageLoaderVisibility(false));
    yield put(setApiCallOngoingFlag(false));
  } catch (err) {
    actions.callback && actions.callback(false);
    yield put(setApiCallOngoingFlag(false));
    yield put(setPageLoaderVisibility(false));
  }
}

function* getShoppingAccountDetailsV2Saga() {
  const storeId = getStoreId();
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.getShoppingAccountDetails(storeId)}`,
    });
    if (response && response.data?.status) {
      yield put(saveShoppingAccountDetailsV2(parseToJson(response.data.data)));
    }
  } catch (err) {
    console.log(err);
  }
}

function* createGoogleShoppingAccountV2Saga(actions: any) {
  yield put(diceLoaderVisibilty(true));
  const storeId = getStoreId();
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.createGoogleShopping(storeId)}`,
    });
    if (response && response.data.status) {
      actions?.callback && actions?.callback();
    }
  } catch (err) {
    console.log(err);
    ToastNotifyError(err?.data?.message);
    yield put(diceLoaderVisibilty(false));
  } finally {
    actions?.resolvePromise && actions?.resolvePromise();
    yield put(diceLoaderVisibilty(false));
  }
}

function* generateGoogleShoppingOtpV2Saga() {
  const storeId = getStoreId();
  const storePhoneNumber = store?.getState()?.storeReducer?.store?.owner?.phone;

  const payload = {
    store_id: storeId,
    phone_number: storePhoneNumber,
  };

  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.generateOtp}`,
      data: payload,
    });

    if (response && response.data.status) {
      yield put(saveVerificationIdGoogleShoppingV2(parseToJson(response.data.data)));
      ToastNotifySuccess("OTP Generated");
    }
  } catch (err) {
    ToastNotifyError("Something Went Bad!");
  }
}

function* verifyGshopOtpV2Saga(actions: any) {
  const storeId = getStoreId();
  const verification_id = store.getState()?.googleShoppingReducer?.verificationId;
  const payload = {
    store_id: storeId,
    verification_id: verification_id,
    verification_code: actions.data,
  };
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.verifyOtp}`,
      data: payload,
    });
    if (response && response?.data?.status) {
      actions?.callback && actions?.callback(false);
      yield put(syncAllProductsGoogleShoppingV2());
      clevertapEventPush(EVENTS_GOOGLE_SHOPPING_V2.OTP_SUBMITTED);
      actions?.history?.push(getHybridRoute(GOOGLE_SHOPPING_V2_SUCCESS_PAGE));
    }
  } catch (err) {
    actions?.callback && actions?.callback(true);
    console.log(err);
  }
}

function* fetchStoreCatalogGoogleShoppingV2(actions: any) {
  yield put(diceLoaderVisibilty(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.getAllProducts(getStoreId())}`,
    });
    if (response && response?.data?.status) {
      actions?.callback && actions?.callback(parseToJson(response.data.data)?.products_statuses);
      yield put(saveStoreCatalogGoogleShoppingV2(parseToJson(response.data.data)));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* fetchStoreAnalyticsGoogleShoppingV2() {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.getStoreAnalytics(getStoreId())}`,
    });
    if (response && response.data.status) {
      yield put(saveStoreAnalyticsGoogleShoppingV2(parseToJson(response.data.data)));
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchAutoDescGoogleShoppingV2(actions: any) {
  yield put(autoGenerateTextLoader(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.autoGenerateItemDescription(actions.itemId)}`,
    });
    if (response && response.data.status) {
      actions?.callback && actions?.callback(parseToJson(response.data.data));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(autoGenerateTextLoader(false));
  }
}

function* sendShippingDetailsGoogleShoppingV2Saga(actions: any) {
  try {
    yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.sendShippingDetails}`,
      data: actions.data,
    });
  } catch (err) {
    console.log(err);
  }
}

function* addItemImagesGoogleShoppingV2Saga(actions: any) {
  yield put(diceLoaderVisibilty(true));
  const payload = {
    store_id: getStoreId(),
    image: googleShopBase64EncodedUrl(actions.data),
  };
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.addItemImage}`,
      data: payload,
    });
    if (response && response.data.status) {
      actions?.callback && actions?.callback(parseToJson(response.data.data));
    }
  } catch (err) {
    console.log(err);
    ToastNotifyError(err?.data?.message);
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* quickItemUpdateGoogleShoppingV2Saga(actions: any) {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.quickItemUpdate}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(getStoreCatalogGoogleShoppingV2());
    }
  } catch (err) {
    console.log(err);
  }
}

function* syncAllProductsGoogleShoppingV2Saga() {
  yield put(diceLoaderVisibilty(true));
  try {
    yield apiCall({
      method: "POST",
      url: `${config.base_url}${GOOGLE_SHOPPING_API_V2.syncAllProductsV2(getStoreId())}`,
    });
  } catch (err) {
    console.log(err);
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS, fetchUserShoppingAccountDetails);
  yield takeEvery(actionTypes.FETCH_SHOPPING_PRODUCT_ANALYSIS, fetchShoppingProductAnalysis);
  yield takeEvery(actionTypes.CHECK_STORE_ELIGIBILITY_FOR_SHOPPING, checkStoreEligibilityCriteria);
  yield takeEvery(actionTypes.CHECK_ELIGIBLE_PRODUCT_NUMBER, checkEligibleProductNumber);
  yield takeEvery(actionTypes.REQUEST_A_MANUAL_DEMO_ACTION, requestAMaunalDemo);
  yield takeEvery(actionTypes.CREATE_GOOGLE_SHOPPINGACCOUNT_ACTION, createGoogleShoppingAccount);
  yield takeEvery(actionTypes.SET_USER_SHIPPING_DETAILS_ACTION, setUserShippingDetails);
  yield takeEvery(actionTypes.REQUEST_PHONE_NUMBER_VERIFICATION, requestPhoneNumberVerification);
  yield takeEvery(actionTypes.VERIFY_GOOGLE_SHOPPING_OTP, verifyOtp);
  yield takeEvery(actionTypes.SYNC_ALL_PRODUCTS, syncProducts);

  yield takeEvery(actionTypes.GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS_V2, getShoppingAccountDetailsV2Saga);
  yield takeEvery(actionTypes.CREATE_GOOGLE_SHOPPING_ACCOUNT_V2, createGoogleShoppingAccountV2Saga);
  yield takeEvery(actionTypes.GENERATE_OTP_GOOGLE_SHOPPING_V2, generateGoogleShoppingOtpV2Saga);
  yield takeEvery(actionTypes.VERIFY_OTP_GOOGLE_SHOPPING_V2, verifyGshopOtpV2Saga);
  yield takeEvery(actionTypes.GET_STORE_CATALOG_GOOGLE_SHOPPING_V2, fetchStoreCatalogGoogleShoppingV2);
  yield takeEvery(actionTypes.GET_STORE_ANALYTICS_GOOGLE_SHOPPING_V2, fetchStoreAnalyticsGoogleShoppingV2);
  yield takeEvery(actionTypes.AUTO_GENERATE_DESC_GOOGLE_SHOPPING_V2, fetchAutoDescGoogleShoppingV2);
  yield takeEvery(actionTypes.SEND_SHIPPING_DETAILS_GOOGLE_SHOPPING_V2, sendShippingDetailsGoogleShoppingV2Saga);
  yield takeEvery(actionTypes.ADD_ITEM_IMAGE_GOOGLE_SHOPPING_V2, addItemImagesGoogleShoppingV2Saga);
  yield takeEvery(actionTypes.QUICK_ITEM_UPDATE_GOOGLE_SHOPPING_V2, quickItemUpdateGoogleShoppingV2Saga);
  yield takeEvery(actionTypes.SYNC_ALL_PRODUCTS_GOOGLE_SHOPPING_V2, syncAllProductsGoogleShoppingV2Saga);
}
