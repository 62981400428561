import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { setExitGatingAnalytics, setExitGatingData, setExitGatingEditorConfig, setPageLoaderVisibility } from "../Actions";
import apiCall from "./../../Services/api";
import { EXIT_GATING_API } from "../../Services/apiUrls";
import { GET_EXIT_GATING_DATA, POST_OR_UPDATE_EXIT_GATING_DATA } from "../ActionTypes";
import { getStoreId } from "../../Utils/common";

function* getExitGatingDataInfo() {
  try {
    yield put(setPageLoaderVisibility(true));
    const storeId = getStoreId();
    const response = yield apiCall({
      method: "GET",
      url: EXIT_GATING_API.getExitGatingData(storeId),
      parseToJson: true,

    });
    if (response?.data?.status) {
      const {analytics, coupon, exit_gating_config} = response?.data?.data;
      const commonData = {
        selectedTemplate: exit_gating_config?.selected_template,
        imagePath: exit_gating_config?.image_url,
        minUserTimeSpent: exit_gating_config?.timing,
        perDayPopUpLimit: exit_gating_config?.frequency,
        offerSelected: exit_gating_config?.promo_code,
        couponData: coupon,
      };

      yield put(setExitGatingData({
        ...commonData,
        status: exit_gating_config?.status,
        showGetStartedScreen: false,
      }));
      yield put(setExitGatingEditorConfig({
        ...commonData,
        isAnyChange: false,
        imageFileName: "",
      }));
      yield put(setExitGatingAnalytics(analytics));
      yield put(setPageLoaderVisibility(false));
    } else {
      yield put(setExitGatingData({
        showGetStartedScreen: true,
      }));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    yield put(setExitGatingData({
      showGetStartedScreen: true,
    }));
  }
}

function* postOrUpdateExitGatingData(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const storeId = getStoreId();
    const response = yield apiCall({
      method: "PUT",
      url: EXIT_GATING_API.setExitGatingData,
      data: {
        ...actions.data,
        store_id: storeId,
      },
    });
    if (response?.data?.status) {
        actions?.callback();
        yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad!");
  }
}

export default function* root() {
  yield takeEvery(GET_EXIT_GATING_DATA, getExitGatingDataInfo);
  yield takeEvery(POST_OR_UPDATE_EXIT_GATING_DATA, postOrUpdateExitGatingData);
}
