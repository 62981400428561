import * as authTypes from "./../ActionTypes/analytics";

export function getVisitorsSummaryByFilters(data: any, callback?: any) {
  return {
    type: authTypes.GET_VISITORS_SUMMARY_BY_FILTERS,
    data,
    callback,
  };
}

export function setVisitorsSummaryByFilters(data: any) {
  return {
    type: authTypes.SET_VISITORS_SUMMARY_BY_FILTERS,
    data,
  };
}
