import { all } from "redux-saga/effects";
import auth from "./auth";
import catalog from "./catalog";
import orders from "./orders";
import store from "./store";
import business from "./business";
import theme from "./theme";
import payment from "./payment";
import domain from "./domain";
import common from "./common";
import bankDetails from "./bankDetails";
import ads from "./ads";
import coupons from "./coupons";
import marketing from "./marketing";
import bulkUpload from "./bulkUpload";
import user from "./user";
import subscription from "./subscription";
import additionalPages from "./additionalPages";
import staffManagement from "./staffManagement";
import workspace from "./workspace";
import crm from "./crm";
import cart from "./cart";
import creditEngine from "./creditEngine";
import leadgen from "./leadgen";
import analytics from "./analytics";
import googleShopping from "./googleShopping";
import facebookBusiness from "./facebookBusiness";
import smsMarketing from "./smsMarketing";
import customDelivery from "./customDelivery";
import seoTools from "./seoTools";
import gstBilling from "./gstBilling";
import announcements from "./addAnnouncement";
import socialMediaProfile from "./socialMediaProfile";
import whatsappMarketing from "./whatsappMarketing";
import productInquiry from "./productInquiry";
import bulkEdit from "./bulkEdit";
import dnsManagement from "./dnsManagement";
import helpSection from "./helpSection";
import brandWebsite from "./brandWebsite";
import exitGating from "./exitGating";
import utilities from "./utilities";

export default function* root() {
  yield all([
    auth(),
    catalog(),
    orders(),
    store(),
    business(),
    theme(),
    payment(),
    domain(),
    common(),
    bankDetails(),
    ads(),
    coupons(),
    marketing(),
    bulkUpload(),
    user(),
    subscription(),
    additionalPages(),
    staffManagement(),
    workspace(),
    crm(),
    cart(),
    creditEngine(),
    leadgen(),
    analytics(),
    googleShopping(),
    facebookBusiness(),
    smsMarketing(),
    customDelivery(),
    seoTools(),
    gstBilling(),
    announcements(),
    socialMediaProfile(),
    whatsappMarketing(),
    productInquiry(),
    bulkEdit(),
    dnsManagement(),
    helpSection(),
    brandWebsite(),
    exitGating(),
    utilities(),
  ]);
}
