import * as actionsType from "../ActionTypes/additionalPagesActions";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

/**
 * This function will fetch the data for the single page
 * @param callback
 */
export const getSinglePageData = (data: any, callback?: any): Action => {
  return {
    type: actionsType.GET_SINGLE_PAGE_DATA,
    data,
    callback,
  };
};

/**
 * This function will fetch all the pages of the store and the default templates based on the query param
 * @param callback
 */
export const getPagesList = (data: any, callback?: any): Action => {
  return {
    type: actionsType.GET_PAGES_LIST,
    data,
    callback,
  };
};

export const addNewPageToStore = (data: any, callback?: any): Action => {
  return {
    type: actionsType.ADD_NEW_PAGE_TO_STORE_PAGES,
    data,
    callback,
  };
};

export const saveStorePagesList = (data: any): Action => {
  return { type: actionsType.SAVE_STORE_PAGES_LIST, data };
};

export const saveDefaultTemplatesList = (data: any): Action => {
  return { type: actionsType.SAVE_DEFAULT_TEMPLATE_LIST, data };
};

export const onSaveTemplateAsDraft = (data: any, callback?: any): Action => {
  return { type: actionsType.ON_SAVE_TEMPLATE_CHANGES_AS_DRAFT, data, callback };
};

export const onPublishTemplateChanges = (data: any, callback?: any): Action => {
  return { type: actionsType.ON_PUBLISH_TEMPLATE_CHANGES, data, callback };
};

export const onDeletePage = (data: any, callback?: any): Action => {
  return { type: actionsType.ON_DELETE_PAGE, data, callback };
};

export const onReorderPage = (data: any, callback?: any): Action => {
  return { type: actionsType.ON_REORDER_PAGE, data, callback };
};
