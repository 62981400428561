import React, { useLayoutEffect } from "react";
import { EMAIL_VERIFICATION } from "../../Constants/routesConstant";
import history from "../../Utils/history";
import { getVerificationQueryParams } from "../../Utils/emailverificationQueryParams";

export function useCheckEmailVerification() {
  const emailVerificationQueryParams = getVerificationQueryParams();

  useLayoutEffect(() => {
    if (window.location.pathname === EMAIL_VERIFICATION) {
      history.replace(
        `${EMAIL_VERIFICATION}?store_id=${emailVerificationQueryParams?.store_id}&email=${emailVerificationQueryParams?.email}&status=${emailVerificationQueryParams?.status}`,
      );
    }
  }, []);

  return null;
}
