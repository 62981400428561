import * as cartTypes from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
  leadCartType?: number;
}

export function addToCart(data: any): Action {
  return {
    type: cartTypes.ADD_TO_CART,
    data,
  };
}

export function getCartsByFilters(data: any, callback?: any): Action {
  return {
    type: cartTypes.GET_CARTS_BY_FILTER,
    data,
    callback,
  };
}

export function getOrderCartById(data: any, callback?: any): Action {
  return {
    type: cartTypes.GET_ORDER_CART_BY_ID,
    data,
    callback,
  };
}

export function cartDetailsById(data: any): Action {
  return {
    type: cartTypes.CART_DETAILS_BY_ID,
    data,
  };
}
export function sendAbandonedCartReminder(data: any, callback?: any): Action {
  return {
    type: cartTypes.SEND_ABANDONED_CART_REMINDER,
    data,
    callback,
  };
}

export function updateCartNotification(data: any, callback: any): Action {
  return {
    type: cartTypes.UPDATE_CART_NOTIFICATION,
    data,
    callback,
  };
}

export function getFilteredLeadSubscribers(data: any, callback?: any): Action {
  return {
    type: cartTypes.GET_FILTERED_EMAIL_SUBSCRIBERS,
    data,
    callback,
  };
}

export function isCartInfoLoading(data: any): Action {
  return {
    type: cartTypes.IS_LOADING,
    data,
  };
}

export const exportCart = (data: any, callback?: any) => {
  return {
    type: cartTypes.REQUEST_CART_REPORT,
    data,
    callback,
  };
};
