export const SET_SEO_DETAILS = "SEO_TOOLS/SET_SEO_DETAILS";
export const GET_SEO_DETAILS = "SEO_TOOLS/GET_SEO_DETAILS";
export const SAVE_SEO_DETAILS = "SEO_TOOLS/SAVE_SEO_DETAILS";
export const SAVE_STEP1_DETAILS = "SEO_TOOLS/SAVE_STEP1_DETAILS";
export const SAVE_STEP2_DETAILS = "SEO_TOOLS/SAVE_STEP2_DETAILS";
export const SAVE_STEP3_DETAILS = "SEO_TOOLS/SAVE_STEP3_DETAILS";
export const TOGGLE_STEP1_DROPDOWN = "SEO_TOOLS/TOGGLE_STEP1_DROPDOWN";
export const TOGGLE_STEP2_DROPDOWN = "SEO_TOOLS/TOGGLE_STEP2_DROPDOWN";
export const TOGGLE_STEP3_DROPDOWN = "SEO_TOOLS/TOGGLE_STEP3_DROPDOWN";
export const TOGGLE_UNSAVED_DETAILS_MODAL = "SEO_TOOLS/TOGGLE_UNSAVED_DETAILS_MODAL";
export const RESET_SEO_DETAILS_STATE = "SEO_TOOLS/RESET_SEO_DETAILS_STATE";
export const TOOGLE_SEO_FORM_DROPDOWN = "SEO_TOOLS/TOOGLE_SEO_FORM_DROPDOWN";
export const TOGGLE_UNSAVED_SEO_DETAILS_STATE = "SEO_TOOLS/TOGGLE_UNSAVED_SEO_DETAILS_STATE";
export const TOGGLE_HAS_CUSTOM_SEO = "SEO_TOOLS/TOGGLE_HAS_CUSTOM_SEO";

// Advance Custom SEO ActionTypes
export const SET_ADV_SEO_DETAILS = "SEO_TOOLS/SET_ADV_SEO_DETAILS";
export const GET_ADV_SEO_DETAILS = "SEO_TOOLS/GET_ADV_SEO_DETAILS";
export const SAVE_ADV_SEO_DETAILS = "SEO_TOOLS/SAVE_ADV_SEO_DETAILS";

export const GET_ADV_SEO_DETAILS_PER_TYPE = "SEO_TOOLS/GET_ADV_SEO_DETAILS_PER_TYPE";
export const SET_ADV_SEO_DETAILS_PER_TYPE = "SEO_TOOLS/SET_ADV_SEO_DETAILS_PER_TYPE";
export const UPDATE_SEO_RECOMMENDATION = "SEO_TOOLS/UPDATE_SEO_RECOMMENDATION";

