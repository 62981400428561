import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  alertBar: {
    backgroundColor: "#F65462",
    height: 28,
    zIndex: 110,
  },
  alertBarText: {
    color: "#ffffff",
    fontSize: 17,
  },
  fixedBarStyles: {
    position: "fixed",
    width: "calc(100% - 240px)",
    zIndex: 100,
    height: "2rem",
    right: 0,
    top: 0,
  },
}));
